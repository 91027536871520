import { CLIENT_URL } from "Utils/urls";
import PageMessage from "./../components/PageMessage";

export default function ResultPage(props) {
  if (props.state === "offline") {
    return (
      <PageMessage
        status={"warning"}
        title={"Undergoing Maintenance"}
        subTitle={
          <span>
            <span>We are currently updating the software to a newer version.</span>
            <br />
            <span>Please try logging back in at a later time</span>
            <br />
            <span>For more information, you can reach us at the number +961 (0) 1 203 088 ext 4</span>
          </span>
        }
      />
    );
  } else if (props.state === "register") {
    return (
      <PageMessage
        status={"info"}
        title={"Email Not Registered"}
        subTitle={
          <span>
            <span>We could not find any registered accounts with that email!</span>
            <br />
            <span>
              Kindly send us an <a href="mailto: support@algotraders.ai">email</a> with the requested permission level.{" "}
            </span>
            <br />
            <span>For more information, you can reach us at the number +961 (0) 1 203 088 ext 4</span>
          </span>
        }
      />
    );
  } else if (props.state === "no permission") {
    return (
      <PageMessage
        status={"error"}
        title={"Access Denied"}
        subTitle={
          <span>
            <span>You currently do not have permission to view this specific report!</span>
            <br />
            <span>
              You may view other reports that you have access to through <a href={CLIENT_URL}> this page</a>.
            </span>
            <br />
            <span>
              If you believe this is not intended, please contact AlgoTraders at
              <a href="mailto: support@algotraders.ai"> support@algotraders.ai</a>.
            </span>
            <br />
            <span>For more information and urgent inquires, you can reach us at +961 (0) 1 203 088 ext 4</span>
          </span>
        }
      />
    );
  } else {
    return <></>;
  }
}

import { TitleConfig } from "components/AlgoBooks/utils/types";

interface ReportTitleProps {
  titleConfig: TitleConfig;
}

export default function ReportTitle({ titleConfig }: ReportTitleProps) {
  const detailsStyle = {
    fontSize: "small",
    marginBottom: "20px",
  };
  const mainTitleStyle = {
    fontWeight: "600",
  };
  const GenerateReportTitle = () => {
    if (titleConfig.company && titleConfig.report && titleConfig.grouping && !titleConfig.selectorLoading) {
      var selectedCompanyObj = titleConfig.companyOptions.find((obj) => {
        return obj.realmId === titleConfig.company;
      });
      var selectedCompanyName = selectedCompanyObj.name;
      var selectedClasses = selectedCompanyObj.classes.filter((objClass) => titleConfig.classes.includes(objClass.id));
      var selectedClassesNames = GetPropertyValues(selectedClasses, "name").join(", ");
      var selectedDepartments = selectedCompanyObj.departments.filter((objClass) => titleConfig.departments.includes(objClass.id));
      var selectedDepartmentsNames = GetPropertyValues(selectedDepartments, "name").join(", ");

      var selectedReport = titleConfig.reportOptions.find((obj) => {
        return obj.value === titleConfig.report;
      }).text;
      var selectedPeriods = titleConfig.periodOptions.filter((periodOption) => titleConfig.period.includes(periodOption.key));
      selectedPeriods = GetPropertyValues(selectedPeriods, "text").join(", ");
      var selectedCurrencies = titleConfig.currency.selectedCurrencies.join(", ");
      return (
        <span style={{ cursor: "default" }}>
          <br />
          <h3 style={mainTitleStyle}>{selectedCompanyName + ": " + selectedReport + " Report"} </h3>

          <div style={detailsStyle}>
            {"Departments: " +
              selectedDepartmentsNames +
              ", Class: " +
              selectedClassesNames +
              ", Frequency: " +
              titleConfig.grouping +
              ", Periods: " +
              selectedPeriods +
              ", Currencies: " +
              selectedCurrencies}
          </div>
        </span>
      );
    }
    return "";
  };

  return <>{GenerateReportTitle()}</>;
}

function GetPropertyValues(arrOfObjs: [{ [key: string]: any }], property: string): any[] {
  var propertyValues = [];
  for (var obj of arrOfObjs) propertyValues.push(obj[property]);
  return propertyValues;
}

/*
@since: 2023-3-22
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Button, InputNumber, message } from "antd";
import { AxiosResponse } from "axios";
import { useState } from "react";
import { UpdateSettings } from "../../../../dataHandling/PriceUpdaterAPI";
import { STATUS } from "../../Utils/Constants";
import { FormatItemsDataForTable } from "../Utils/PriceTableUtil";
import NavBarCard from "./NavBar/NavBarCard";
import { GetRecommendedRate } from "./NavBar/PriceUpdaterSettingsUtil";
import { PriceUpdaterSettings } from "Utils/types";

export default function RateConstantSetting(settingsProps) {
  const { priceUpdaterConfig, setPriceUpdaterConfig } = usePriceConfigContext();
  const {
    products,
    setProducts,
    modifierOptions,
    setModifierOptions,
    comboItemOptionSizes,
    setComboItemOptionSizes,
    editMode,
    setCustomNewRate,
    updaterStatus,
  } = settingsProps;
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState(priceUpdaterConfig.addToRateConstant);

  function OnSave() {
    const newSettings:PriceUpdaterSettings = { addToRateConstant: inputValue };
    if (inputValue != priceUpdaterConfig.addToRateConstant) {
      setLoading(true);
      UpdateSettings(newSettings)
        .then((response: AxiosResponse) => {
          if (response.status == 200) {
            message.success(response.data.message);
            const newUpdaterConfig = { ...priceUpdaterConfig, ...newSettings };
            setPriceUpdaterConfig(newUpdaterConfig);

            // rate constant change overrides the manual rate
            const newRecommendedRate = GetRecommendedRate(newUpdaterConfig);
            setCustomNewRate(newRecommendedRate);

            // Update table data
            const items = { products, modifierOptions, comboItemOptionSizes };
            FormatItemsDataForTable(items, newRecommendedRate);
            setProducts(items.products);
            setModifierOptions(items.modifierOptions);
            setComboItemOptionSizes(items.comboItemOptionSizes);
          } else {
            message.error(response.data.message);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.response.data.message);
        });
    }
  }

  return (
    <NavBarCard cardProps={{ title: "Additional Rate Constant" }}>
      <InputNumber
        type="number"
        addonAfter={
          <Button
            onClick={OnSave}
            block
            disabled={inputValue == priceUpdaterConfig.addToRateConstant || loading}
            style={{ width: "100%" }}
            loading={loading}
          >
            Save
          </Button>
        }
        value={inputValue}
        controls={false}
        onChange={(newInput) => setInputValue(newInput)}
        style={{ marginTop: "9px" }}
        disabled={updaterStatus == STATUS.RUNNING || loading || editMode}
      />
    </NavBarCard>
  );
}

import { Alert } from "antd";
import { Link } from "react-router-dom";
import { ReportData } from "../../utils/types";

interface ExternalUrlsAlertProps {
  reportData: ReportData;
}
export default function ExternalUrlsAlert(props: ExternalUrlsAlertProps) {
  const description = (
    <ul>
      {props.reportData.Errors.DetailError.quickbooksUrls.map((qbUrl, index) => (
        <li>
          <Link to={qbUrl} target="blank">
            Quickbooks Report {index + 1}
          </Link>
        </li>
      ))}
    </ul>
  );

  return (
    <Alert
      message="Trouble parsing Details Report"
      description={<>You can view the original quickbooks report here: {description}</>}
      type="warning"
    />
  );
}

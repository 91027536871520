/*
@since: 2023-8-11
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { ReloadOutlined } from "@ant-design/icons";
import { useAuthContext } from "Utils/hooks/useAuthContext";
import { Button, Popconfirm, Row, message } from "antd";
import { AxiosResponse, isAxiosError } from "axios";
import { CSSProperties, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { FetchDownloaderStatus, StartDownloader } from "../../../dataHandling/RecipeBook";
import { STATUS, toStatus } from "../Utils/Constants";
interface DownloaderButtonProps {
  mainBranch: string | null;
  loadingMainBranches: boolean;
}

const DISABLED_STATUSES: STATUS[] = [STATUS.LOADING, STATUS.RUNNING];

export function DownloaderButton(props: DownloaderButtonProps) {
  const smallScreen = useMediaQuery({ query: "(max-width:600px)" });
  const [downloaderStatus, setDownloaderStatus] = useState<STATUS>(STATUS.LOADING);
  const [popConfirmOpen, setPopConfirmOpen] = useState<boolean>(false);
  const [textHovering, setTextHovering] = useState<boolean>(false);
  const { user } = useAuthContext();
  useEffect(() => {
    setDownloaderStatus(STATUS.LOADING);
    const statusWatcher = setInterval(() => {
      if (props.mainBranch !== null) {
        FetchDownloaderStatus(props.mainBranch)
          .then((response) => {
            setDownloaderStatus(toStatus(response.status));
          })
          .catch((err) => {
            if (isAxiosError(err)) {
              message.error(err?.response?.data.message);
            } else {
              message.error("Error occurred while fetching downloader status.");
            }
          });
      }
    }, 1000); // 1s
    return () => {
      clearInterval(statusWatcher);
    };
  }, [props.mainBranch]);

  const LaunchDownloader = () => {
    if (props.mainBranch === null) {
      message.error("Cannot start downloader, please select a branch.");
      return;
    }
    if (user === undefined) {
      message.error("Undefined user cannot launch downloader.");
      return;
    }
    StartDownloader(props.mainBranch, user.email)
      .then((response: AxiosResponse) => {
        message.success(response.data.message);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          message.error(err?.response?.data.message);
        } else {
          message.error("Error occurred while launching downloader.");
        }
      });
  };

  const togglePopConfirm = () => {
    if (!DISABLED_STATUSES.includes(downloaderStatus) && !popConfirmOpen) setPopConfirmOpen(true);
    else setPopConfirmOpen(false);
  };

  function GetButtonText() {
    switch (downloaderStatus) {
      case STATUS.LOADING:
        return "Loading status...";
      case STATUS.RUNNING:
        return "Refreshing data...";
      default:
        return (!popConfirmOpen && textHovering) || popConfirmOpen ? "Get the latest data from Foodics" : "Refresh data";
    }
  }
  const buttonDynamicStyling: CSSProperties = smallScreen ? { marginRight: "5px", marginLeft: "5px" } : { margin: "0px" };

  return (
    <Row style={{ display: "flex", justifyContent: "center", alignItems: "center", overflow: "hidden" }}>
      <Popconfirm
        title={<span style={{ cursor: "default" }}>Are you sure?</span>}
        onConfirm={LaunchDownloader}
        okText="Refresh RecipeBook Data"
        // okType="danger"
        okButtonProps={{
          loading: DISABLED_STATUSES.includes(downloaderStatus),
          disabled: DISABLED_STATUSES.includes(downloaderStatus),
        }}
        onCancel={() => setPopConfirmOpen(false)}
        onPopupClick={togglePopConfirm}
        disabled={DISABLED_STATUSES.includes(downloaderStatus)}
        placement="bottomLeft"
        style={{ overflow: "hidden" }}
      >
        <Button
          block
          disabled={DISABLED_STATUSES.includes(downloaderStatus)}
          icon={<ReloadOutlined />}
          loading={DISABLED_STATUSES.includes(downloaderStatus)}
          onClick={togglePopConfirm}
          style={{
            backgroundColor: "transparent",
            borderColor: "transparent",
            color: "white",
            height: "100%",
            width: "100%",
            ...buttonDynamicStyling,
          }}
          onMouseEnter={() => setTextHovering(true)}
          onMouseLeave={() => setTextHovering(false)}
        >
          {!smallScreen && GetButtonText()}
        </Button>
      </Popconfirm>
    </Row>
  );
}

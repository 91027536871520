/**
 * @since: 2023-12-20
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import PageMessage from "components/AlgoBooks/components/PageMessage";
import "components/Shared/Styles/styles.css";
import { Link } from "react-router-dom";

export function NoResponsePage() {
  return (
    <div className="container-center">
      <PageMessage
        status={"error"}
        title={"Sorry."}
        subTitle={
          <span>
            We're having trouble reaching our servers. Try waiting a minute or two and then{" "}
            <Link reloadDocument to="/">
              go back to homepage.
            </Link>
          </span>
        }
      />
    </div>
  );
}

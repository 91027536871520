/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { SalesBranch, SalesFrequency, SalesTableRow } from "Utils/types";
import moment from "moment";
import { DAILY_FORMAT, FREQUENCY_FORMATS, WEEKLY_FORMAT } from "./constants";

/**
 *
 * @param {String} date
 * @param {String} frequency
 * @returns
 */
export function ToReadableDate(date: string, frequency: SalesFrequency): string {
  const { preString, format } = FREQUENCY_FORMATS[frequency];
  let readableDate;
  if (format === WEEKLY_FORMAT) {
    const startOfWeekDay = moment(date).weekday(1).format(DAILY_FORMAT);
    const endOfWeekDay = moment(date).weekday(7).format(DAILY_FORMAT);
    readableDate = `${startOfWeekDay} - ${endOfWeekDay}`;
  } else {
    readableDate = preString + moment(date).format(format);
  }
  return readableDate;
}

export function ToReadableCurrency(number: number, currency: string): string {
  if (!number) {
    number = 0;
  }
  const readableNumber = new Intl.NumberFormat("en-US", { style: "currency", currency: currency }).format(number);
  return readableNumber;
}

export function GenerateChartXAxis(sales: SalesTableRow[], Frequency: SalesFrequency): string[] {
  let saleDates = [];
  for (let sale of sales) {
    saleDates.push(ToReadableDate(sale.date, Frequency));
  }
  return saleDates;
}

export function GenerateChartYAxis(sales: SalesTableRow[]): number[] {
  let saleTotals = [];
  for (let sale of sales) {
    saleTotals.push(Number(sale.total));
  }
  return saleTotals;
}

export function getDefaultCurrencyForBranch(currentBranchID: string, branchesConfig: SalesBranch[]): string | undefined {
  for (let branch of branchesConfig) {
    if (branch.optionValue === currentBranchID) {
      return branch.defaultCurrency;
    }
  }
}

import { Button, Col, Input, message, Modal, notification, Row, Select } from "antd";
import { useState } from "react";
import { ListSubBranchesType } from "Utils/types";
import { CreateInventoryCount, GetInventoryCountTemplate } from "../../../../../dataHandling/InventoryCountAPI";
import { DownloadExcelFileFromBytes } from "../../../Utils/Excel";
import FileUploader from "./FileUploader";
import "./styles.css";

interface CreationModalProps {
  flag: boolean | null;
  setFlag: React.Dispatch<React.SetStateAction<boolean | null>>;
  branches: ListSubBranchesType;
  updateCounts: React.Dispatch<React.SetStateAction<string>>;
  mainBranch: string;
}

interface CreationModalLoadingStates {
  download: boolean;
  transaction: boolean;
}

export default function CreationModal(props: CreationModalProps) {
  const [loading, setLoading] = useState<CreationModalLoadingStates>({
    download: false,
    transaction: false,
  });
  const [branch, setBranch] = useState<string | null>(null);
  const [note, setNote] = useState<string>("");
  const [cachedFiles, setCachedFiles] = useState<ArrayBuffer[]>([]);
  const { Option } = Select;

  const handleInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setNote(e.target.value);
  };

  const handleTemplateDownload = () => {
    setLoading({ ...loading, download: true });
    GetInventoryCountTemplate(props.mainBranch)
      .then((response) => {
        if (response.status === 200) {
          DownloadExcelFileFromBytes(response.data, "Template");
        } else {
          console.log(response);
          message.error("Failed to download inventory count template");
        }
        setLoading({ ...loading, download: false });
      })
      .catch((err) => {
        message.error("An error occurred while fetching inventory count template, check logs for more details.");
        console.error(err);
      });
  };

  const handleCreation = () => {
    setLoading({ ...loading, transaction: true });
    CreateInventoryCount(props.mainBranch, branch!, note, cachedFiles[0])
      .then((response) => {
        if (response.status === 200) {
          message.success("Inventory Count Created Successfully!");
          props.updateCounts("New ID");
          handleCancel();
        } else if (response.status === 201) {
          message.error("Submitted excel sheet contains errors");
          DownloadExcelFileFromBytes(response.data, "Invalid Rows");
        } else {
          message.error("Server Error, kindly review your submission!");
        }
        setLoading({ ...loading, transaction: false });
      })
      .catch((err) => {
        if (err.response.status === 400) {
          notification.error({
            description: err.response.data,
            duration: null,
            message: "Error",
            style: { whiteSpace: "pre-wrap" },
            className: "error-notification",
          });
        } else {
          message.error("Server Error", 7);
        }
        console.log(err);
        setLoading({ ...loading, transaction: false });
      });
  };

  const handleCancel = () => {
    setCachedFiles([]);
    props.setFlag(null);
  };

  const handleSelect = (value: string) => {
    setBranch(value);
  };

  return (
    <Modal
      title={<span style={{ cursor: "default" }}>Submit New Inventory Count</span>}
      open={props.flag}
      onCancel={handleCancel}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel} disabled={loading.transaction}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={loading.transaction} onClick={handleCreation} disabled={cachedFiles.length < 1}>
          Create
        </Button>,
      ]}
    >
      <p style={{ cursor: "default" }}>Select the required branch, fill out the inventory template and click on create</p>
      <Col>
        <Row>
          <span style={{ color: "#1e94fc" }}>Branch</span>
        </Row>
        <Select placeholder="Select a branch" style={{ width: "50%" }} onChange={handleSelect}>
          {props.branches.map((element) => (
            <Option key={element.id} value={element.id}>
              {element.name}
            </Option>
          ))}
        </Select>
      </Col>
      <br />
      <Col>
        <Row>
          <span style={{ color: "#1e94fc" }}>Note</span>
        </Row>
        <Input onChange={handleInput} />
      </Col>
      <br />
      <Button
        loading={loading.download}
        type="primary"
        onClick={() => {
          handleTemplateDownload();
        }}
      >
        {"Download Template"}
      </Button>
      <FileUploader disabled={loading.transaction} cachedFiles={cachedFiles} setCachedFiles={setCachedFiles} maxCount={1}></FileUploader>
    </Modal>
  );
}

import { Divider } from "antd";
// @ts-ignore
import { ReactElement } from "react";
import { IsNullOrUndefined } from "../../utils/ComponentUtils/ReportTable/utils";
import { ReportErrors, ReportValidationsType, ReportVersionType } from "../../utils/types";
import ReportVersion from "./ReportVersion";
import "./styles.css";

interface ReportValidationProps {
  reportValidations: ReportValidationsType;
  reportWarnings: string[];
  reportErrors: ReportErrors;
  version: ReportVersionType;
  reportType: string;
}

export default function ReportValidations(props: ReportValidationProps) {
  const validationStyle = {
    ok: {},
    error: {
      color: "red",
    },
  };
  return (
    <div style={{ cursor: "default" }}>
      {props.reportWarnings.length > 0 ? (
        <div>
          <Divider></Divider>
          <h3>Warnings:</h3>
          <ul>
            {props.reportWarnings.map((element) => {
              return <li key={element}>{element} </li>;
            })}
          </ul>
        </div>
      ) : (
        <></>
      )}

      <Divider />
      <ReportVersion version={props.version} reportType={props.reportType}></ReportVersion>
      <Divider />

      <h3>Validation Details:</h3>

      <div>
        <ul>
          {props.reportValidations["state"] === "error" ? (
            <li>
              <h4 style={validationStyle[props.reportValidations["state"]]}>{props.reportValidations["message"]}</h4>
            </li>
          ) : (
            <>
              <li>
                <strong>Validator: </strong>
                {props.reportValidations["validator"]}
              </li>
              <li>
                <strong>Comment: </strong>
                {!props.reportValidations["comment"] ? "-" : props.reportValidations["comment"]}
              </li>
              <li style={{ whiteSpace: "pre-wrap" }}>
                <strong>Validation Status: </strong>
                {CheckReportValidity(props.reportValidations["created"])}
              </li>
            </>
          )}
          {!IsNullOrUndefined(props.reportErrors?.SyncErrors) && (
            <li>
              <strong>Foodics to QuickBooks Sync: </strong>
              {RenderReportErrors(props.reportErrors)}
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

function RenderReportErrors(reportErrors: ReportErrors): ReactElement {
  if (reportErrors.SyncErrors.length === 0) return <span>OK</span>;
  else {
    let syncErrorDates: string = reportErrors.SyncErrors.join(", ");
    let errorMessage: ReactElement = (
      <>
        <span style={{ color: "red" }}>Not able to sync the following dates: </span>
        <div style={{ color: "red" }}>{syncErrorDates}, The reports contain missing data.</div>
      </>
    );
    return errorMessage;
  }
}

function CheckReportValidity(lastReportValidation: string): ReactElement {
  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let validationDate: Date = new Date(lastReportValidation);
  let currentDate: Date = new Date();
  let monthDiff: number = currentDate.getMonth() - validationDate.getMonth();
  let yearDiff: number = currentDate.getFullYear() - validationDate.getFullYear();
  if (yearDiff > 0 || monthDiff > 1) {
    let lastNonValidatedDate = addMonths(-1, currentDate);
    let validationDay: string = String(validationDate.getDate());
    let validationMonth: string = monthNames[validationDate.getMonth()];
    let validationYear: string = String(validationDate.getFullYear());
    let lastNonValidatedMonth = monthNames[lastNonValidatedDate.getMonth()];
    let lastNonValidatedYear = String(lastNonValidatedDate.getFullYear());
    return (
      <span style={{ color: "red", whiteSpace: "pre-wrap" }} className="preWrap">
        Not up-to date. Last update: {validationDay} {validationMonth} {validationYear} Please validate the data until{" "}
        {lastNonValidatedMonth} {lastNonValidatedYear}.
      </span>
    );
  } else {
    return <>Up-to date.</>;
  }
}

/**
 *
 * @param {int} numberOfMonths
 * @param {Date} currentDate
 * @returns {Date} date after `numberOfMonths`
 */
function addMonths(numberOfMonths: number, currentDate: Date): Date {
  return new Date(currentDate.setMonth(currentDate.getMonth() + numberOfMonths));
}

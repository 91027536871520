/**
 * @since: 2024-1-3
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { API_ROUTES } from "Utils/constants";
import { AppPermissionSuccessfulResponseBody, BaseResponse, BaseServerErrorResponse, FailedResponseBody } from "Utils/types";
import { AxiosError } from "axios";
import { MessageResponse } from "components/AlgoBooks/utils/types";
import { axiosInstance } from "./axios";

export function LogErrorToServer(error: AxiosError) {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.DASHBOARD_LOG_ERROR, error.toJSON())
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
export async function FetchAppPermissions(): Promise<BaseResponse<AppPermissionSuccessfulResponseBody, FailedResponseBody>> {
  try {
    let response = await axiosInstance.get(API_ROUTES.DASHBOARD_APP_PERMISSIONS);
    // debugger;
    const responseObject: BaseResponse<AppPermissionSuccessfulResponseBody, FailedResponseBody> = {
      meta: response,
      body: response.data,
    };
    if (response == undefined) {
      throw new Error(`No response from server.`);
    }
    return responseObject;
  } catch (error) {
    console.error(error);
    const failedResponse: BaseServerErrorResponse<FailedResponseBody> = {
      body: {
        meta: error,
        success: false,
        error: "Something went wrong while trying to log in.",
      },
    };
    return failedResponse;
  }
}
export function SendSupportMessage(message: string): Promise<MessageResponse> {
  let form: FormData = new FormData();
  form.append("message", message);
  return new Promise<MessageResponse>((resolve, reject) => {
    axiosInstance
      .post<MessageResponse>(API_ROUTES.DASHBOARD_MESSAGE_SUPPORT, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * @since: 2023-11-30
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { FirebaseProfile } from "Utils/types";
import { message } from "antd";
import { FirebaseOptions, initializeApp } from "firebase/app";
import {
  User as FirebaseUser,
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signOut,
  updateProfile,
} from "firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyAbcWjguuN3Rxc8iuAN-3P1ZLi0jN7L6wM",
  authDomain: "algoadvisory.firebaseapp.com",
  projectId: "algoadvisory",
  storageBucket: "algoadvisory.appspot.com",
  messagingSenderId: "1077467793569",
  appId: "1:1077467793569:web:f6c09a1ed309427fac88e0",
  measurementId: "G-88XR12FYJG",
};

// Initialize Firebase
const fireBaseApp = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const authProvider = new GoogleAuthProvider();
authProvider.addScope("https://www.googleapis.com/auth/userinfo.email");
authProvider.addScope("https://www.googleapis.com/auth/userinfo.profile");
authProvider.setCustomParameters({ prompt: "select_account" });
authProvider.setDefaultLanguage("en");

export const fireBaseAuth = getAuth(fireBaseApp);
fireBaseAuth.languageCode = "en";

export function VoidFunction() {
  return null;
}

export function SignInWithRedirect(onFulfilled: () => void = VoidFunction, onReject: () => void = VoidFunction) {
  signInWithRedirect(fireBaseAuth, authProvider)
    .then(() => {
      console.log("Signing in with redirect");
      onFulfilled();
    })
    .catch((err) => {
      console.error(err);
      onReject();
    });
}

export function SignInWithPopUp(onFulfilled: () => void = VoidFunction, onReject: () => void = VoidFunction) {
  signInWithPopup(fireBaseAuth, authProvider)
    .then(() => {
      console.log("Signing in with popup");
      onFulfilled();
    })
    .catch((err) => {
      console.log("Caught error Popup closed");
      console.error(err);
      onReject();
    });
}

export function SignOutFirebaseUser(onFulfilled: () => void = VoidFunction) {
  signOut(fireBaseAuth)
    .then(() => {
      console.log("Signing out user");
      onFulfilled();
    })
    .catch((err) => {
      message.error("Sign out failed, check logs for more details.");
      console.error(err);
    });
}

/**
 * Update firebase user account data using their google account info
 */
export function SyncFireBaseUserData(user: FirebaseUser) {
  if (user.providerData.length > 0 && user.providerData[0].email === user.email && user.providerData[0].providerId === "google.com") {
    const newFirebaseUserData: FirebaseProfile = {};
    if (user.providerData[0].displayName !== user.displayName) {
      newFirebaseUserData.displayName = user.providerData[0].displayName;
    }
    if (user.providerData[0].photoURL !== user.photoURL) {
      newFirebaseUserData.photoURL = user.providerData[0].photoURL;
    }
    if (Object.keys(newFirebaseUserData).length > 0)
      updateProfile(user, newFirebaseUserData).then(() => {
        console.log("Changes detected, firebase user profile data updated.");
      });
  }
}

/*
@since: 2023-3-16
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Link, Location, Navigate } from "react-router-dom";
import "./RoutesUtil.css";

/**
 *
 * @param {import("react-router-dom").Location} location
 * @param {number} resourceIndex
 * @returns {null | string}
 *
 * @example:
 * GetUrlResource("/a/b/c", 1) ==> "a"
 * GetUrlResource("/a/b/c", 2) ==> "b"
 */
export function GetUrlResource(location: Location, resourceIndex: number) {
  const urlParts = location.pathname.split("/");
  if (resourceIndex < urlParts.length) {
    return urlParts[resourceIndex];
  } else {
    return null;
  }
}

interface RedirectToDefaultPageProps {
  defaultRoute: string;
}
export function RedirectToDefaultPage({ defaultRoute }: RedirectToDefaultPageProps) {
  return <Navigate to={defaultRoute} replace={true} />;
}

interface TabLinkProps {
  to: string;
  text: string;
  disabled: boolean;
}

export function TabLink({ to, text, disabled }: TabLinkProps) {
  return (
    <Link to={to} style={{ padding: "20px" }} className={disabled ? "disabled-link" : null}>
      {text}
    </Link>
  );
}

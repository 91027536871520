/**
 * @since: 2023-11-30
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import axios, { AxiosError, AxiosInstance } from "axios";
import { LogErrorToServer } from "dataHandling/dashboard-core";
import { User } from "firebase/auth";
import _ from "lodash";
import { NavigateFunction } from "react-router-dom";
import { BAD_SERVER_RESPONSE, NO_CONNECTION_ROUTE, NO_PERM_ROUTE, NO_USER_ROUTE, SERVER_DOWN_ROUTE } from "./constants";
import { CompanyAppMapping } from "./types";

export function AddAuthAxiosRequestInterceptor(axiosInstance: AxiosInstance, user: User) {
  axiosInstance.interceptors.request.use(function (config) {
    // console.log(`Intercepting request for user ${user.displayName}  / ${user.email}`);
    return user.getIdToken().then((idToken) => {
      config.headers.Authorization = `Bearer ${idToken}`;
      return config;
    });
  });
}

export function AddAuthAxiosResponseInterceptor(axiosInstance: AxiosInstance, navigate: NavigateFunction) {
  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      return HandleResponseErrors(error, navigate);
    }
  );
}

/**
 *
 * @returns false if no permission errors were matched
 */
export function HandleResponseErrors(error: AxiosError, navigate: NavigateFunction): Promise<unknown> | boolean {
  // received response from server
  if (error.response !== undefined) {
    if (_.isEqual(error.response.data, BAD_SERVER_RESPONSE.NO_APP_ACCESS)) {
      navigate(NO_PERM_ROUTE);
      return new Promise(() => {}); // Cancel any following error handling
    } else if (_.isEqual(error.response.data, BAD_SERVER_RESPONSE.USER_UNREGISTERED)) {
      navigate(NO_USER_ROUTE);
      return new Promise(() => {});
    }
  } else {
    const noResponseFromServer = !error.response && error.code === "ERR_NETWORK";
    if (window.navigator.onLine && noResponseFromServer) {
      LogErrorToServer(error); // Log will be received only if server was not actually down
      navigate(SERVER_DOWN_ROUTE);
      return new Promise(() => {});
    } else if (!window.navigator.onLine && noResponseFromServer) {
      navigate(NO_CONNECTION_ROUTE);
      return new Promise(() => {});
    } else if (axios.isCancel(error)) {
      return Promise.reject(error);
    }
  }
  return Promise.reject(error);
}

/**
 * @returns A valid company for the selected application, null if no match found
 */
export function GetValidCompanyForApp(companyAppMapping: CompanyAppMapping, appName: string): string | null {
  for (let [company, applications] of Object.entries(companyAppMapping)) {
    if (applications.includes(appName)) {
      return company;
    }
  }
  return null;
}

export function IsNotNullOrUndefined(value: any): boolean {
  return !(value === undefined || value === null);
}

/**
 * @param objects  [{id:1,b:2}, {id:2,b:2}]
 * @param property id
 * @returns object by key {1:{id:1,b:2}, 2:{id:2,b:2}}
 */
export function ListToObjectByProperty<ObjectType extends { [key: string]: any }>(
  objects: ObjectType[] = [],
  property: string = ""
): { [property: string]: ObjectType } {
  return Object.assign({}, ...objects.map((object) => ({ [object[property]]: object })));
}

/**
 * @param data text
 * @param fileName
 */
export function DownloadCsvFile(data: BlobPart, fileName: string) {
  // var bytes = new Uint8Array(data);
  var blob = new Blob([data], { type: "text/csv;charset=utf-8" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName + ".csv";
  link.click();
}

/**
 * @param data sequence of bytes
 * @param fileName
 */
export function DownloadExcelFile(data: ArrayBuffer, fileName: string) {
  var blob = new Blob([data], { type: "application/octetstream" });
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName + ".xlsx";
  link.click();
}

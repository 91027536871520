/**
 * @since: 2023-12-7
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

// Prod
export const BASE_URL = "https://serverdashboard.algoadvisory.ai/";
export const SOCKET_URL = "wss://serverdashboard.algoadvisory.ai/ws/reports/";
export const CLIENT_URL = "https://dashboard.algoadvisory.ai/";

// QA
// export const CLIENT_URL = "https://dashboard-qa.algoadvisory.ai/";
// export const BASE_URL = "https://serverdashboard-qa.algoadvisory.ai/";
// export const SOCKET_URL = "wss://serverdashboard-qa.algoadvisory.ai/ws/reports/";

// Development
// export const CLIENT_URL = "http://localhost:3000/";
// export const SOCKET_URL = "ws://localhost:8000/ws/reports/";
// export const BASE_URL = "http://localhost:8000/";

// Local Network
// export const CLIENT_URL = "http://192.168.0.224:3000/";
// export const SOCKET_URL = "ws://192.168.0.224:8000/ws/reports/";
// export const BASE_URL = "http://192.168.0.224:8000/";

//Disable
// export const CLIENT_URL = "";
// export const SOCKET_URL = "";
// export const BASE_URL = "";

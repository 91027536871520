/**
 * @since: 2023-12-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import PageMessage from "components/AlgoBooks/components/PageMessage";
import "components/Shared/Styles/styles.css";
import { Link } from "react-router-dom";

export function PermissionDeniedPage() {
  return (
    <div className="container-center">
      <PageMessage
        status={"error"}
        title={"Access Denied"}
        subTitle={
          <span>
            <span>You currently do not have permission to view this page!</span>
            <br />
            <span>
              If you believe this is not intended, please contact AlgoTraders at
              <a href="mailto: support@algotraders.ai"> support@algotraders.ai</a>.
            </span>
            <br />
            <span>For more information and urgent inquires, you can reach us at +961 (0) 1 203 088 ext 4</span>
            <Link to="/" style={{ display: "block" }} reloadDocument>
              Go back home.
            </Link>
          </span>
        }
      />
    </div>
  );
}

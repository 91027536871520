import { Result } from "antd";
// import "antd/dist/antd.min.css";

export default function PageMessage(props) {
  return (
    <>
      <Result status={props.status} title={props.title} subTitle={props.subTitle} />
    </>
  );
}

/*
@since: 2023-3-16
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { InputNumber, Tooltip } from "antd";
import { Decimal } from "decimal.js";
import {
  CURRENCY,
  CURR_LBP_PRICE_COL_TITLE,
  CURR_USD_PRICE_COL_TITLE,
  MAXIMUM_PRICE,
  MINIMUM_PRICE,
  PRICES_CEILING,
} from "../../Utils/Constants";
/**
 *
 * @param {Object} priceUpdaterConfig
 * @param {number} newLbpRate : is recommended rate initially, then custom input rate if modified
 * Add new_usd, new_lbp to all the records in resource data
 */
export function AddExtraPriceFields(resourceData, newLbpRate) {
  return resourceData.map((resourceInstance) => {
    // Constant is 0 because last lbp rate used already includes the constant at the time of update
    resourceInstance.curr_lbp_price = resourceInstance.foodics_lbp_price;
    resourceInstance.new_usd_price = resourceInstance.usd_price;

    // New lbp rate is either modified by user or the recommended rate which already includes the constant, so no need for additional constant here
    resourceInstance.new_lbp_price = CalculateRoundedLbpPrice(resourceInstance.new_usd_price, newLbpRate, 0);
    resourceInstance.state = { inputStatus: "" };
    return resourceInstance;
  });
}

/**
 * @param {number | null} price
 * @param {number | null} rate
 * @param {number | null} constant : Constant added to rate
 * calculates LBP price to the nearest 1000
 */
export function CalculateRoundedLbpPrice(price, rate, constant) {
  const lbpPrice = CalculateLbpPrice(price, rate, constant);
  return Number(RoundUpToNearest(PRICES_CEILING, lbpPrice));
}

/**
 *
 * @param {number | null} price
 * @param {number | null} rate
 * @param {number | null} constant : Constant added to rate
 * @note: Equivalent to python MenuDataUpdater:CalculateNewPrice
 * @returns {Decimal | null} lbpPrice
 */
export function CalculateLbpPrice(price, rate, constant) {
  if (price == null || rate == null || constant == null) {
    return null;
  }
  const actualRate = new Decimal(rate).add(new Decimal(constant));
  const actualPrice = new Decimal(price);

  return actualPrice.mul(actualRate);
}

export function EditableCellRenderer(setRecordState, recordsState, editModeOn, newLbpRate) {
  return function EditablePriceCell(price, record, index) {
    const recordMatchingFunction = (element) => element.foodics_id == record.foodics_id;
    const onPriceChange = (newInputPrice) => {
      ValidateAndEditCurrentRecord(newInputPrice, record, newLbpRate, 0);
      UpdateRecordState(record, setRecordState, recordsState, recordMatchingFunction);
    };
    return <InputPrice editModeOn={editModeOn} record={record} onchange={onPriceChange} />;
  };
}

export function EditableComboCellRenderer(setRecordState, recordsState, editModeOn, newLbpRate) {
  return function EditablePriceCell(price, record, index) {
    const recordMatchingFunction = (element) =>
      element.option.foodics_id == record.option.foodics_id &&
      element.option.item.foodics_id == record.option.item.foodics_id &&
      element.combo_size.foodics_id == record.combo_size.foodics_id &&
      element.combo_size.combo.foodics_id == record.combo_size.combo.foodics_id &&
      element.product.foodics_id == record.product.foodics_id;
    const onPriceChange = (newInputUsdPrice) => {
      ValidateAndEditCurrentRecord(newInputUsdPrice, record, newLbpRate, 0);
      UpdateRecordState(record, setRecordState, recordsState, recordMatchingFunction);
    };
    return <InputPrice editModeOn={editModeOn} record={record} onchange={onPriceChange} />;
  };
}

export function GenerateCurrPriceColumns(currentRate) {
  return [
    {
      title: CURR_USD_PRICE_COL_TITLE,
      dataIndex: "usd_price",
      sorter: (a, b) => a.usd_price - b.usd_price,
      render: PriceCellRenderer(CURRENCY.USD),
    },
    {
      title: CURR_LBP_PRICE_COL_TITLE,
      dataIndex: "curr_lbp_price",
      sorter: (a, b) => a.curr_lbp_price - b.curr_lbp_price,
      render: ValidCurrLbpPriceCellRenderer(CURRENCY.LBP, currentRate),
    },
  ];
}

/**
 *
 * @param {Object<Array<Object>>} items
 * @param {number} rate
 */
export function FormatItemsDataForTable(items, newLbpRate) {
  items.products = AddExtraPriceFields(items.products, newLbpRate);
  items.modifierOptions = AddExtraPriceFields(items.modifierOptions, newLbpRate);
  items.comboItemOptionSizes = AddExtraPriceFields(items.comboItemOptionSizes, newLbpRate);
}

/**
 *
 * @returns antd column object for the New LBP Price column
 */
export function GetNewLbpPriceColumn() {
  return {
    title: <>New Price ({CURRENCY.LBP})</>,
    dataIndex: "new_lbp_price",
    render: PriceCellRenderer(CURRENCY.LBP),
  };
}

export function InputPrice({ editModeOn, record, onchange }) {
  return (
    <InputNumber
      type="number"
      disabled={!editModeOn}
      placeholder={ToReadableCurrency(record.new_usd_price, "USD")}
      value={record.new_usd_price}
      controls={false}
      onChange={onchange}
      min={MINIMUM_PRICE}
      max={MAXIMUM_PRICE}
      status={record?.state?.inputStatus || ""}
    />
  );
}

/**
 *
 * @param {string | number} string
 * @returns if string contains only numbers
 */
export function IsNumeric(string) {
  if (typeof string == "number") return true;
  if (typeof string != "string") return false; // we only process strings!
  return (
    !isNaN(string) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(string))
  ); // ...and ensure strings of whitespace fail
}

export function PriceCellRenderer(currency) {
  return (price, record, index) => <span>{ToReadableCurrency(price, currency)}</span>;
}

export function ValidCurrLbpPriceCellRenderer(currency, currentRate) {
  return (price, record, index) => {
    let style = {};
    const currUpdaterLbpPrice = CalculateRoundedLbpPrice(record.usd_price, currentRate, 0);
    const currFoodicsLbpPrice = record.curr_lbp_price;
    const matchesFoodicsPrice = currUpdaterLbpPrice == currFoodicsLbpPrice;
    const readableFoodicsPrice = ToReadableCurrency(price, currency);
    if (matchesFoodicsPrice) {
      return <span>{readableFoodicsPrice}</span>;
    } else {
      return (
        <Tooltip title="The LBP price does not match the current rate">
          <span style={{ color: "red" }}>{readableFoodicsPrice}</span>
        </Tooltip>
      );
    }
  };
}

/**
 *
 * @param {Decimal | null} nearest
 * @param {Decimal | null} value
 * @return the value round up to the nearest {nearest}
 */
export function RoundUpToNearest(nearest, value) {
  if (value === null || nearest === null) return null;
  return value.div(nearest).ceil().mul(nearest);
}

/**
 *
 * @param {number} number
 * @param {string} currency
 * @returns number to Converted currency string,
 * @example 12.3, "USD" -> $12.3
 */
export function ToReadableCurrency(number, currency) {
  if (number === undefined || number === null) return "N / A";
  const readableNumber = new Intl.NumberFormat("en-US", { style: "currency", currency: currency }).format(number);
  return readableNumber;
}

/**
 *
 * @param {number} number
 * @returns {string} readable number (comma separated)
 */
export function ToReadableNumber(number) {
  return new Intl.NumberFormat().format(number);
}

function UpdateRecordState(newRecord, setStateFunction, recordsState, cellMatchingFunction) {
  let newRecordState = [...recordsState];
  const recordIndex = newRecordState.findIndex(cellMatchingFunction);
  newRecordState[recordIndex] = newRecord;
  setStateFunction(newRecordState);
}

export function ValidateAndEditCurrentRecord(newInputPrice, record, rate, constant) {
  if (IsNumeric(newInputPrice)) {
    record.state.inputStatus = "";
    record.new_usd_price = newInputPrice;
    record.new_lbp_price = CalculateRoundedLbpPrice(newInputPrice, rate, constant);
  }
}

/*
@since: 2023-4-4
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import moment from "moment";

/**
 *
 * @param {Object} priceUpdaterConfig
 * @returns {number | null} recommended rate = latest available rate + rate constant
 */
export function GetRecommendedRate(priceUpdaterConfig) {
  const { latestLiraRate, addToRateConstant } = priceUpdaterConfig;
  if (addToRateConstant == null || latestLiraRate == null) {
    return null;
  }
  return priceUpdaterConfig.latestLiraRate + priceUpdaterConfig.addToRateConstant;
}
/**
 *
 * @param {string} utcTime
 * @return {moment.Moment} local moment object
 */
export function UtcToLocal(utcTime) {
  return moment.utc(utcTime).local();
}

/**
 *
 * @param {moment.Moment} momentObject
 * @return {string | null} readable time, null if date invalid
 */
export function ToReadableTime(momentObject) {
  if (!momentObject.isValid()) {
    return null;
  }
  return momentObject.format("dddd, MMMM Do YYYY, h:mm:ss a");
}

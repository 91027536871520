/*
@since: 2023-3-21
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Col, Row, message } from "antd";
import { useEffect, useState } from "react";
import { FetchStatuses } from "../../../../../dataHandling/PriceUpdaterAPI";
import CustomTabs from "../../Utils/CustomTabs";
import { FormatItemsDataForTable } from "../../Utils/PriceTableUtil";
import NavBarOperations from "./NavBarOperations";
import NavBarRates from "./NavBarRates";

export default function PriceUpdaterSettings({ settingsProps, customTabProps }) {
  // Global state
  const { priceUpdaterConfig } = usePriceConfigContext();
  const { customNewRate, products, modifierOptions, comboItemOptionSizes, setProducts, setModifierOptions, setComboItemOptionSizes } =
    settingsProps;
  // Local State
  const [downloaderStatus, setDownloaderStatus] = useState(priceUpdaterConfig.downloaderStatus);
  const [updaterStatus, setUpdaterStatus] = useState(priceUpdaterConfig.updaterStatus);
  const [popConfirmOpen, setPopConfirmOpen] = useState(false);

  useEffect(() => {
    const statusWatcher = setInterval(() => {
      FetchStatuses()
        .then((response) => {
          if (response === undefined) {
            message.error("Failed to fetch price updater config");
          } else {
            setDownloaderStatus(response.data.downloaderStatus);
            setUpdaterStatus(response.data.updaterStatus);
          }
        })
        .catch((err) => {
          message.error("An error occurred, check logs for more details");
          console.error(err);
          // check why there was an error
        });
    }, 1000); // 1s

    return () => {
      clearInterval(statusWatcher);
    };
  }, []);

  // Updates recommended rate
  useEffect(() => {}, [priceUpdaterConfig.latestLiraRate, priceUpdaterConfig.addToRateConstant]);

  // Update New Price column
  useEffect(() => {
    const items = { products, modifierOptions, comboItemOptionSizes };
    FormatItemsDataForTable(items, customNewRate);
    setProducts(items.products);
    setModifierOptions(items.modifierOptions);
    setComboItemOptionSizes(items.comboItemOptionSizes);
  }, [customNewRate]);

  return (
    <>
      <Row justify="space-between">
        <Col xxl={8} xl={8} xs={24} sm={24} md={24} lg={24}>
          <NavBarRates settingsProps={settingsProps} updaterStatus={updaterStatus} downloaderStatus={downloaderStatus} />{" "}
        </Col>
        <Col xxl={8} xl={9} xs={24} sm={24} md={24} lg={24}>
          <CustomTabs
            {...customTabProps}
            antdTabProps={{
              tabBarStyle: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              },
            }}
          />
        </Col>
        <Col xxl={8} xl={7} xs={24} sm={24} md={24} lg={24}>
          <NavBarOperations
            settingsProps={settingsProps}
            downloaderState={[downloaderStatus, setDownloaderStatus]}
            updaterState={[updaterStatus, setUpdaterStatus]}
            popConfirmState={[popConfirmOpen, setPopConfirmOpen]}
            customNewRate={customNewRate}
          />
        </Col>
      </Row>
    </>
  );
}

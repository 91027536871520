/**
 * @since: 2023-12-21
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { BookOutlined } from "@ant-design/icons";
import { Col, Row } from "antd";
import { USER_GUIDE_URL } from "../utils/ComponentUtils/ReportTable/constants";

export function UserGuideButton() {
  return (
    <Row
      style={{ display: "flex", justifyContent: "center", alignItems: "center", color: "white", padding: "8px" }}
      onClick={() => window.open(USER_GUIDE_URL, "_blank")}
      className="custom-dropdown"
    >
      <Col sm={3} xs={24} style={{display:"flex", justifyContent:"center"}}>
        <BookOutlined style={{ color: "white", margin: "3px" }} />
      </Col>
      <Col sm={2} xs={24}></Col>
      <Col sm={10} xs={0}>
        User Guide
      </Col>
    </Row>
  );
}

/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { PlusOutlined } from "@ant-design/icons";
import { ToReadableDate } from "Utils/HumanReadable";
import { IsNotNullOrUndefined } from "Utils/helpers";
import { DateSelectionHandle } from "Utils/types";
import type { SelectProps } from "antd";
import { Button, Col, DatePicker, DatePickerProps, Row, Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Dayjs } from "dayjs";
import { MouseEventHandler, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import "./MonthSelection.scss";

interface DateSelectionProps {
  availableDates: string[];
  loading?: boolean;
  disabledAll?: boolean;
  initialDateSelectionValue: string | null;
  onDatePickerClear?: () => void;
  onExistingDateSelection?: (date: string, createNewDate: boolean) => void;
}
export const MonthSelection = forwardRef<DateSelectionHandle, DateSelectionProps>(
  (
    {
      onDatePickerClear = () => {},
      onExistingDateSelection = () => {},
      loading = false,
      disabledAll = false,
      ...props
    }: DateSelectionProps,
    ref
  ) => {
    useImperativeHandle(
      ref,
      () => ({
        ClearDatePicker,
        SelectNewDateOption,
      }),
      []
    );
    // Select
    const [dateSelectionOptions, setDateSelectionOptions] = useState<DefaultOptionType[]>([]);
    const [dataSelectionOpen, setDateSelectionOpen] = useState<boolean>(false);
    const [dateSelectionValue, setDateSelectionValue] = useState<string | null>(props.initialDateSelectionValue);

    const onDropdownVisibleChange = (open: boolean) => {
      if (datePickerVisible) {
        // setDatePickerOpen(false);
      } else {
        setDateSelectionOpen(open);
      }
    };

    const onDateOptionSelection: SelectProps["onSelect"] = (value, option) => {
      if (value !== "date-picker") {
        setDateSelectionValue(value);
        onExistingDateSelection(value, false);
      } else {
        setDateSelectionOpen(true);
        setDateSelectionValue(null);
      }
    };

    const SelectNewDateOption = (date: string) => {
      setDateSelectionValue(date);
    };

    // DatePicker
    const [datePickerPopupOpen, setDatePickerPopupOpen] = useState<boolean>(false);
    const [datePickerValue, setDatePickerValue] = useState<Dayjs | null>(null);
    const [datePickerVisible, setDatePickerVisible] = useState<boolean>(false);

    const toggleDatePickerVisibility = (open: boolean) => {
      if (!IsNotNullOrUndefined(datePickerValue)) setDatePickerVisible((open) => !open);
      setDatePickerPopupOpen((open) => !open);
    };

    const onAddNewDateClick: MouseEventHandler<HTMLElement> = (event) => {
      showAndOpenDatePicker();
      event.stopPropagation();
    };

    const onDatePickerChange: DatePickerProps["onChange"] = (date, dateString) => {
      setDatePickerValue(date);
      setDateSelectionValue(null);
      if (IsNotNullOrUndefined(date)) {
        onExistingDateSelection(dateString, true);
      } else {
        ClearDatePicker();
      }
    };

    const ClearDatePicker = () => {
      setDatePickerValue(null);
      setDateSelectionValue(null);
      // cleared date value
      hideAndCloseDatePicker();
      setDateSelectionOpen(false);
      onDatePickerClear();
    };

    const showAndOpenDatePicker = () => {
      setDatePickerVisible(true);
      setDatePickerPopupOpen(true);
    };

    const hideAndCloseDatePicker = () => {
      setDatePickerVisible(false);
      setDatePickerPopupOpen(false);
    };

    const disabledDatePickerDates: DatePickerProps["disabledDate"] = (currentDatePickerDate) => {
      const datePickerDate = currentDatePickerDate.format("YYYY-MM");
      return props.availableDates.includes(datePickerDate);
    };

    const AddNewDateOption = {
      className: "add-new-date-option",
      value: "date-picker",
      label: (
        <Button
          onClick={onAddNewDateClick}
          style={{ width: "100%", height: "100%" }}
          icon={<PlusOutlined style={{ color: "green" }} />}
          disabled={datePickerVisible}
        >
          Add new date
        </Button>
      ),
    };

    useEffect(() => {
      const availableDates = props.availableDates.map(
        (date): DefaultOptionType => ({
          value: date,
          label: ToReadableDate(date),
        })
      );
      setDateSelectionOptions([AddNewDateOption, ...availableDates]);
    }, [props.availableDates, datePickerVisible]);

    return (
      <Row>
        <Col span={12}>
          <Select
            size="large"
            placeholder="Select a month"
            options={dateSelectionOptions}
            style={{ width: "200px" }}
            open={dataSelectionOpen}
            onDropdownVisibleChange={onDropdownVisibleChange}
            value={dateSelectionValue}
            onSelect={onDateOptionSelection}
            disabled={IsNotNullOrUndefined(datePickerValue) || disabledAll || loading}
            loading={loading}
          />
        </Col>

        {datePickerVisible && (
          <Col span={12}>
            <div className="date-picker-container">
              <DatePicker
                onChange={onDatePickerChange}
                value={datePickerValue}
                picker="month"
                placeholder="Add new date"
                onClick={(event) => event?.stopPropagation()}
                open={datePickerPopupOpen}
                onOpenChange={toggleDatePickerVisibility}
                placement="bottomLeft"
                style={{ marginLeft: "20px", marginRight: "20px" }}
                popupStyle={{ marginLeft: "20px", marginRight: "20px" }}
                size="large"
                disabled={disabledAll}
                disabledDate={disabledDatePickerDates || loading}
                allowClear
              />
            </div>
          </Col>
        )}
      </Row>
    );
  }
);

import { Card, Space } from "antd";
import { ReactNode } from "react";

function CustomBar({ children }: { children: ReactNode }) {
  return (
    <Card style={{ height: "100%", minHeight: "85vh", marginTop: "15px", width: "100%" }}>
      <Space size={10} direction="vertical" style={{ width: "100%" }}>
        {children}
      </Space>
    </Card>
  );
}

export { CustomBar };

/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import { IsNotNullOrUndefined } from "Utils/helpers";
import { Result } from "antd";

export function NoDataTypesPage(props: { companyName: string | null }) {
  return (
    <Result
      status={"error"}
      title={"No Data Types found"}
      subTitle={IsNotNullOrUndefined(props.companyName) && <span>{props.companyName} does not have registered data types.</span>}
    />
  );
}

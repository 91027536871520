/*
@since: 2023-3-16
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { IMAGES, PriceUpdaterConfigContext } from "Utils/constants";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { Layout, message } from "antd";
import TopBar from "components/AlgoBooks/components/TopBar/TopBar";
import { ListBranches } from "dataHandling/InventoryCountAPI";
import { GetPriceUpdaterConfig } from "dataHandling/PriceUpdaterAPI";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { COMBO_URL_PATH, MODIFIER_OPTION_URL_PATH, PRODUCT_URL_PATH } from "../../Utils/Constants";
import ErrorPage from "../../Views/ErrorPage";
import PriceUpdaterLayout from "../../Views/PriceUpdater/PriceUpdaterLayout";
import { RedirectToDefaultPage } from "../Utils/RoutesUtil";
import ComboPriceTable from "./ComboPriceTable";
import ModifierPriceTable from "./ModifierPriceTable";
import ProductPriceTable from "./ProductPriceTable";

export function PriceUpdaterApp() {
  const { Content } = Layout;

  // Context
  const { companyBranchMapping, setSelectedCompanyName, selectedCompanyName, setSelectionDisabled } = useCompanyContext();

  // Local state
  const [mainBranch, setMainBranch] = useState(null);
  const [mainBranches, setMainBranches] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(true);
  const [priceUpdaterConfig, setPriceUpdaterConfig] = useState(null);
  const [editMode, setEditMode] = useState(false);

  useEffect(() => {
    setBranchesLoading(true);
    Promise.all([GetPriceUpdaterConfig(), ListBranches()])
      .then(([config, branchesResponse]) => {
        if (config === undefined) {
          message.error("Failed to fetch price updater config");
        } else {
          setPriceUpdaterConfig(config);
        }

        if (branchesResponse.status === 200) {
          let data = branchesResponse.data;
          setMainBranches(data);
          setCompany(companyBranchMapping[selectedCompanyName] || data[0]);
        } else {
          message.error("Failed to fetch main branches from backend!");
        }
        setBranchesLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred, check logs for more details");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (selectedCompanyName !== undefined) setCompany(companyBranchMapping[selectedCompanyName]);
  }, [selectedCompanyName]);

  useEffect(() => {
    setSelectionDisabled(branchesLoading || editMode);
  }, [branchesLoading, editMode]);

  const branchCompanyMapping = _.invert(companyBranchMapping);

  function setCompany(branch) {
    setMainBranch(branch);
    setSelectedCompanyName(branchCompanyMapping[branch]);
  }
  return (
    <>
      <Layout style={{ height: "100%" }} className="foodics-tool">
        <TopBar logoUrl={IMAGES.FOODICSTOOL_LOGO} siteName="Foodics Pricing" />
        <Layout>
          <Content>
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
                paddingLeft: "20px",
                paddingRight: "20px",
                height: "100%",
              }}
            >
              <PriceUpdaterConfigContext.Provider
                value={{
                  mainBranch,
                  setCompany,
                  mainBranches,
                  setMainBranches,
                  branchesLoading,
                  setBranchesLoading,
                  branchCompanyMapping,
                  priceUpdaterConfig,
                  setPriceUpdaterConfig,
                  editMode,
                  setEditMode,
                }}
              >
                <Routes>
                  <Route element={<PriceUpdaterLayout />}>
                    <Route path="" element={<RedirectToDefaultPage defaultRoute={PRODUCT_URL_PATH} />} />
                    <Route index path={PRODUCT_URL_PATH} element={<ProductPriceTable />} />
                    <Route path={MODIFIER_OPTION_URL_PATH} element={<ModifierPriceTable />} />
                    <Route path={COMBO_URL_PATH} element={<ComboPriceTable />} />
                    <Route path="404" element={<ErrorPage />} />
                    <Route path="*" element={<ErrorPage />} />
                  </Route>
                </Routes>
              </PriceUpdaterConfigContext.Provider>
            </div>
          </Content>
        </Layout>
      </Layout>
    </>
  );
}

/*
@since: 2023-3-14
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { IMAGES } from "Utils/constants";
import { useAuthContext } from "Utils/hooks/useAuthContext";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { Layout, message } from "antd";
import TopBar from "components/AlgoBooks/components/TopBar/TopBar";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { ListBranches } from "../../../dataHandling/InventoryCountAPI";
import "../FoodicsTool.scss";
import "../index.css";
import ErrorPage from "./ErrorPage";
import InventoryCount from "./InventoryCount/InventoryCount";

const INITIAL_CONFIG_CONTEXT = {
  mainBranch: null,
  setCompany: null,
  mainBranches: [],
  setMainBranches: null,
  branchesLoading: null,
  setBranchesLoading: null,
  branchCompanyMapping: null,
};
export const ConfigContext = React.createContext(INITIAL_CONFIG_CONTEXT);

export default function FoodicsToolApp() {
  const { Content } = Layout;

  // Context
  const { logout, user } = useAuthContext();
  const { companyBranchMapping, setSelectedCompanyName, selectedCompanyName, setSelectionDisabled } = useCompanyContext();

  // Local state
  const [mainBranch, setMainBranch] = useState(null);
  const [mainBranches, setMainBranches] = useState([]);
  const [branchesLoading, setBranchesLoading] = useState(true);

  useEffect(() => {
    if (selectedCompanyName !== undefined) setCompany(companyBranchMapping[selectedCompanyName]);
  }, [selectedCompanyName]);

  useEffect(() => {
    setSelectionDisabled(branchesLoading);
  }, [branchesLoading]);

  useEffect(() => {
    setBranchesLoading(true);
    ListBranches()
      .then((response) => {
        if (response.status === 200) {
          let data = response.data;
          setMainBranches(data);
          setCompany(companyBranchMapping[selectedCompanyName] || data[0]);
          setBranchesLoading(false);
        } else {
          setBranchesLoading(false);
          message.error("Failed to fetch main branches from backend!");
        }
      })
      .catch((err) => {
        message.error("An error occurred, check logs for more details");
        console.error(err);
      });
  }, []);

  const branchCompanyMapping = _.invert(companyBranchMapping);

  function setCompany(branch) {
    setMainBranch(branch);
    setSelectedCompanyName(branchCompanyMapping[branch]);
  }

  return (
    <Layout style={{ height: "100%" }} className="foodics-tool">
      <TopBar logoUrl={IMAGES.FOODICSTOOL_LOGO} siteName="Foodics Inventory" />
      <Layout>
        <Content>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "20px",
              paddingLeft: "20px",
              paddingRight: "20px",
              height: "100%",
            }}
          >
            <ConfigContext.Provider
              value={{
                mainBranch,
                setCompany,
                mainBranches,
                setMainBranches,
                branchesLoading,
                setBranchesLoading,
                branchCompanyMapping,
              }}
            >
              <Routes>
                <Route path="" element={<InventoryCount />} />
                <Route path="404" element={<ErrorPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Routes>
            </ConfigContext.Provider>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

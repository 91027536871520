/**
 * @since: 2023-11-30
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { CompanyContext } from "Utils/constants";
import { useContext } from "react";

export function useCompanyContext() {
  const auth = useContext(CompanyContext);
  return auth;
}

import { QueriedRecipes, RecipeItems } from "Utils/types";
import { Row, Select, message } from "antd";
import { DefaultOptionType } from "antd/es/select";
import isEmpty from "just-is-empty";
import { useEffect, useState } from "react";
import { FetchIngredients, FetchRecipeFromItem } from "../../../dataHandling/RecipeBook";
import RecipeList from "./RecipeViewer/RecipeList";

interface RecipeBookToolBarProps {
  mainBranch: string;
  setRecipeId: React.Dispatch<React.SetStateAction<string | null>>;
  recipeId: string | null;
}

const { Option } = Select;

export function RecipeBookToolBar(props: RecipeBookToolBarProps) {
  const mainBranch = props.mainBranch;
  const setRecipeId = props.setRecipeId;
  const recipeId = props.recipeId;

  const [items, setItems] = useState<RecipeItems>([]);
  const [selectedItem, setSelectedItem] = useState<string | null>({});
  const [recipesList, setRecipesList] = useState<QueriedRecipes>([]);
  const [optionLoading, setOptionLoading] = useState<boolean>(true);
  const [recipesLoading, setRecipesLoading] = useState<boolean>(false);

  const changeHandler = (value: string, option: DefaultOptionType) => {
    setSelectedItem(value);
  };

  // Fetch new item options on branch change
  useEffect(() => {
    if (mainBranch) {
      setOptionLoading(true);
      FetchIngredients(mainBranch)
        .then((ingredients) => {
          setItems(ingredients);
          cleanState();
          setOptionLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching ingredients, check logs for more details.");
          console.error(err);
        });
    }
  }, [mainBranch]);

  // Fetch corresponding recipes on item selection
  useEffect(() => {
    if (selectedItem && !isEmpty(selectedItem)) {
      setRecipesLoading(true);
      FetchRecipeFromItem(selectedItem, mainBranch)
        .then((recipes) => {
          setRecipesList(recipes);
          setRecipesLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching recipe for ingredient, check logs for more details.");
          console.error(err);
        });
    } else {
      setRecipesList([]);
    }
  }, [selectedItem]);

  const cleanState = () => {
    setRecipesList([]);
    setSelectedItem(null);
  };

  const selectorStyle = {
    width: "100%",
  };

  return (
    <>
      <Row>
        <Select
          showSearch
          disabled={optionLoading || recipesLoading}
          style={selectorStyle}
          onChange={changeHandler}
          placeholder="Item Name..."
          loading={optionLoading || items?.length === 0}
          value={selectedItem}
          filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
        >
          {items?.map((item) => (
            <Option key={item.sku} value={item.sku}>
              {item.name}
            </Option>
          ))}
        </Select>
      </Row>
      <Row>
        <RecipeList
          loading={recipesLoading}
          recipes={recipesList}
          recipeId={recipeId}
          setRecipeId={setRecipeId}
          listProps={{ header: <ListHeader /> }}
        />
      </Row>
    </>
  );
}

function ListHeader() {
  return <h2 style={{ margin: "0" }}>Recipes :</h2>;
}

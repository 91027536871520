import { InfoCircleTwoTone } from "@ant-design/icons";
import { RecipeDataType } from "Utils/types";
import { Card, Col, Divider, Result, Row, Space, Table } from "antd";
import { CSSProperties } from "react";
const { Column, ColumnGroup } = Table;

interface RecipeInfoProps {
  recipeData: RecipeDataType;
  recipeId: string | null;
  loading: boolean;
  mainBranch: string;
}

function RecipeInfo(props: RecipeInfoProps) {
  return (
    <div>
      <Space size={15} direction="vertical" style={{ width: "100%", cursor: "default" }}>
        <Row>
          <RecipeTable loading={props.loading} recipeData={props.recipeData} recipeId={props.recipeId} />
        </Row>

        <Row justify="space-between">
          <Col xl={11} lg={11} md={11} sm={11} xs={24}>
            <AdditionalInfo loading={props.loading} recipeData={props.recipeData} recipeId={props.recipeId} />
          </Col>
          <Col xl={11} lg={11} md={11} sm={11} xs={24}>
            <CostAnalysis loading={props.loading} recipeData={props.recipeData} recipeId={props.recipeId} />
          </Col>
        </Row>
      </Space>
    </div>
  );
}

interface RecipeTableProps {
  loading: boolean;
  recipeData: RecipeDataType;
  recipeId: string | null;
}

function RecipeTable(props: RecipeTableProps) {
  return (
    <>
      <h1 style={{ paddingTop: "10px" }}>{props.recipeData.name}</h1>
      {props.recipeId ? (
        <Table
          dataSource={props.recipeData["ingredients"]}
          loading={props.loading}
          size="small"
          bordered
          pagination={{ pageSize: 11 }}
          style={{ width: "100%" }}
          scroll={{ x: true }}
        >
          <ColumnGroup title="Ingredients">
            <Column title="Item" dataIndex="name" key="name" align="middle" />
            <Column title="Amount" dataIndex="amount" key="amount" align="middle" />
            <Column title="Measurement" dataIndex="measurement" key="measurement" align="middle" />
          </ColumnGroup>
          <ColumnGroup title="Cost" align="spaced">
            <Column title="# of RU" dataIndex="ru" key="ru" align="middle" />
            <Column title="RU cost" dataIndex="ruCost" key="ruCost" align="middle" />
            <Column title="Ingredient Cost" dataIndex="ingredientCost" key="ingredientCost" align="middle" />
          </ColumnGroup>
        </Table>
      ) : (
        <InitialView />
      )}
    </>
  );
}

interface CostAnalysisProps {
  loading: boolean;
  recipeData: RecipeDataType;
  recipeId: string | null;
}

function CostAnalysis(props: CostAnalysisProps) {
  const labelStyle: CSSProperties = { color: "#1e94fc" };
  const dividerStyle: CSSProperties = { margin: "5px" };
  return (
    <>
      {props.recipeId ? (
        <Card style={{ width: "100%", height: "100%" }} title="Cost Analysis" loading={props.loading}>
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Total Ingredients Cost</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["recipeCost"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>RU Cost</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["ruCost"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Budgeted Food Cost</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["budgetedFoodCost"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>% VAT</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["vat"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Suggested Selling Price</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["suggestedSellingPrice"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Actual Selling Price</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["price"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Actual Cost %</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["actualCost"]}</span>
            </Col>
          </Row>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

interface AdditionalInfoProps {
  loading: boolean;
  recipeData: RecipeDataType;
  recipeId: string | null;
}

function AdditionalInfo(props: AdditionalInfoProps) {
  const labelStyle: CSSProperties = { color: "#1e94fc" };
  const dividerStyle: CSSProperties = { margin: "5px" };
  return (
    <>
      {props.recipeId ? (
        <Card style={{ width: "100%", height: "100%" }} title="Additional Recipe Info" loading={props.loading}>
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Shelf Life</span>
            </Col>
            <Col span={12} align="right">
              <span>{"N/A"}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Recipe Yield</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["yield"]}</span>
            </Col>
          </Row>
          <Divider style={dividerStyle} type="horizontal" />
          <Row>
            <Col span={12}>
              <span style={labelStyle}>Procedure</span>
            </Col>
            <Col span={12} align="right">
              <span>{props.recipeData["procedure"]}</span>
            </Col>
          </Row>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

function InitialView() {
  return (
    <Result
      style={{ width: "100%", cursor: "default" }}
      icon={<InfoCircleTwoTone />}
      title="Search and select a recipe to load recipe info"
    />
  );
}
export { RecipeInfo };

/**
 * @since: 2023-11-23
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { ConfigProvider, Layout } from "antd";
import { SideBarMenu } from "components/SideBarMenu/SideBarMenu";

import { CompanyAppMapping, DashboardCompany } from "Utils/types";
import { useState } from "react";
import { Outlet } from "react-router-dom";
import "./Dashboard.scss";

const { Header, Content, Footer, Sider } = Layout;

interface DashboardProps {
  appPermissions: string[];
  companyPermissions: DashboardCompany[];
  companyAppMapping: CompanyAppMapping;
}

export function Dashboard(props: DashboardProps) {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(false);

  return (
    <ConfigProvider
      theme={{
        components: {
          Layout: {
            triggerBg: "#3e5594",
          },
        },
      }}
    >
      <Layout
        hasSider
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsedWidth="50px"
          className="sider"
          collapsed={sideBarCollapsed}
          onCollapse={() => setSideBarCollapsed((prevCollapseState: boolean) => !prevCollapseState)}
        >
          <SideBarMenu
            sideBarCollapsed={sideBarCollapsed}
            toggleSideBarCollapse={() => setSideBarCollapsed((prevCollapseState: boolean) => !prevCollapseState)}
            appPermissions={props.appPermissions}
            companyPermissions={props.companyPermissions}
            companyAppMapping={props.companyAppMapping}
          />
        </Sider>
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </ConfigProvider>
  );
}

export const averageLineAnnotation = {
  type: "line",
  borderColor: "black",
  borderDash: [6, 6],
  borderDashOffset: 0,
  borderWidth: 3,
  scaleID: "y",
  value: (ctx) => CalculateChartDataAverage(ctx),
};

function CalculateChartDataAverage(chartObj) {
  const values = chartObj.chart.data.datasets[0].data;
  return values.reduce((a: number, b: number) => a + b, 0) / values.length;
}

/*
@since: 2023-3-15
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Input, Row } from "antd";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UpdateUsdPrices } from "../../../../dataHandling/PriceUpdaterAPI";
import { NEW_PRICE_COL_TITLE } from "../../Utils/Constants";
import { AddExtraPriceFields, EditableCellRenderer, GenerateCurrPriceColumns, GetNewLbpPriceColumn } from "../Utils/PriceTableUtil";
import CustomEditSwitch from "./CustomEditSwitch";
import CustomPriceTable from "./CustomPriceTable";

export default function ModifierPriceTable() {
  // Context
  const { priceUpdaterConfig, mainBranch } = usePriceConfigContext();
  const { modifierOptions, dataLoading, setModifierOptions, setDataLoading, editMode, setEditMode, customNewRate } = useOutletContext();

  // State
  const [filteredModifierOptions, setFilteredModifierOptions] = useState(modifierOptions);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    onChange: (page, pageSize) => {
      setPaginationSettings({ ...paginationSettings, current: page });
    },
  });

  // Reverts unsaved changes
  useEffect(() => {
    // on table unmount
    return () => {
      // reset data changes
      const modifierOptionsUnchanged = [...modifierOptions];
      AddExtraPriceFields(modifierOptionsUnchanged, customNewRate);
      setModifierOptions(modifierOptionsUnchanged);
    };
  }, []);

  if (dataLoading) {
    return <></>;
  }

  function onEditModeChange(checked, event) {
    // toggle off = send update request
    if (!checked) {
      let changedModifierOptions = modifierOptions.filter((modifierOption) => modifierOption.usd_price != modifierOption.new_usd_price);

      // changes were made
      if (changedModifierOptions.length > 0) {
        changedModifierOptions = changedModifierOptions.map((modifierOption) => {
          modifierOption.usd_price = modifierOption.new_usd_price;
          return modifierOption;
        });
        const requestBody = { modifierOptions: changedModifierOptions };

        setDataLoading(true);
        UpdateUsdPrices(mainBranch, requestBody)
          .then((response) => {
            const items = response.data.data;
            const newModifierOptions = AddExtraPriceFields(items.modifierOptions, customNewRate);
            setModifierOptions(newModifierOptions);
            setDataLoading(false);
            console.log(response);
          })
          .catch((err) => {
            message.error("An error occurred while updating modifier prices, check logs for more details.");
            console.error(err);
          });
      }
    }
  }

  function SearchableColumn(columnName, updateData, searchField) {
    const [inputValue, setInputValue] = useState("");
    const fuse = new Fuse(modifierOptions, { keys: [searchField], findAllMatches: true });
    function searchChangeHandler(event) {
      const currInputValue = event.target.value;
      setInputValue(currInputValue);
      let filteredData;
      // show all records on empty string
      if (currInputValue == "") {
        filteredData = modifierOptions;
      } else {
        const filteredSearchResults = fuse.search(currInputValue);
        filteredData = filteredSearchResults.map((element) => element.item);
      }
      updateData(filteredData);
      // go to first page when searching
      setPaginationSettings({ ...paginationSettings, current: 1 });
    }
    return (
      <>
        {columnName}
        <Input placeholder={`Search ${columnName}`} value={inputValue} onChange={searchChangeHandler} />
      </>
    );
  }

  const productColumns = [
    {
      title: SearchableColumn("Name", setFilteredModifierOptions, "name"),
      dataIndex: "name",
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("SKU", setFilteredModifierOptions, "foodics_sku"),
      dataIndex: "foodics_sku",
      render: (sku) => <span>{sku}</span>,
    },
    {
      title: SearchableColumn("Modifier", setFilteredModifierOptions, "modifier_name"),
      dataIndex: "modifier_name",
      render: (modifierName) => <span>{modifierName}</span>,
    },
    ...GenerateCurrPriceColumns(priceUpdaterConfig.lastLbpRateUsed),
    {
      title: NEW_PRICE_COL_TITLE,
      dataIndex: "new_usd_price",
      sorter: (a, b) => a.new_usd_price - b.new_usd_price,
      render: EditableCellRenderer(setModifierOptions, modifierOptions, editMode, customNewRate),
    },
    GetNewLbpPriceColumn(),
  ];

  return (
    <>
      <Row justify="end">
        <CustomEditSwitch
          loading={dataLoading}
          disabled={dataLoading}
          checked={editMode}
          onClick={setEditMode}
          onChange={onEditModeChange}
        />
      </Row>
      <CustomPriceTable
        columns={productColumns}
        dataSource={filteredModifierOptions}
        rowKey="foodics_id"
        loading={dataLoading}
        pagination={paginationSettings}
      />
    </>
  );
}

import {
  FrequencySelectOption,
  Grouping,
  OptionsInstance,
  OptionsResponse,
  SalesBranch,
  SalesCategoryByBranch,
  SelectOption,
} from "Utils/types";
import { ALL_CATEGORIES_INDICATOR, GROUPING_BY_CATEGORY, GROUPING_BY_NONE } from "./constants";

export class Options {
  branches: SalesBranch[];
  currencies: SelectOption[];
  frequencies: FrequencySelectOption[];
  categoriesByBranch: SalesCategoryByBranch;
  selectedOptions: OptionsInstance;

  constructor(allOptions: OptionsResponse, initialBranchId: string | null = null) {
    this.branches = allOptions.Branch;
    this.currencies = allOptions.Currency;
    this.frequencies = allOptions.Frequency;
    this.categoriesByBranch = allOptions.Category;
    this.selectedOptions = this.GetInitialOptions(initialBranchId);
  }

  GetInitialOptions(initialBranchId: string | null = null): OptionsInstance {
    let defaultBranch;
    if (initialBranchId !== null) {
      defaultBranch = this.branches.find((branch) => branch.optionValue === initialBranchId);
    } else {
      defaultBranch = this.branches[0];
    }

    const defaultBranchId = defaultBranch!.optionValue;
    const defaultCategory = this.GetDefaultBranchCategory(defaultBranchId);
    return {
      Branch: defaultBranch!.optionValue,
      Currency: defaultBranch!.defaultCurrency,
      Frequency: this.frequencies[0].optionValue,
      GroupBy: Options.GetGroupBy(defaultCategory),
      Category: defaultCategory,
    };
  }

  GetBranchCategories(branchId: string): SelectOption[] {
    return this.categoriesByBranch[branchId];
  }
  GetDefaultBranchCategory(branchId: string): number {
    const branchCategories = this.GetBranchCategories(branchId);
    return branchCategories[0].optionValue;
  }

  static GetGroupBy(categoryId: number | string): Grouping {
    return categoryId == ALL_CATEGORIES_INDICATOR ? GROUPING_BY_NONE : GROUPING_BY_CATEGORY;
  }
}

export function UpdateSelectedGroupingAndCategory(
  newCategorySelection: number | string,
  selectedOptions: OptionsInstance,
  setSelected: React.Dispatch<React.SetStateAction<OptionsInstance>>
) {
  let grouping: Grouping = newCategorySelection == ALL_CATEGORIES_INDICATOR ? GROUPING_BY_NONE : GROUPING_BY_CATEGORY;
  setSelected({ ...selectedOptions, Category: newCategorySelection, GroupBy: grouping });
}

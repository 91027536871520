// Auth
// Main Components
import { message } from "antd";
// Hooks
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Api
import { FetchReportOptions } from "../../../dataHandling/algobooks";
import ReportTitle from "../components/ReportTable/ReportTitle";
// Redux
import ReportSelect from "../components/ReportSelect";
import ReportTable from "../components/ReportTable/ReportTable";
// Util Components
import { Loading } from "../components/Loading";
import ResultPage from "./ResultPage";
// Util Functions
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { SOCKET_URL } from "Utils/urls";
import { GetAllPeriodOptions } from "../utils/ComponentUtils/ReportSelect/DateUtils";
import { NON_CURRENCY_REPORTS } from "../utils/ComponentUtils/ReportTable/constants";
import { GetCurrencyListSelection, GetReportTypeSelection, URLisBase } from "../utils/ComponentUtils/ReportTable/utils";
import { ArrayUtil } from "../utils/JSUtils/Array";
import {
  CompanyOption,
  CurrencyType,
  Entity,
  Period,
  PeriodOptions,
  ReportOption,
  ReportParams,
  ReportTypeConfig,
  TitleConfig,
} from "../utils/types";

interface ReportsPageProps {
  defaultParams: ReportParams;
}

export function ReportsPage(props: ReportsPageProps) {
  // Context
  const { setSelectedCompanyName, selectedCompanyName, setSelectionDisabled } = useCompanyContext();

  // Utilities
  const navigate = useNavigate();

  // State
  const [selectorLoading, setSelectorLoading] = useState<boolean>(true);
  const [report, setReport] = useState<string | null>(null);
  const [algobooksCompany, setAlgoBooksCompany] = useState<string | null>(null); // realmId
  const [classes, setClasses] = useState<string[] | null>(null);
  const [departments, setDepartments] = useState<string[] | null>(null);
  const [grouping, setGrouping] = useState<string | null>(null); // "Monthly"
  const [period, setPeriod] = useState<string[]>([]); // ["startend", "startend"]
  const [currency, setCurrency] = useState<CurrencyType>([]);
  const [companyNote, setCompanyNote] = useState<string | null>(null);

  // Report Options
  const [reportOptions, setReportOptions] = useState<ReportOption[] | null>(null);
  const [groupingOptions, setGroupingOptions] = useState<string[]>(null);
  const [allPeriodOptions, setAllPeriodOptions] = useState<PeriodOptions>([]);
  const [periodOptions, setPeriodOptions] = useState<Period[]>([]);
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);
  const [companyOptions, setCompanyOptions] = useState<CompanyOption[]>([]);
  const [classOptions, setClassOptions] = useState<Entity[]>([]);
  const [departmentOptions, setDepartmentOptions] = useState<Entity[]>([]);
  const [reportTypeConfig, setReportTypeConfig] = useState<ReportTypeConfig>({});

  // Report State
  const [hideRows, setHideRows] = useState(true);
  const [reportLoading, setReportLoading] = useState<boolean>(true);
  const [validParameters, setValidParameters] = useState<boolean>(false);
  const [hasPermission, setHasPermission] = useState<boolean>(true);
  const [titleConfig, setTitleConfig] = useState<TitleConfig>({});
  const [isCurrencyReport, setIsCurrencyReport] = useState<boolean>(true);

  // Used to force report table to refresh and query new report
  const [queryCount, setQueryCount] = useState<number>(0);

  // Used to check if View Report button triggered report query
  const [prevQueryCount, setPrevQueryCount] = useState<number>(0);

  // cached url that reflects last selected valid options
  const [tempReportUrl, setTempReportUrl] = useState<string>("");

  // socket
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const [disable, setDisable] = useState<boolean>(true);
  const key = "updatable";

  // function that connects the socket
  const ConnectSocket = (): WebSocket => {
    console.log("Initializing new Socket Instance");
    const webSocket = new WebSocket(SOCKET_URL);
    // initiate heartbeat
    webSocket.onopen = function () {
      console.log("Socket connected");
      setSocket(webSocket);
    };

    // on socket error kill everything
    webSocket.onerror = function (event) {
      // alert("Server Error! Socket has been disconnected!");
      message.error("Server Error! Socket has been disconnected!");
      console.error("Websocket Error: ");
      console.error(event);
      webSocket.close();
    };
    webSocket.onclose = function (event) {
      ParseWebSocketError(event);
      setDisable(true);
      if (event.code !== WEBSOCKET_CLOSE_CODES.NORMAL_CLOSURE) {
        console.log("Socket Connection down");
        message.loading({ content: "Connecting...", key });
        setTimeout(() => {
          ConnectSocket();
          message.success({ content: "Connected!", key, duration: 2 });
        }, 1000);
      }
    };

    return webSocket;
  };

  // Runs on initial page load, set base report params if provided
  useEffect(() => {
    //Connect to socket
    const webSocket = ConnectSocket();
    //Get all report options
    FetchReportOptions()
      .then((response) => {
        // Temp logic to fix URL
        // if (window.location.pathname.includes("code")) {
        //   navigate("");
        // }
        // Non-company specific options
        setCurrencyOptions(response.currencyOptions);
        setGroupingOptions(response.groupingOptions);
        setCompanyOptions(response.companyOptions);
        setReportTypeConfig(response.reportTypeConfig);
        //Set report options based on url
        if (JSON.stringify(props.defaultParams) !== JSON.stringify({})) {
          console.log("Overriding with url params", props.defaultParams);
          let selectedCompany = response.companyOptions.find((obj: CompanyOption) => {
            return obj.realmId === props.defaultParams.realmId;
          });
          // Check if user has permission to access the report
          let departmentPermitted = false;
          let permittedDepartments = [];
          let selectedDepartments = [];
          for (let department of selectedCompany.departments) permittedDepartments.push(department.id);
          // Url contains more than one department
          const urlDepartmentsString = props.defaultParams.departments;
          if (urlDepartmentsString.length > 1) {
            selectedDepartments = urlDepartmentsString.split("-");
            departmentPermitted = true;
            for (let department of selectedDepartments) if (!permittedDepartments.includes(department)) departmentPermitted = false;
          } else {
            let selectedDepartment = urlDepartmentsString === "-" ? "" : urlDepartmentsString;
            departmentPermitted = permittedDepartments.includes(selectedDepartment);
          }
          if (!selectedCompany || !departmentPermitted) {
            setHasPermission(false);
          } else {
            // Set options based on url parameters
            const selectedGrouping = props.defaultParams.frequency;
            const selectedReportType = props.defaultParams.report;
            const allPeriodOptions = GetAllPeriodOptions(selectedCompany, selectedReportType, selectedGrouping);
            const selectedPeriodOptions = allPeriodOptions[selectedGrouping];
            const selectedCurrenciesList = URLParametersToList(props.defaultParams.currency);
            const selectedDepartmentsList = URLParametersToList(urlDepartmentsString);
            const selectedClassesList = URLParametersToList(props.defaultParams.classes);
            setGrouping(selectedGrouping);
            setReport(selectedReportType);
            setReportOptions(selectedCompany.reportOptions);
            setAllPeriodOptions(allPeriodOptions);
            setPeriodOptions(selectedPeriodOptions);
            setPeriod(props.defaultParams.period.split(","));
            setCurrency({
              selectedCurrencies: selectedCurrenciesList,
              multipliers: selectedCompany.exchangeRates,
            });
            SetCompany(props.defaultParams.realmId, response.companyOptions);
            setCompanyNote(selectedCompany.note || null);
            setDepartmentOptions(selectedCompany.departments);
            setDepartments(selectedDepartmentsList);
            setClassOptions(selectedCompany.classes);
            setClasses(selectedClassesList);
          }
        }
        //set default options when url blank
        else {
          console.log("no base params detected from url, loading with defaults!");
          let dashboardCompany = null;
          if (selectedCompanyName) {
            dashboardCompany = response.companyOptions.find((obj: CompanyOption) => {
              return obj.name === selectedCompanyName;
            });
          }
          const defaultCompany = dashboardCompany || response.companyOptions[0];
          const defaultReportType = defaultCompany.reportOptions[0].value;
          const defaultGrouping = response.groupingOptions[0];
          const defaultClasses = defaultCompany.classes;
          const defaultDepartments = defaultCompany.departments;
          UpdatePeriodOptions(defaultCompany, defaultReportType, defaultGrouping);
          setGrouping(defaultGrouping);
          setReportOptions(defaultCompany.reportOptions);
          setReport(defaultReportType);
          setClassOptions(defaultClasses);
          setDepartmentOptions(defaultDepartments);
          SetCompany(defaultCompany.realmId, response.companyOptions);
          setCompanyNote(defaultCompany.note || null);
          setDepartments([defaultDepartments[0].id]);
          setClasses([defaultClasses[0].id]);
          setCurrency({
            selectedCurrencies: GetCurrencyListSelection(response.reportTypeConfig, defaultReportType, defaultCompany),
            multipliers: defaultCompany.exchangeRates,
          });
        }
      })
      .then(() => {
        setSelectorLoading(false);
      })
      .catch((err) => {
        message.error(`Error occurred while fetching report options from server.`);
        console.error(err);
      });

    // eslint-disable-next-line
    // Clean up on dismount
    return () => {
      WaitForSocketConnection(webSocket, () => webSocket.close(WEBSOCKET_CLOSE_CODES.NORMAL_CLOSURE));
    };
  }, []);

  // Dashboard company selection --> IN app state changes
  useEffect(() => {
    const selectedCompanyObj = companyOptions.find((obj) => {
      return obj.name === selectedCompanyName;
    });
    // company options loaded
    if (selectedCompanyObj !== undefined) SetCompany(selectedCompanyObj?.realmId);
  }, [selectedCompanyName, companyOptions]);

  // Change class, department, reportType, period options & selected options when company is changed
  useEffect(() => {
    if (!selectorLoading) {
      var selectedCompanyObj = companyOptions.find((obj) => {
        return obj.realmId === algobooksCompany;
      });
      const reportOptions = selectedCompanyObj.reportOptions;
      const companyDepartments = selectedCompanyObj.departments;
      const companyClasses = selectedCompanyObj.classes;
      const reportType = GetReportTypeSelection(report, selectedCompanyObj);
      UpdatePeriodOptions(selectedCompanyObj, reportType, grouping);
      setReportOptions(reportOptions);
      setReport(reportType);
      setDepartments([companyDepartments[0].id]);
      setClasses([companyClasses[0].id]);
      setDepartmentOptions(companyDepartments);
      setClassOptions(companyClasses);

      setCurrency({
        selectedCurrencies: GetCurrencyListSelection(reportTypeConfig, reportType, selectedCompanyObj),
        multipliers: selectedCompanyObj.exchangeRates,
      });
      setCompanyNote(selectedCompanyObj.note || null);
    }
  }, [algobooksCompany]);

  // change period options when frequency is modified
  useEffect(() => {
    if (!selectorLoading) {
      var tempPeriodOptions = allPeriodOptions[grouping];
      setPeriodOptions(tempPeriodOptions);
      setPeriod([tempPeriodOptions[0].key]);
    }
  }, [grouping]);

  // Updating temp url based on parameter changes
  useEffect(() => {
    if (algobooksCompany && classes && departments && report && grouping && period && currency.selectedCurrencies && hasPermission) {
      var urlParameters = "/algobooks/";
      urlParameters += algobooksCompany + "/";
      // No departments selected
      if (JSON.stringify(departments) === JSON.stringify([""])) {
        // Add default department
        urlParameters += (departmentOptions[0]?.id || "-") + "/";
      } else {
        // Join selected departments
        urlParameters += departments.join("-") + "/";
      }
      // No classes selected
      if (JSON.stringify(classes) === JSON.stringify([""])) {
        // Add default class
        urlParameters += (classOptions[0]?.id || "-") + "/";
      } else {
        // Join selected classes
        urlParameters += classes.join("-") + "/";
      }
      urlParameters += report + "/";
      urlParameters += grouping + "/";
      urlParameters += period.join(",") + "/";
      urlParameters += currency.selectedCurrencies.join("-") + "/";
      if (urlParameters !== window.location.pathname) {
        setTempReportUrl(urlParameters);
      }
      console.log("cached URL", urlParameters);
    }
    // eslint-disable-next-line
  }, [algobooksCompany, classes, departments, report, grouping, period, currency.selectedCurrencies]);

  // Changes report title, triggered when report is updated
  useEffect(() => {
    setSelectionDisabled(reportLoading);
    if (!reportLoading) {
      setTitleConfig({
        company: algobooksCompany,
        classes,
        departments,
        report,
        grouping,
        selectorLoading,
        companyOptions,
        reportOptions,
        periodOptions,
        period,
        currency,
      });
    } else {
      setTitleConfig({});
    }
  }, [reportLoading]);

  // Set url with most recent parameters only when they are recognized as "valid" or "loaded"
  useEffect(() => {
    if (
      (validParameters || !selectorLoading) &&
      tempReportUrl &&
      (!URLisBase() || (URLisBase() && queryCount != prevQueryCount)) &&
      queryCount !== 0
    ) {
      navigate(tempReportUrl);
    }
    if (validParameters) {
      setTitleConfig({
        company: algobooksCompany,
        classes,
        departments,
        report,
        grouping,
        selectorLoading,
        companyOptions,
        reportOptions,
        periodOptions,
        period,
        currency,
      });
    }
  }, [validParameters, selectorLoading]);

  // Set report category (currency or non) based on report type change
  useEffect(() => {
    // Page Loaded and report type initialized
    if (report) {
      const isCurrencyReport = !NON_CURRENCY_REPORTS.includes(report);
      setIsCurrencyReport(isCurrencyReport);

      // reset to default currency option
      let selectedCompany;
      // if defined in state
      if (algobooksCompany || props.defaultParams.realmId) {
        const companyRealmId = algobooksCompany || props.defaultParams.realmId;
        selectedCompany = companyOptions.find((companyObj) => {
          return companyObj.realmId === companyRealmId;
        });
      } else {
        selectedCompany = companyOptions[0];
      }
      // Update currency

      if (!isCurrencyReport) {
        setCurrency({
          selectedCurrencies: GetCurrencyListSelection(reportTypeConfig, report, selectedCompany),
          multipliers: selectedCompany.exchangeRates,
        });
      }
      // Update period
      UpdatePeriodOptions(selectedCompany, report, grouping);
    }
  }, [report]);

  // socket connection status checker
  useEffect(() => {
    if (socket !== null) {
      console.log(`Socket Online`, socket);
    }
    setDisable(false);
  }, [socket]);

  /**
   *
   * @param {Object} companyObj
   * @param {String} reportType
   * @param {String} grouping
   * Updates available periods, selected periods according to parameters
   */
  function UpdatePeriodOptions(companyObj: CompanyOption, reportType: string, grouping: string): void {
    const toSelectedPeriodOptionsByFrequency = GetAllPeriodOptions(companyObj, reportType, grouping);
    const toSelectedPeriodOptions = toSelectedPeriodOptionsByFrequency[grouping];
    setAllPeriodOptions(toSelectedPeriodOptionsByFrequency);
    setPeriodOptions(toSelectedPeriodOptions);
    // Date selector empty
    if (period.length === 0) {
      const toSelectedPeriodObj = toSelectedPeriodOptions[0];
      setPeriod([toSelectedPeriodObj.key]);
    } else {
      // Date selector not empty, remove dates where no reports are available
      let unvalidatedPeriodKeys = [...period];
      let validPeriodKeys = [...period];
      for (let fromSelectedPeriodKey of unvalidatedPeriodKeys) {
        const matchingPeriod = toSelectedPeriodOptions.find((periodObj) => periodObj.key == fromSelectedPeriodKey);
        if (matchingPeriod === undefined) {
          ArrayUtil.RemoveByValue(validPeriodKeys, fromSelectedPeriodKey);
        }
      }
      setPeriod(validPeriodKeys);
    }
  }

  function SetCompany(realmId: string, overrideCompanyOptions?: CompanyOption[]) {
    setValidParameters(false);
    setAlgoBooksCompany(realmId);
    const selectedCompany = (overrideCompanyOptions || companyOptions).find((obj: CompanyOption) => obj.realmId === realmId);
    setSelectedCompanyName(selectedCompany?.name);
  }

  return (
    <div>
      {socket !== null ? (
        <>
          {hasPermission ? (
            <div>
              <ReportSelect
                departments={departments}
                setDepartments={setDepartments}
                classes={classes}
                setClasses={setClasses}
                setReport={setReport}
                report={report}
                setGrouping={setGrouping}
                isCurrencyReport={isCurrencyReport}
                grouping={grouping}
                setPeriod={setPeriod}
                period={period}
                setCurrency={setCurrency}
                currency={currency}
                reportOptions={reportOptions}
                groupingOptions={groupingOptions}
                periodOptions={periodOptions}
                currencyOptions={currencyOptions}
                classOptions={classOptions}
                departmentOptions={departmentOptions}
                disable={disable}
                reportLoading={reportLoading}
                queryCount={queryCount}
                setQueryCount={setQueryCount}
                setPrevQueryCount={setPrevQueryCount}
                setValidParameters={setValidParameters}
                validParameters={validParameters}
                loading={selectorLoading}
                hideRows={hideRows}
                setHideRows={setHideRows}
              />
              {!selectorLoading ? (
                <>
                  <ReportTitle titleConfig={titleConfig} />
                  {/* Make sure to keep the DetailView components props updated too */}
                  <ReportTable
                    loadedParams={props.defaultParams}
                    company={algobooksCompany}
                    companyRealmID={algobooksCompany}
                    companyOptions={companyOptions}
                    companyNote={companyNote}
                    isCurrencyReport={isCurrencyReport}
                    reportOptions={reportOptions}
                    classes={classes}
                    departments={departments}
                    report={report}
                    grouping={grouping}
                    period={period}
                    currency={currency}
                    currencyOptions={currencyOptions}
                    view={"General"}
                    socket={socket}
                    disableSelector={setDisable}
                    setLoading={setReportLoading}
                    loading={reportLoading}
                    queryCount={queryCount}
                    setQueryCount={setQueryCount}
                    prevQueryCount={prevQueryCount}
                    setPrevQueryCount={setPrevQueryCount}
                    setValidParameters={setValidParameters}
                    forceLoading={selectorLoading}
                    hideRows={hideRows}
                  />
                </>
              ) : (
                <></>
              )}
            </div>
          ) : (
            <ResultPage state="no permission"></ResultPage>
          )}
        </>
      ) : (
        <Loading tip="Connecting to server" />
      )}
    </div>
  );
}

/**
 *
 * @param {string} parameterString
 * @returns {Array<string>} parsedParameters
 */
function URLParametersToList(parameterString: string): string[] {
  if (parameterString === null || parameterString === "-") {
    return [""];
  } else {
    return parameterString.split("-");
  }
}

/**
 *  Make the function wait until the connection is made...
 *  @see https://stackoverflow.com/questions/13546424/how-to-wait-for-a-websockets-readystate-to-change
 *  */
function WaitForSocketConnection(socket: WebSocket, callback: () => void): void {
  setTimeout(function () {
    if (socket.readyState === WebSocket.OPEN) {
      console.log("Connection is made");
      if (callback != null) {
        callback();
      }
    } else {
      console.log("wait for connection...");
      WaitForSocketConnection(socket, callback);
    }
  }, 5); // wait 5 millisecond for the connection...
}

function ParseWebSocketError(event: CloseEvent): void {
  let reason;
  // See https://www.rfc-editor.org/rfc/rfc6455#section-7.4.1
  if (event.code == WEBSOCKET_CLOSE_CODES.NORMAL_CLOSURE)
    reason = "Normal closure, meaning that the purpose for which the connection was established has been fulfilled.";
  else if (event.code == WEBSOCKET_CLOSE_CODES.GOING_AWAY)
    reason = 'An endpoint is "going away", such as a server going down or a browser having navigated away from a page.';
  else if (event.code == WEBSOCKET_CLOSE_CODES.PROTOCOL_ERROR) reason = "An endpoint is terminating the connection due to a protocol error";
  else if (event.code == WEBSOCKET_CLOSE_CODES.UNSUPPORTED_DATA)
    reason =
      "An endpoint is terminating the connection because it has received a type of data it cannot accept (e.g., an endpoint that understands only text data MAY send this if it receives a binary message).";
  else if (event.code == 1004) reason = "Reserved. The specific meaning might be defined in the future.";
  else if (event.code == WEBSOCKET_CLOSE_CODES.NO_STATUS_RECEIVED) reason = "No status code was actually present.";
  else if (event.code == WEBSOCKET_CLOSE_CODES.ABNORMAL_CLOSURE)
    reason = "The connection was closed abnormally, e.g., without sending or receiving a Close control frame";
  else if (event.code == WEBSOCKET_CLOSE_CODES.INVALID_PAYLOAD_DATA)
    reason =
      "An endpoint is terminating the connection because it has received data within a message that was not consistent with the type of the message (e.g., non-UTF-8 [https://www.rfc-editor.org/rfc/rfc3629] data within a text message).";
  else if (event.code == WEBSOCKET_CLOSE_CODES.POLICY_VIOLATION)
    reason =
      'An endpoint is terminating the connection because it has received a message that "violates its policy". This reason is given either if there is no other sutible reason, or if there is a need to hide specific details about the policy.';
  else if (event.code == WEBSOCKET_CLOSE_CODES.MESSAGE_TOO_BIG)
    reason = "An endpoint is terminating the connection because it has received a message that is too big for it to process.";
  else if (event.code == WEBSOCKET_CLOSE_CODES.MANDATORY_EXTENSION)
    // Note that this status code is not used by the server, because it can fail the WebSocket handshake instead.
    reason =
      "An endpoint (client) is terminating the connection because it has expected the server to negotiate one or more extension, but the server didn't return them in the response message of the WebSocket handshake. <br /> Specifically, the extensions that are needed are: " +
      event.reason;
  else if (event.code == WEBSOCKET_CLOSE_CODES.INTERNAL_SERVER_ERROR)
    reason =
      "A server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.";
  else if (event.code == WEBSOCKET_CLOSE_CODES.TLS_HANDSHAKE_FAILURE)
    reason = "The connection was closed due to a failure to perform a TLS handshake (e.g., the server certificate can't be verified).";
  else reason = "Unknown reason";

  console.warn("The connection was closed for reason: " + reason);
}

const WEBSOCKET_CLOSE_CODES = {
  NORMAL_CLOSURE: 1000,
  GOING_AWAY: 1001,
  PROTOCOL_ERROR: 1002,
  UNSUPPORTED_DATA: 1003,
  NO_STATUS_RECEIVED: 1005,
  ABNORMAL_CLOSURE: 1006,
  INVALID_PAYLOAD_DATA: 1007,
  POLICY_VIOLATION: 1008,
  MESSAGE_TOO_BIG: 1009,
  MANDATORY_EXTENSION: 1010,
  INTERNAL_SERVER_ERROR: 1011,
  SERVICE_RESTART: 1012,
  TRY_AGAIN_LATER: 1013,
  TLS_HANDSHAKE_FAILURE: 1015,
};

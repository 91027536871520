import { Popover } from "antd";
import { CSSProperties, ReactElement, ReactNode } from "react";
import {
  CompanyOption,
  DetailHandlerType,
  DetailParameters,
  Entity,
  EntityField,
  RenderCellType,
  ReportCellValue,
  ReportColumn,
  ReportData,
  ReportRow,
  ReportTableProps,
  ReportTypeConfig,
  RowStyles,
} from "../../types.js";
import { COLUMN_PERCENTAGE_INDICATOR, REPORT_BORDERS, TABLE_COLUMN_STYLE } from "./constants.js";

function AreRowsLimited(reportData: ReportData | null): boolean {
  return !!reportData?.Errors?.RowsLimited;
}

function ContainsExternalUrls(reportData: ReportData): boolean {
  return reportData?.Errors?.DetailError?.quickbooksUrls?.length > 0;
}

/**
 *
 * @param {Array<String>} entityIds
 * @param {"departments" | "classes"} entityField
 * @param {string} companyRealmId
 * @param {Array<Object>} companyOptions
 * @returns
 */
function ConvertToEntityObjects(
  entityIds: string[],
  entityField: EntityField,
  companyRealmId: string,
  companyOptions: CompanyOption[]
): Entity[] {
  const company = companyOptions.find((company) => company.realmId == companyRealmId);
  const listEntityObjects: Entity[] = [];
  for (let entityId of entityIds) {
    if (entityId && company !== undefined) {
      const entityObject = company[entityField].find((entity) => entity.id == entityId);
      if (entityObject == undefined) continue;
      listEntityObjects.push(entityObject);
    }
  }
  return listEntityObjects;
}

/**
 *
 * @param {Array<Object>} columns
 * @param {Object} props
 * @param {Function} setDetailParams: Details report parameter setter, when clicking value will send thee correct parameters
 * @param {Function} setPopUp: Details pop up visibility setter
 * @returns
 */
function GenerateColumns(
  columns: ReportColumn[],
  props: ReportTableProps,
  setDetailParams: React.Dispatch<React.SetStateAction<DetailParameters>>,
  setPopUp: React.Dispatch<React.SetStateAction<boolean>>
): [ReportColumn[], ReportColumn[]] {
  const { isCurrencyReport } = props;
  var selectedCurrencies = props.currency.selectedCurrencies;
  var generatedColumns = [];
  var count = 1;

  const handleDetail = (reportCell: ReportCellValue, props: ReportTableProps) => {
    if ("href" in reportCell && reportCell.href !== "") {
      var currencyObject = { ...props.currency };
      currencyObject.selectedCurrencies = [reportCell.currency];
      setDetailParams({
        href: reportCell.href,
        title: reportCell.rowTitle,
        currency: currencyObject,
      });
      setPopUp(true);
    }
  };
  let globalColumnStyle = {
    fontFamily: TABLE_COLUMN_STYLE,
  };

  generatedColumns = GenerateStyledColumns(columns, count, handleDetail, props, globalColumnStyle, isCurrencyReport);

  var reportColumnsCleaned = RemoveDuplicatePercentageColumns(generatedColumns);
  return [generatedColumns, reportColumnsCleaned];
}

function GenerateStyledColumns(
  columns: ReportColumn[],
  columnCount: number,
  handleDetail: DetailHandlerType,
  props: ReportTableProps,
  globalColumnStyle: CSSProperties,
  isCurrencyReport: boolean
): ReportColumn[] {
  let generatedColumns: ReportColumn[] = [];
  columns.forEach((column) => {
    if (column.key === "COL1") {
      column.render = RenderFirstColumnCells;
      generatedColumns.push(column);
      columnCount += 1;
    } else {
      // selectedCurrencies.forEach((currency) => {
      //   let columnKey = `COL${columnCount}`;
      //   let generatedColumn = GenerateColumn(props, handleDetail, globalColumnStyle, column, currency, columnKey, isCurrencyReport);
      //   generatedColumns.push(generatedColumn);
      //   columnCount += 1;
      // });
      let columnKey = `COL${columnCount}`;
      let generatedColumn = GenerateColumn(props, handleDetail, globalColumnStyle, column, columnKey, isCurrencyReport);
      generatedColumns.push(generatedColumn);
      columnCount += 1;
    }
  });
  return generatedColumns;
}

/*
 *
 * @param {[Object]} props
 * @param {[function]} handleDetail
 * @param {[Object]} globalColumnStyle
 * @param {ReportColumn} reportColumn
 * @param {string} columnKey
 * @param {boolean} isCurrencyReport
 * @returns
 */
function GenerateColumn(
  props: ReportTableProps,
  handleDetail: DetailHandlerType,
  globalColumnStyle: CSSProperties,
  reportColumn: ReportColumn,
  columnKey: string,
  isCurrencyReport: boolean
): ReportColumn {
  const columnTitle = reportColumn.title;
  const isPercentageColumn = columnTitle === COLUMN_PERCENTAGE_INDICATOR || !isCurrencyReport;
  const formattedColumnTitle = FormatTitle(columnTitle);
  return {
    render: RenderCellValue(props, handleDetail, globalColumnStyle, isPercentageColumn),
    key: columnKey,
    dataIndex: columnKey,
    align: !isPercentageColumn ? "right" : "left",
    title: formattedColumnTitle,
    // multiplier: parseFloat(props.currency.multipliers[currency]),
    currency: reportColumn.currency, // take from column object
    endPeriod: reportColumn.endPeriod,
    width: "max-content",
  };
}

function RenderFirstColumnCells(columnObj: ReportColumn, rowObj: ReportRow, rowindex: number): ReactNode {
  var style = columnObj.class[0];
  if (columnObj.description) {
    let popOverContent = columnObj.description;
    return (
      <Popover overlayStyle={{ whiteSpace: "pre-line" }} content={popOverContent}>
        <span className={style}>{columnObj.value}</span>
      </Popover>
    );
  } else {
    return <span className={style}>{columnObj.value}</span>;
  }
}

/**
 *
 * @param {Object} props
 * @param {function} handleDetail
 * @param {*} globalColumnStyle
 * @param {boolean} isPercentageColumn
 * @returns function to render column values
 */
function RenderCellValue(
  props: ReportTableProps,
  handleDetail: DetailHandlerType,
  globalColumnStyle: CSSProperties,
  isPercentageColumn: boolean
): RenderCellType {
  return (value: ReportCellValue, row: ReportRow, rowindex: number) => {
    let style = "";
    let isUnvalidated = false;
    if (value && "class" in value) {
      if (value.class.includes("detail") && "href" in value && value.href == "") {
        removeItemAll(value.class, "detail");
      }
      style = value.class.join(" ");
      isUnvalidated = value.class.includes("unvalidated");
    }
    return (
      <span
        onClick={() => handleDetail(value, props)}
        className={style}
        style={isPercentageColumn && !isUnvalidated ? { color: "#4949f5", ...globalColumnStyle } : { ...globalColumnStyle }}
      >
        {value ? value.value : ""}
      </span>
    );
  };
}

/**
 *
 * Formats the rows and all their children
 * Deletes the children field if it's empty
 */
function GenerateReportRows(
  reportRows: ReportRow[],
  originalColumns: ReportColumn[],
  newColumns: ReportColumn[],
  currencyOptions: string[]
): ReportRow[] {
  var generatedReportRows: ReportRow[] = [];
  var numOfSelectedCurrencies = (newColumns.length - 1) / (originalColumns.length - 1);
  const colKeysWithCurrencyValues = GetColKeysForCurrencyColumns(newColumns, currencyOptions);
  reportRows.forEach((originalReportRow) => {
    var generatedRow = GenerateRow(originalReportRow, originalColumns, newColumns, numOfSelectedCurrencies, colKeysWithCurrencyValues);
    if (originalReportRow.children) {
      if (originalReportRow.children.length > 0) {
        // Recurse to row children
        generatedRow.children = [];
        generatedRow.children = GenerateReportRows(originalReportRow.children, originalColumns, newColumns, currencyOptions);
      } else {
        // empty children key causes visual issues
        delete generatedRow.children;
      }
    }
    generatedReportRows.push(generatedRow);
  });
  // res = MoveTotal(res);
  return generatedReportRows;
}

/**
 * Delete rows that contain only zeroes
 * Does not handle if children have non-zero values but add up to zero (parent row will be removed)
 */
export function HideZeroRows(reportRows: ReportRow[]): ReportRow[] {
  return HideZeroRowsRecursive(structuredClone(reportRows));
}

function HideZeroRowsRecursive(reportRows: ReportRow[]): ReportRow[] {
  let rowIndex = 0;
  while (rowIndex < reportRows.length) {
    const row = reportRows[rowIndex];
    let deleteRow = true;
    for (let [field, reportCell] of Object.entries(row)) {
      // is value column field
      if (field.startsWith("COL") && field !== "COL1") {
        if (parseFloat(reportCell.value) != 0) {
          deleteRow = false;
          break;
        }
      }
    }
    if (deleteRow) {
      reportRows.splice(rowIndex, 1);
    } else if (row.children) {
      row.children = HideZeroRowsRecursive(row.children);
    }
    if (!deleteRow) {
      rowIndex++;
    }
  }
  return reportRows;
}

const DATE_REGEX = /^\d\d\d\d-\d\d-\d\d$/;
function IsDate(string: string): boolean {
  return DATE_REGEX.test(string);
}

/**
 * Formats the row title, total (adds commas)
 */
function GenerateRow(
  reportRow: ReportRow,
  originalColumns: ReportColumn[],
  newColumns: ReportColumn[],
  numOfSelectedCurrencies: number,
  colKeysWithCurrencyValues: string[]
) {
  var newGeneratedRow = { ...reportRow };
  for (let i = 0; i < originalColumns.length; i++) {
    var colKey = "COL" + String(i + 1);
    var reportCell = { ...reportRow[colKey] };
    var originalCellValue = reportRow[colKey].value;
    // remove numbers from first col excluding the columns that include dates, e.g "1142000 مدفوع مقدما" <== "مدفوع مقدما"
    if (colKey === "COL1") {
      if (!IsDate(originalCellValue)) {
        // reportCell.value = originalCellValue.replace(/[0-9]/g, "").toUpperCase();
        reportCell.value = originalCellValue.toUpperCase();
        newGeneratedRow[colKey] = Object.assign({}, reportCell);
      }
    } else {
      reportCell.value = originalCellValue;
      // Apply calculation if value field
      let colInfo = GetItemByKey(colKey, newColumns);
      const isNotACurrencyColumn = !colKeysWithCurrencyValues.includes(colKey);
      if (isNotACurrencyColumn) {
        reportCell.value = originalCellValue;
      } else {
        if (colInfo) {
          if (IsNumber(reportCell.value)) {
            const parsedValue = parseFloat(originalCellValue);
            reportCell.value = FormatNumber(parsedValue);
            reportCell.currency = colInfo.currency;
          } else {
            // Capitalize if mixed field and remove numbers from Account and Split
            reportCell.value = reportCell.value.toUpperCase();
            const name = ExtractNodeText(colInfo.title).split(" ")[0].toUpperCase();
            if (["ACCOUNT", "SPLIT"].includes(name)) {
              reportCell.value = reportCell.value.replace(/[0-9]/g, "");
            }
          }
        }
      }
      newGeneratedRow[colKey] = Object.assign({}, reportCell);
    }
  }
  return newGeneratedRow;
}

const KNOWN_CURRENCY_COLUMNS: string[] = ["Amount", "Balance"];
/**
 *
 * @param newColumns
 * @param currencyOptions
 * @returns column keys of columns that have currency indicator
 */
function GetColKeysForCurrencyColumns(newColumns: ReportColumn[], currencyOptions: string[]) {
  const CURRENCY_REGEX = /\((.*)\)/;
  const colKeysForCurrencyColumns = [];
  for (let colIndex = 0; colIndex < newColumns.length; colIndex++) {
    const columnTitle = ExtractNodeText(newColumns[colIndex].title);
    let currencyCodeInTitle = columnTitle.match(CURRENCY_REGEX);
    if (
      (currencyCodeInTitle && currencyOptions && currencyOptions.includes(currencyCodeInTitle[1])) ||
      KNOWN_CURRENCY_COLUMNS.includes(columnTitle)
    ) {
      colKeysForCurrencyColumns.push(`COL${colIndex + 1}`);
    }
  }
  return colKeysForCurrencyColumns;
}

function IsNumber(value: string) {
  return /^(\$\s)?-?[0-9]\d*(\.\d+)?$/.test(value);
}

/**
 *
 * @param {string} key
 * @param {Array<Object>} itemList
 * @returns item whose "key" field value matches the key passed
 */
function GetItemByKey(key: string, itemList: ReportColumn[]): ReportColumn | null {
  var res = null;
  itemList.forEach((item) => {
    if (item.key === key) {
      res = item;
    }
  });
  return res;
}

function GetRowLevel(rowKey: string, rows: ReportRow[], level: number = 1) {
  var res = 0;
  if (rows.some((row) => row.key === rowKey)) {
    return level;
  } else {
    level = level + 1;
    rows.forEach((row) => {
      if ("children" in row && row.children) {
        var temp = GetRowLevel(rowKey, row.children, level);
        if (temp > 0) {
          res = temp;
        }
      }
    });
    return res;
  }
}

function GenerateRowStyles(rows: ReportRow[], reportType: string): RowStyles {
  var rowStyles: RowStyles = {};
  var template = REPORT_BORDERS[reportType];
  rows.forEach((row) => {
    var classes = [];
    if (template.includes(row["COL1"].value.toUpperCase())) {
      classes.push("highlight-top-border");
    } else {
      classes.push("highlight-default");
    }
    rowStyles[row.key] = classes;
  });
  return rowStyles;
}

function URLisBase() {
  const baseUrl = window.location.origin + "/algobooks";
  let currentURL = window.location.href;

  let lastCharacter = currentURL.charAt(currentURL.length - 1);
  if (lastCharacter === "/") {
    currentURL = currentURL.slice(0, window.location.href.length - 1); // removing last slash from URL for comparison
  }

  return baseUrl === currentURL;
}

/**
 *
 * @param {Array<Object>} columns
 * @returns Report columns after removing any duplicate percentage columns
 * Example:
 * SOCIALE TAHLIA  % % --> SOCIALE TAHLIA  %
 */
function RemoveDuplicatePercentageColumns(columns: ReportColumn[]): ReportColumn[] {
  var res: ReportColumn[] = [];
  var lastColumnWasPercentage = false;
  columns.forEach((column) => {
    const columnTitle = ExtractNodeText(column.title);
    if (!columnTitle.includes("%")) {
      res.push(column);
      lastColumnWasPercentage = false;
    } else {
      if (!lastColumnWasPercentage) {
        res.push(column);
      }
      lastColumnWasPercentage = true;
    }
  });
  return res;
}

/**
 * Remove 'children' property where value is empty list
 * @param rows
 */
function RemoveEmptyChildrenProperty(rows: ReportRow[]) {
  // run on all rows recursively, each time report is set
  rows.forEach((row) => {
    if (row.children && row.children?.length > 0) {
      RemoveEmptyChildrenProperty(row.children);
    } else {
      delete row.children;
    }
  });
}

function removeItemAll<Type>(arr: Type[], value: Type) {
  var i = 0;
  while (i < arr.length) {
    if (arr[i] === value) {
      arr.splice(i, 1);
    } else {
      ++i;
    }
  }
  return arr;
}

function ExtractNodeText(node: ReactElement | string | number): string | number | void {
  if (["string", "number"].includes(typeof node)) return node;
  if (node instanceof Array) return node.map(ExtractNodeText).join("");
  if (typeof node === "object" && node) return ExtractNodeText(node.props.children);
}

function FormatNumber(number: number): string {
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

/**
 *
 * @param {String} columnTitle, e.g: "SOCIALE TAHLIA Feb 2023 (SAR)"
 * @returns Column title with spaces replaced with new lines, if they match known title parts patterns
 */
function FormatTitle(columnTitle: string) {
  let titleRegexGroups: any[] = [];
  if (IsMonthlyTitle(columnTitle)) {
    titleRegexGroups = columnTitle.match(MONTH_COLUMN_TITLE_REGEX);
  } else if (IsQuarterlyTitle(columnTitle)) {
    titleRegexGroups = columnTitle.match(QUARTER_COLUMN_TITLE_REGEX);
  } else {
    titleRegexGroups = columnTitle.match(YEAR_COLUMN_TITLE_REGEX);
  }
  if (titleRegexGroups == null) {
    return columnTitle;
  }
  titleRegexGroups = titleRegexGroups.filter((group) => group !== undefined);
  const [completeTitle, ...titleParts] = titleRegexGroups;
  return titleParts ? titleParts.join("\n") : columnTitle;
}

function IsMonthlyTitle(title: string) {
  return title.toUpperCase().match(MONTH_REGEX);
}

function IsQuarterlyTitle(title: string) {
  return title.toUpperCase().match(QUARTER_REGEX);
}

function IsNullOrUndefined(value: any): boolean {
  return value === null || value === undefined;
}
function GetCurrencyListSelection(reportTypeConfig: ReportTypeConfig, reportType: string, company: CompanyOption): string[] {
  if (!IsNullOrUndefined(reportTypeConfig.SingleCurrencyReports[reportType])) return [reportTypeConfig.SingleCurrencyReports[reportType]];
  else return [company.baseCurrency];
}

function GetReportTypeSelection(previousReportType: string | null, newlySelectedCompany: CompanyOption): string {
  const matchedReportOption = newlySelectedCompany.reportOptions.find((reportOption) => reportOption.value === previousReportType);
  if (matchedReportOption) return matchedReportOption.value;
  else return newlySelectedCompany.reportOptions[0].value;
}

const MONTH_REGEX = /(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)/i;
const QUARTER_REGEX = /(Q1|Q2|Q3|Q4)/;
const MONTH_COLUMN_TITLE_REGEX = /(.*)\s(\w{3}\s\d{4})\s?(\(.*\))?/; //e.g 'SOCIALE TAHLIA Feb 2023 (SAR)'
const QUARTER_COLUMN_TITLE_REGEX = /(.*)\s(\w{2}\s\d{4})\s?(\(.*\))?/; //e.g 'SOCIALE TAHLIA Q4 (SAR)'
const YEAR_COLUMN_TITLE_REGEX = /(.*)\s(\d{4})\s?(\(.*\))?/; //e.g 'SOCIALE TAHLIA 2023 (SAR)'

export {
  AreRowsLimited,
  ContainsExternalUrls,
  ConvertToEntityObjects,
  ExtractNodeText,
  GenerateColumns,
  GenerateReportRows,
  GenerateRowStyles,
  GetCurrencyListSelection,
  GetReportTypeSelection,
  GetRowLevel,
  IsNullOrUndefined,
  RemoveEmptyChildrenProperty,
  URLisBase
};

/*
@since: 2023-3-23
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { ReloadOutlined } from "@ant-design/icons";
import { Button, message, Row } from "antd";
import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { IsNotNullOrUndefined } from "Utils/helpers";
import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { RunDownloader } from "../../../../dataHandling/PriceUpdaterAPI";
import { STATUS } from "../../Utils/Constants";
import { ToReadableTime, UtcToLocal } from "./NavBar/PriceUpdaterSettingsUtil";

export default function DownloaderButton({ settingsProps, downloaderState, updaterState, popConfirmState }) {
  const { priceUpdaterConfig } = usePriceConfigContext();

  // Props
  const editMode = settingsProps.editMode;
  const [updaterStatus, setUpdaterStatus] = updaterState;
  const [downloaderStatus, setDownloaderStatus] = downloaderState;
  const [popConfirmOpen, setPopConfirmOpen] = popConfirmState;

  // Local State
  const [downloaderWasRunning, setDownloaderWasRunning] = useState(false);

  function StartDownloader() {
    if (downloaderStatus != STATUS.RUNNING) {
      setDownloaderStatus(STATUS.RUNNING);
      // close pop up if ran downloader
      setPopConfirmOpen(false);
      RunDownloader()
        .then((response: AxiosResponse) => {
          if (response.status == 200) {
            message.info(response.data.message);
          } else {
            message.warning(response.data.message);
          }
        })
        .catch((err) => {
          if (IsNotNullOrUndefined(err?.response?.data?.message)) message.error(err.response.data.message);
          else message.error("An error occurred while starting downloader.");
        });
    }
  }

  // Refresh page every time downloader is complete, this will only show if the user opened the settings before. TODO: watch the statuses globally + replace long polling with SSE or WS
  useEffect(() => {
    // If downloader started running during user session, they should know when it finishes
    if (!downloaderWasRunning && downloaderStatus == STATUS.RUNNING) {
      setDownloaderWasRunning(true);
    } else if (downloaderStatus == STATUS.DONE && downloaderWasRunning) {
      message.success("Downloading foodics data complete! Refresh the page to see the latest data.", 5);
      setDownloaderWasRunning(false);
    }
  }, [downloaderStatus]);

  return (
    <>
      <Row justify="center">
        <Button
          style={{ margin: "20px" }}
          disabled={downloaderStatus == STATUS.RUNNING || updaterStatus == STATUS.RUNNING || editMode}
          danger={downloaderStatus == STATUS.RUNNING || updaterStatus == STATUS.RUNNING}
          ghost={downloaderStatus == STATUS.RUNNING || updaterStatus == STATUS.RUNNING}
          loading={downloaderStatus == STATUS.RUNNING}
          icon={<ReloadOutlined />}
          shape="round"
          size="large"
          onClick={StartDownloader}
        >
          Refresh LBP Foodics Prices
        </Button>
      </Row>
      <Row justify="center" style={{ color: "grey", textAlign: "center", cursor: "default" }}>
        Last downloaded at:
        <br />
        {ToReadableTime(UtcToLocal(priceUpdaterConfig.lastDownloadDateUtc)) ?? "Never"}
      </Row>
    </>
  );
}

/*
@since: 2023-3-17
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Input, Row, message } from "antd";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UpdateUsdPrices } from "../../../../dataHandling/PriceUpdaterAPI";
import { NEW_PRICE_COL_TITLE } from "../../Utils/Constants";
import { AddExtraPriceFields, EditableComboCellRenderer, GenerateCurrPriceColumns, GetNewLbpPriceColumn } from "../Utils/PriceTableUtil";
import CustomEditSwitch from "./CustomEditSwitch";
import CustomPriceTable from "./CustomPriceTable";

export default function ComboPriceTable() {
  // Context
  const { priceUpdaterConfig, mainBranch } = usePriceConfigContext();
  const { comboItemOptionSizes, dataLoading, setComboItemOptionSizes, setDataLoading, editMode, setEditMode, customNewRate } =
    useOutletContext();

  // State
  const [filteredCombos, setFilteredCombos] = useState(comboItemOptionSizes);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    onChange: (page, pageSize) => {
      setPaginationSettings({ ...paginationSettings, current: page });
    },
  });

  // Reverts unsaved changes
  useEffect(() => {
    // on table unmount
    return () => {
      // reset data changes
      const comboItemOptionSizesUnchanged = [...comboItemOptionSizes];
      AddExtraPriceFields(comboItemOptionSizesUnchanged, customNewRate);
      setComboItemOptionSizes(comboItemOptionSizesUnchanged);
    };
  }, []);

  if (dataLoading) {
    return <></>;
  }

  function onEditModeChange(checked, event) {
    // toggle off = send update request
    if (!checked) {
      let changedComboItemOptionSizes = comboItemOptionSizes.filter(
        (comboItemOptionSize) => comboItemOptionSize.usd_price != comboItemOptionSize.new_usd_price
      );

      // changes were made
      if (changedComboItemOptionSizes.length > 0) {
        changedComboItemOptionSizes = changedComboItemOptionSizes.map((comboItemOptionSize) => {
          comboItemOptionSize.usd_price = comboItemOptionSize.new_usd_price;
          return comboItemOptionSize;
        });
        const requestBody = { comboItemOptionSizes: changedComboItemOptionSizes };

        setDataLoading(true);
        UpdateUsdPrices(mainBranch, requestBody)
          .then((response) => {
            const items = response.data.data;
            const newComboItemOptionSizes = AddExtraPriceFields(items.comboItemOptionSizes, customNewRate);
            setComboItemOptionSizes(newComboItemOptionSizes);
            setDataLoading(false);
            console.log(response);
          })
          .catch((err) => {
            message.error("An error occurred while updating combo prices, check logs for more details.");
            console.error(err);
          });
      }
    }
  }

  function SearchableColumn(columnName, updateData, searchField) {
    const [inputValue, setInputValue] = useState("");
    const fuse = new Fuse(comboItemOptionSizes, { keys: [searchField], findAllMatches: true });
    function searchChangeHandler(event) {
      const currInputValue = event.target.value;
      setInputValue(currInputValue);
      let filteredData;
      // show all records on empty string
      if (currInputValue == "") {
        filteredData = comboItemOptionSizes;
      } else {
        const filteredSearchResults = fuse.search(currInputValue);
        filteredData = filteredSearchResults.map((element) => element.item);
      }
      updateData(filteredData);
      // go to first page when searching
      setPaginationSettings({ ...paginationSettings, current: 1 });
    }
    return (
      <>
        {columnName}
        <Input placeholder={`Search ${columnName}`} value={inputValue} onChange={searchChangeHandler} />
      </>
    );
  }
  const defaultDataColWidth = "10%";
  const comboColumns = [
    {
      title: SearchableColumn("Combo Name", setFilteredCombos, "combo_size.combo.name"),
      dataIndex: ["combo_size", "combo", "name"],
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("Combo SKU", setFilteredCombos, "combo_size.combo.foodics_sku"),
      dataIndex: ["combo_size", "combo", "foodics_sku"],
      render: (sku) => <span>{sku}</span>,
    },
    {
      title: SearchableColumn("Combo Size Name", setFilteredCombos, "combo_size.name"),
      dataIndex: ["combo_size", "name"],
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("Item Name", setFilteredCombos, "option.item.name"),
      dataIndex: ["option", "item", "name"],
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("Option Name", setFilteredCombos, "option.name"),
      dataIndex: ["option", "name"],
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("Product Name", setFilteredCombos, "product.name"),
      dataIndex: ["product", "name"],
      render: (name) => <span>{name}</span>,
    },
    {
      title: SearchableColumn("Product SKU", setFilteredCombos, "product.foodics_sku"),
      dataIndex: ["product", "foodics_sku"],
      render: (sku) => <span>{sku}</span>,
    },
    ...GenerateCurrPriceColumns(priceUpdaterConfig.lastLbpRateUsed),
    {
      title: NEW_PRICE_COL_TITLE,
      dataIndex: "new_usd_price",
      sorter: (a, b) => a.new_usd_price - b.new_usd_price,
      render: EditableComboCellRenderer(setComboItemOptionSizes, comboItemOptionSizes, editMode, customNewRate),
    },
    GetNewLbpPriceColumn(),
  ];

  return (
    <>
      <Row justify="end">
        <CustomEditSwitch
          loading={dataLoading}
          disabled={dataLoading}
          checked={editMode}
          onClick={setEditMode}
          onChange={onEditModeChange}
        />
      </Row>
      <CustomPriceTable
        columns={comboColumns}
        dataSource={filteredCombos}
        rowKey={(row) =>
          `option=${row.option.foodics_id}, item=${row.option.item.foodics_id}, size=${row.combo_size.foodics_id}, combo=${row.combo_size.combo.foodics_id}, product=${row.product.foodics_id}`
        }
        loading={dataLoading}
        pagination={paginationSettings}
      />
    </>
  );
}

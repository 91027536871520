/**
 * @since: 2023-12-5
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import { BASE_URL } from "Utils/urls";
import axios, { AxiosInstance } from "axios";

const defaultAxiosConfig = {
  baseURL: BASE_URL,
};

export const axiosInstance: AxiosInstance = axios.create(defaultAxiosConfig);

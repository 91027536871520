/*
@since: 2023-4-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Col, InputNumber, Row } from "antd";
import { CURRENCY, STATUS } from "../../../Utils/Constants";
import { ToReadableCurrency } from "../../Utils/PriceTableUtil";
import RateConstantSetting from "../RateConstantSetting";
import NavBarCard from "./NavBarCard";
import { GetRecommendedRate } from "./PriceUpdaterSettingsUtil";

export default function NavBarRates({ settingsProps, updaterStatus, downloaderStatus }) {
  // Global state
  const { priceUpdaterConfig } = usePriceConfigContext();
  const { editMode, customNewRate, setCustomNewRate } = settingsProps;
  return (
    <Row justify="start" style={{ margin: "0 auto", cursor: "default" }}>
      <Col xxl={6} xl={12} lg={6} md={6} sm={12} xs={12}>
        <NavBarCard cardProps={{ title: "Current Rate" }}>
          <p>{ToReadableCurrency(priceUpdaterConfig.lastLbpRateUsed, CURRENCY.LBP) || "No updates found."}</p>
        </NavBarCard>
      </Col>
      <Col xxl={6} xl={12} lg={6} md={6} sm={12} xs={12}>
        <NavBarCard cardProps={{ title: "Recommended Rate" }}>
          <p>{ToReadableCurrency(GetRecommendedRate(priceUpdaterConfig), CURRENCY.LBP)}</p>
        </NavBarCard>
      </Col>
      <Col xxl={6} xl={12} lg={6} md={6} sm={12} xs={12}>
        <NavBarCard cardProps={{ title: "New Rate" }}>
          <InputNumber
            type="number"
            disabled={updaterStatus == STATUS.RUNNING || downloaderStatus == STATUS.RUNNING || editMode}
            placeholder={ToReadableCurrency(100000, CURRENCY.LBP)}
            value={customNewRate}
            controls={false}
            onChange={(newCustomRate) => setCustomNewRate(newCustomRate)}
            style={{ marginTop: "10px" }}
            min={0}
          />
        </NavBarCard>
      </Col>
      <Col xxl={6} xl={12} lg={6} md={6} sm={12} xs={12}>
        <RateConstantSetting {...settingsProps} updaterStatus={updaterStatus} />
      </Col>
    </Row>
  );
}

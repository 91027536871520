import { Alert, Button, Space } from "antd";
import { updateQueryCount } from "../../utils/ComponentUtils/ReportSelect/QueryUtils";

export default function RetryPrompt({ setPrevQueryCount, setQueryCount, queryCount, CancelPendingRequest, ClearReportLoadingTimers }) {
  const RetryReportQuery = () => {
    ClearReportLoadingTimers(true);
    // Trigger cancelling previous request
    CancelPendingRequest();
    // Request report again
    updateQueryCount(setPrevQueryCount, setQueryCount, queryCount);
  };
  const DismissRetryPrompt = () => {
    ClearReportLoadingTimers(true);
  };

  return (
    <Alert
      message="Loading took too long"
      description="It seems we're having trouble getting the report, would you like to try again?"
      type="warning"
      action={
        <Space direction="vertical">
          <Button size="small" type="primary" onClick={RetryReportQuery}>
            Retry
          </Button>
          <Button size="small" danger type="ghost" onClick={DismissRetryPrompt}>
            No, thanks.
          </Button>
        </Space>
      }
      closable
    />
  );
}

import moment from "moment";
import { ReportVersionType } from "../../utils/types";
import "./styles.css";

interface ReportVersionProps {
  reportType: string;
  version: ReportVersionType;
}

export default function ReportVersion(props: ReportVersionProps) {
  return (
    <div>
      <h3>{"Version Details:"}</h3>
      <ul>
        <ReportTypeExplanation reportType={props.reportType} />
        <li>
          <span className="preWrap">
            <strong>{"Quickbooks data downloaded on "}</strong>
            {FormatDate(props.version.quickbooks)}
          </span>
        </li>
        <li>
          <span className="preWrap">
            <strong>{"Excel additional data downloaded on "}</strong>
            {FormatDate(props.version.excel)}
          </span>
        </li>
      </ul>
    </div>
  );
}

function FormatDate(downloadDate: string) {
  if (downloadDate) {
    return moment.utc(downloadDate).local().format("ll LTS");
  }
  return "-";
}

function ReportTypeExplanation(props: { reportType: string }) {
  const reportTypeExplanations: { [key: string]: string } = {
    Summary: "customized profit and loss report which includes manual additions (mostly extra salaries and other adjustments).",
    ProfitAndLoss: "standard profit and loss report downloaded from QuickBooks without any modifications.",
    BalanceSheet: "standard balance sheet report downloaded from QuickBooks without any modifications.",
    CashFlow: "standard cash flow report downloaded from QuickBooks without any modifications.",
  };
  if (reportTypeExplanations[props.reportType] !== undefined) {
    return (
      <li style={{ whiteSpace: "pre-wrap" }}>
        <span className="preWrap">
          <strong>Report:</strong> {reportTypeExplanations[props.reportType]}
        </span>
      </li>
    );
  } else {
    return <></>;
  }
}

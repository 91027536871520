// TODO: Move to algosettings
const REPORT_BORDERS = {
  ProfitAndLoss: ["GROSS PROFIT", "NET OPERATING INCOME", "NET OPERATING INCOME", "OTHER EXPENSES", "NET OTHER INCOME", "NET INCOME"],
  BalanceSheet: [
    "CASH AT BANK AND IN HAND",
    "NET CURRENT ASSETS",
    "NET CURRENT ASSETS (LIABILITIES)",
    "TOTAL ASSETS LESS CURRENT LIABILITIES",
    "TOTAL NET ASSETS (LIABILITIES)",
    "CAPITAL AND RESERVES",
  ],
  CashFlow: ["NET CASH INCREASE FOR PERIOD"],
  Summary: ["NET INCOME"],
  SummaryV2: ["NET INCOME"],
  KeyPerformanceIndicator: [],
  Shareholder: [],
};

// TODO: Move to algosettings
const NON_CURRENCY_REPORTS = ["KeyPerformanceIndicator", "Shareholder"];
const COLUMN_PERCENTAGE_INDICATOR = "               %";
const MONTHS_ABBREV = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

const RETRY_PROMPT_DELAY_MILLISECONDS = 10000;
const TABLE_COLUMN_STYLE = "Roboto Mono";
// TODO: Move to algosettings
const USER_GUIDE_URL = "https://docs.google.com/presentation/d/1vX-yCLgefV-FhrNiFe7DH2wUFURiXmCuBKPJGQc_mDA/edit#slide=id.p";
export {
  COLUMN_PERCENTAGE_INDICATOR,
  MONTHS_ABBREV,
  NON_CURRENCY_REPORTS,
  REPORT_BORDERS,
  RETRY_PROMPT_DELAY_MILLISECONDS,
  TABLE_COLUMN_STYLE,
  USER_GUIDE_URL,
};

import { RecipeSearchRecord } from "Utils/types";
import { List, ListProps } from "antd";

interface RecipeListProps {
  loading: boolean;
  recipes: RecipeSearchRecord[];
  recipeId: string | null;
  setRecipeId: React.Dispatch<React.SetStateAction<string | null>>;
  listProps?: ListProps;
}

export default function RecipeList(props: RecipeListProps) {
  const recipes = props.recipes;
  const loading = props.loading;
  const selectedRecipeId = props.recipeId;
  const setRecipeId = props.setRecipeId;
  const listProps = props.listProps || {};

  const handleListItemClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const { value } = event.target;
    setRecipeId(value);
  };

  // Class Handler
  const handleListItemClass = (id: number) => {
    var className = "menuItem";
    if (id === selectedRecipeId) {
      className = className + " selectedRecipe";
    }
    return className;
  };

  return (
    <List
      loading={loading}
      style={{ overflowY: "auto", width: "100%", maxHeight: "65vh" }}
      itemLayout="horizontal"
      dataSource={recipes}
      size="small"
      renderItem={(recipe: RecipeSearchRecord) => (
        <List.Item
          className={handleListItemClass(recipe.id)}
          onClick={handleListItemClick}
          key={recipe.id}
          value={recipe.id}
          style={{ paddingLeft: "5px", cursor: "default" }}
        >
          {recipe.name}
        </List.Item>
      )}
      {...listProps}
    />
  );
}

import App from "components/App/App.tsx";
import { ErrorPage } from "components/Shared/ErrorPage/ErrorPage.tsx";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import "./index.css";
const router = createBrowserRouter([
  {
    path: "/*",
    element: <App />,
    // element: <React.StrictMode><App /></React.StrictMode> ,
    errorElement: <ErrorPage />,
  },
]);
ReactDOM.createRoot(document.getElementById("root")!).render(<RouterProvider router={router} />);

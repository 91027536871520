/*
@since: 2023-3-23
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { ReloadOutlined } from "@ant-design/icons";
import { Button, message, Popconfirm, Row } from "antd";
import { useEffect, useState } from "react";
import { RunUpdater } from "../../../../dataHandling/PriceUpdaterAPI";
import { STATUS } from "../../Utils/Constants";
import { IsNumeric } from "../Utils/PriceTableUtil";

export default function UpdaterSetting({ settingsProps, updaterState, downloaderState, popConfirmState, customNewRate }) {
  const [updaterStatus, setUpdaterStatus] = updaterState;
  const [downloaderStatus, setDownloaderStatus] = downloaderState;
  const [popConfirmOpen, setPopConfirmOpen] = popConfirmState;
  const editMode = settingsProps.editMode;
  const [updaterWasRunning, setUpdaterWasRunning] = useState(false);

  function StartUpdater() {
    if (updaterStatus == STATUS.RUNNING) {
      message.error("Can't update prices when updater is already running.");
      setPopConfirmOpen(false);
      return;
    }
    if (downloaderStatus == STATUS.RUNNING) {
      message.error("Can't update prices when downloader is already running.");
      setPopConfirmOpen(false);
      return;
    }
    setPopConfirmOpen(false);
    setUpdaterStatus(STATUS.RUNNING);
    RunUpdater(customNewRate)
      .then((response: AxiosResponse) => {
        if (response.status == 200) {
          message.info(response.data.message);
        } else {
          message.warning(response.data.message);
        }
      })
      .catch((err) => {
        message.error(err.response.data.message);
      });
  }

  function OpenPopConfirm() {
    // if nothing is running
    if (updaterStatus == STATUS.RUNNING) {
      message.error("Can't update prices when updater is already running.");
      setPopConfirmOpen(false);
      return;
    }
    if (downloaderStatus == STATUS.RUNNING) {
      message.error("Can't update prices when downloader is already running.");
      setPopConfirmOpen(false);
      return;
    }
    setPopConfirmOpen(true);
    return;
  }

  // Show notification that updater is complete
  useEffect(() => {
    // If downloader started running during user session, they should know when it finishes
    if (!updaterWasRunning && updaterStatus == STATUS.RUNNING) {
      setUpdaterWasRunning(true);
    } else if (updaterStatus == STATUS.DONE && updaterWasRunning) {
      message.success("Updating foodics prices complete! Refresh page to update most recent rate used.", 5);
      setUpdaterWasRunning(false);
    }
  }, [updaterStatus]);

  // Close pop up it edit mode was toggled
  useEffect(() => {
    setPopConfirmOpen(false);
  }, [editMode]);

  return (
    <Row justify="center">
      <Popconfirm
        title="Are you sure you want to update prices?"
        open={popConfirmOpen}
        onConfirm={StartUpdater}
        okText="Confirm Update Prices"
        // okType="danger"
        okButtonProps={{
          loading: updaterStatus == STATUS.RUNNING,
          disabled: updaterStatus == STATUS.RUNNING || downloaderStatus == STATUS.RUNNING || !IsNumeric(customNewRate) || editMode,
        }}
        onCancel={() => setPopConfirmOpen(false)}
      >
        <Button
          style={{ margin: "20px" }}
          disabled={updaterStatus == STATUS.RUNNING || downloaderStatus == STATUS.RUNNING || editMode}
          danger
          ghost={updaterStatus == STATUS.RUNNING || downloaderStatus == STATUS.RUNNING}
          loading={updaterStatus == STATUS.RUNNING}
          icon={<ReloadOutlined />}
          shape="round"
          size="large"
          onClick={OpenPopConfirm}
        >
          Update LBP Foodics Prices
        </Button>
      </Popconfirm>
    </Row>
  );
}

/*
@since: 2023-2-9
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

/**
 *
 * @param {Array<Object>} report columns
 */
export function CleanColumnsData(reportColumns) {
  const columns = [...reportColumns];
  for (let i = 0; i < columns.length; i++) {
    columns[i].title = columns[i].title.replaceAll("\n", " ");
  }
  return columns;
}

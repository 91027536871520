/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { ChartDataLineType } from "Utils/types";
import type { ChartOptions } from "chart.js";
import Chart from "chart.js/auto";
import { CSSProperties } from "react";
import { Line } from "react-chartjs-2";
import { averageLineAnnotation } from "../Utils/ChartData";
import { colors } from "../Utils/constants";
interface SalesChartProps {
  chartData: ChartDataLineType;
}
export function SalesChart(props: SalesChartProps) {
  const chartData = props.chartData;
  // Chart.defaults.elements.point.radius = 0
  Chart.defaults.elements.line.borderWidth = 1;
  Chart.defaults.plugins.legend.display = false;

  const chartOptions: ChartOptions<"line"> = {
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1.5,
    plugins: {
      annotation: {
        annotations: {
          averageLineAnnotation,
        },
      },
    },
  };

  const chartDataOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  /**
   * @type {import("react").CSSProperties}
   */
  const chartStyle: CSSProperties = {
    backgroundColor: colors.WHITE,
    width: "100%",
  };

  const chartContainerStyle: CSSProperties = { overflowY: "overlay" };

  if (Object.keys(chartData).length !== 0) {
    return (
      <div style={chartContainerStyle}>
        <Line
          data={{
            labels: chartData.labels, // x-axis
            datasets: chartData.datasets, // y-axis
            options: chartDataOptions,
          }}
          options={chartOptions}
          style={chartStyle}
          className="fillContainer"
        />
      </div>
    );
  } else {
    <Line
      data={{
        labels: [],
        datasets: [],
        options: {
          scales: {
            y: {
              beginAtZero: true,
            },
          },
        },
      }}
    />;
  }
}

/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { ToReadableDate } from "Utils/HumanReadable";
import { IsNotNullOrUndefined, ListToObjectByProperty } from "Utils/helpers";
import { useDataEntryContext } from "Utils/hooks/useDataEntryContext";
import { AdditionalEntities, AdditionalRecord, DateSelectionHandle } from "Utils/types";
import { Col, Modal, Row, message } from "antd";
import { Loading } from "components/AlgoBooks/components/Loading";
import {
  FetchAdditionalEntities,
  FetchAdditionalRecords,
  FetchAvailableDates,
  FetchLatestAdditionalRecords,
  UpdateAdditionalData,
} from "dataHandling/data-entry";
import { useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EditSwitch } from "../EditSwitch/EditSwitch";
import { AdditionalRecordsTable } from "./AdditionalRecordsTable/AdditionalRecordsTable";
import { ExportButton } from "./ExportButton/ExportButton";
import { MonthSelection } from "./MonthSelection/MonthSelection";

export function AdditionalData() {
  const { company, companiesByRealmId, setDisabledEditSwitch, editMode, setEditMode, setCurrentDataType } = useDataEntryContext();
  const dateSelectionRef = useRef<DateSelectionHandle>(null);
  const [availableDates, setAvailableDates] = useState<string[]>([]);
  const [copyModalVisible, setCopyModalVisible] = useState<boolean>(false);
  const [currentTableDate, setCurrentTableDate] = useState<string | null>(null); // either selected date or new date
  const [newDateMode, setNewDateMode] = useState<boolean>(false);
  const [entitiesLoading, setEntitiesLoading] = useState<boolean>(true);
  const [initialDateSelectionValue, setInitialDateSelectionValue] = useState<string>(null);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [additionalRecords, setAdditionalRecords] = useState<AdditionalRecord[]>([]);
  const [additionalEntities, setAdditionalEntities] = useState<AdditionalEntities | null>(null);
  const [latestAdditionalRecords, setLatestAdditionalRecords] = useState<AdditionalRecord[]>([]);

  const { classesById, departmentsById } = useMemo(
    () => ({
      classesById: ListToObjectByProperty(additionalEntities?.classes, "quickbooksId"),
      departmentsById: ListToObjectByProperty(additionalEntities?.departments, "quickbooksId"),
    }),
    [additionalEntities]
  );

  useEffect(() => {
    setCurrentDataType("Additional");
    ResetToInitialState();
    setEntitiesLoading(true);
    Promise.all([FetchAvailableDates(company.quickbooksId, "Additional"), FetchAdditionalEntities(company.quickbooksId)])
      .then(([availableDateResponse, additionalEntitiesResponse]) => {
        // Initialize state
        setAvailableDates(availableDateResponse.availableDates);
        setAdditionalEntities({
          classes: additionalEntitiesResponse.classes,
          departments: additionalEntitiesResponse.departments,
          reportNames: additionalEntitiesResponse.reportNames,
          descriptionOptions: additionalEntitiesResponse.descriptionOptions,
          userDisplayName: additionalEntitiesResponse.userDisplayName,
        });

        // Load first date data
        if (availableDateResponse.availableDates.length > 0) {
          LoadTableData(availableDateResponse.availableDates[0], false);
          setInitialDateSelectionValue(availableDateResponse.availableDates[0]);
        }
      })
      .catch((err) => {
        message.error("Error occurred while fetching available entities.");
        console.error(err);
      })
      .finally(() => {
        setEntitiesLoading(false);
      });
  }, []);

  useEffect(() => {
    setDisabledEditSwitch(currentTableDate === null); // no selections -> disabled edit switch
  }, [currentTableDate]);

  const LoadTableData = (date: string, createNewDate: boolean) => {
    setCurrentTableDate(date);
    setNewDateMode(createNewDate);
    if (!createNewDate) {
      // selected existing date
      setTableLoading(true);
      FetchAdditionalRecords(company.quickbooksId, date)
        .then((response) => {
          setAdditionalRecords(response.additionalRecords);
        })
        .catch((error) => {
          message.error("Error occurred while fetching additional records.");
          console.error(error);
        })
        .finally(() => {
          setTableLoading(false);
        });
    } else if (IsNotNullOrUndefined(date)) {
      // selected new date from datepicker
      setEditMode(true);
      setAdditionalRecords([]);
      setTableLoading(true);

      FetchLatestAdditionalRecords(company.quickbooksId)
        .then((response) => {
          if (response.latestAdditionalRecords.length > 0) {
            setCopyModalVisible(true);
            setLatestAdditionalRecords(response.latestAdditionalRecords);
          }
        })
        .catch((error) => {
          console.error(error);
          message.error("An error occurred while created new date.");
        })
        .finally(() => {
          setTableLoading(false);
        });
    }
  };

  const ResetToInitialState = () => {
    dateSelectionRef.current?.ClearDatePicker();
    setCurrentTableDate(null);
    setNewDateMode(false);
    setAdditionalRecords([]);
    setEditMode(false);
  };

  const onSaveChanges = () => {
    setTableLoading(true);
    UpdateAdditionalData(company.quickbooksId, currentTableDate!, additionalRecords)
      .then((response) => {
        if (response.success) message.success(response.message);
        setAdditionalRecords(response.additionalRecords);
        setEditMode(false);
        if (newDateMode) {
          // Saved changes are new date rows
          dateSelectionRef.current?.ClearDatePicker();
          dateSelectionRef.current?.SelectNewDateOption(currentTableDate!);
          setNewDateMode(false);
          // Select the created date in the date selection options
          availableDates.push(currentTableDate!);
          availableDates.sort((date1, date2) => date2.localeCompare(date1));
          setAvailableDates(availableDates);
        } else {
          if (response.additionalRecords.length === 0) {
            // if deleted all records for an existing date, date must not be an option anymore
            ResetToInitialState();
            setAvailableDates(availableDates.filter((date) => date !== currentTableDate));
          }
        }
      })
      .catch((error) => {
        console.error(error);
        message.error("An error occurred while updating the data.");
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const onCopyModalOk = () => {
    // Here latestAdditionalRecords must be a non-empty array, or else the modal wouldn't have been displayed
    const newAdditionalRecords = latestAdditionalRecords.map((additionalRecord) => {
      additionalRecord.record_id = uuidv4();
      additionalRecord.date = currentTableDate!;
      return additionalRecord;
    });
    setAdditionalRecords(newAdditionalRecords);
    setCopyModalVisible(false);
  };

  const onCopyModalCancel = () => {
    setCopyModalVisible(false);
  };

  return (
    <>
      <Modal
        cancelText="No"
        okText="Yes"
        centered
        mask
        maskClosable={false}
        closeIcon={false}
        title={
          latestAdditionalRecords.length > 0 &&
          `Would you like to copy the latest available month's records? (${ToReadableDate(latestAdditionalRecords[0].date)})`
        }
        open={copyModalVisible}
        onOk={onCopyModalOk}
        onCancel={onCopyModalCancel}
        destroyOnClose
      />

      <Row justify="center" style={{ marginTop: "20px" }}>
        {entitiesLoading ? (
          <Loading tip="Loading entities..." />
        ) : (
          <AdditionalRecordsTable
            additionalRecords={additionalRecords}
            setAdditionalRecords={setAdditionalRecords}
            additionalEntities={additionalEntities!}
            loading={tableLoading}
            reloadTableData={() => LoadTableData(currentTableDate!, newDateMode)}
            companiesByRealmId={companiesByRealmId}
            classesById={classesById}
            departmentsById={departmentsById}
            currentTableDate={currentTableDate}
            company={company}
            newDateMode={newDateMode}
            resetToInitialState={ResetToInitialState}
            onSaveChanges={onSaveChanges}
            tableToolbarChildren={
              <Row justify="space-around">
                <Col xl={18} lg={18} md={19} sm={24} xs={24} style={{ display: "flex", justifyContent: "start", gap: "20px" }}>
                  <Row justify="start">
                    <Col xl={17} lg={17} md={17} sm={12} xs={24} style={{ marginTop: "5px", marginBottom: "5px", marginRight: "5px" }}>
                      <MonthSelection
                        availableDates={availableDates}
                        onExistingDateSelection={LoadTableData}
                        loading={tableLoading}
                        disabledAll={editMode}
                        initialDateSelectionValue={initialDateSelectionValue}
                        ref={dateSelectionRef}
                      />
                    </Col>
                    <Col xl={6} lg={6} md={5} sm={12} xs={24} style={{ marginTop: "5px", marginBottom: "5px" }}>
                      <ExportButton
                        company={company}
                        date={currentTableDate!}
                        additionalRecords={additionalRecords}
                        disabled={tableLoading || additionalRecords.length === 0}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col
                  xl={6}
                  lg={6}
                  md={5}
                  sm={24}
                  xs={24}
                  style={{ display: "flex", justifyContent: "end", marginTop: "5px", marginBottom: "5px" }}
                >
                  <EditSwitch />
                </Col>
              </Row>
            }
          />
        )}
      </Row>
    </>
  );
}

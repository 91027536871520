import { Col, Row, Spin, SpinProps } from "antd";
import { CSSProperties } from "react";

interface LoadingProps {
  tip: string;
  spinnerProps?: SpinProps;
  textStyles?: CSSProperties;
}

export function Loading({ textStyles = {}, spinnerProps = {}, ...props }: LoadingProps) {
  return (
    <Row justify="center" style={{ height: "5em" }}>
      <Col style={{ width: "25%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <Spin size="large" style={{ paddingTop: "5%", display: "block" }} {...props.spinnerProps} />
        <p style={{ width: "max-content", color: "#1890ff", zIndex: "1000", ...textStyles }}>{props.tip}</p>
      </Col>
    </Row>
  );
}

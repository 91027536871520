import { Avatar, Col, Row } from "antd";
import { CSSProperties } from "react";
import { TopBarProps } from "../../utils/types";
import "./TopBar.css";

export default function TopBar({ rightSidePanelChildren = <></>, rideSideColumnProps = {}, ...props }: TopBarProps) {
  const navBarStyle: CSSProperties = {
    backgroundColor: "#4b66af",
    height: "auto",
    // paddingLeft: "15px",
    paddingRight: "20px",
  };
  const titleLogoStyle: CSSProperties = {
    borderRadius: "8px",
    transition: "background-color 0.4s linear",
    cursor: "default",
  };
  const titleStyle: CSSProperties = {
    color: "white",
    fontSize: "20px",
    marginLeft: "15px",
  };
  const avatarStyle = {};
  return (
    <div>
      <Row justify="space-between" align="middle" style={navBarStyle}>
        <Col span={12}>
          <Row style={titleLogoStyle} align="middle">
            <Col>
              <Avatar
                size={"logoSize" in props ? props.logoSize : 50}
                src={props.logoUrl}
                style={{ ...avatarStyle, padding: "3px" }}
              ></Avatar>
            </Col>
            <Col lg={12} md={12} sm={12} xs={0}>
              <strong>
                <span style={titleStyle}>{props.siteName}</span>
              </strong>
            </Col>
          </Row>
        </Col>

        <Col className="custom-dropdown" {...rideSideColumnProps}>
          {rightSidePanelChildren}
        </Col>
      </Row>
    </div>
  );
}

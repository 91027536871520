/**
 * @since: 2023-11-30
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { GetActiveApplicationFromUrl, IsAppRoute } from "Utils/RouteUtils";
import { APP_AXIOS_INSTANCES, AuthContext, CompanyContext, ROUTE_TO_APP_NAME, defaultAuthContextObject } from "Utils/constants";
import {
  AddAuthAxiosRequestInterceptor,
  AddAuthAxiosResponseInterceptor,
  GetValidCompanyForApp,
  HandleResponseErrors,
} from "Utils/helpers";
import { AuthContextType, CompanyAppMapping, CompanyBranchMappingType, DashboardCompany } from "Utils/types";
import { message } from "antd";
import { SignInWithPopUp, SyncFireBaseUserData, fireBaseAuth } from "auth";
import { isAxiosError } from "axios";
import AlgoBooksApp from "components/AlgoBooks/AlgoBooksApp";
import { Loading } from "components/AlgoBooks/components/Loading";
import CantinaSalesApp from "components/CantinaSales/CantinaSalesApp";
import { Dashboard } from "components/Dashboard/Dashboard";
import { DataEntryApp } from "components/DataEntry/DataEntryApp";
import { PriceUpdaterApp } from "components/FoodicsTool/Components/PriceUpdater/PriceUpdaterApp";
import FoodicsToolApp from "components/FoodicsTool/Views/FoodicsToolApp";
import { LoginPage } from "components/LoginPage/LoginPage";
import { NoAppPage } from "components/NoAppPage/NoAppPage";
import { NoConnectionPage } from "components/NoConnectionPage/NoConnectionPage";
import { NoResponsePage } from "components/NoResponsePage/NoResponsePage";
import { PermissionDeniedPage } from "components/PermissionDeniedPage/PermissionDeniedPage";
import RecipeBookApp from "components/RecipeBook/RecipeBookApp";
import { ErrorPage } from "components/Shared/ErrorPage/ErrorPage";
import { UserNotRegisteredPage } from "components/UserNotRegisteredPage/UserNotRegisteredPage";
import { axiosInstance } from "dataHandling/axios";
import { FetchAppPermissions } from "dataHandling/dashboard-core";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";

export default function App() {
  const [authContext, setAuthContext] = useState<AuthContextType>(defaultAuthContextObject);
  const [appPermissions, setAppPermissions] = useState<string[]>([]);
  const [companyPermissions, setCompanyPermissions] = useState<DashboardCompany[]>([]);
  const [companyAppMapping, setCompanyAppMapping] = useState<CompanyAppMapping>({});
  const [selectedCompanyName, changeSelectedCompanyState] = useState<string | null>(null);
  const [companyBranchMapping, setCompanyBranchMapping] = useState<CompanyBranchMappingType>({});
  const [selectionDisabled, setSelectionDisabled] = useState<boolean>(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    fireBaseAuth.onAuthStateChanged((user) => {
      if (user !== null && !user.isAnonymous) {
        APP_AXIOS_INSTANCES.map((axiosInstance) => {
          AddAuthAxiosRequestInterceptor(axiosInstance, user);
          AddAuthAxiosResponseInterceptor(axiosInstance, navigate);
        });
        AddAuthAxiosRequestInterceptor(axiosInstance, user); // since response interception is handled in the same useEffect

        (async () => {
          setAuthContext({ ...authContext, isLoading: true });
          const response = await FetchAppPermissions();
          if (response.body.success) {
            if (response.body.appPermissions.length === 0) {
              navigate("/no-permission");
            } else {
              setAppPermissions(response.body.appPermissions);
              setCompanyPermissions(response.body.companyPermissions);
              setCompanyAppMapping(response.body.companyAppMapping);
              setCompanyBranchMapping(response.body.companyBranchMapping);

              // Base url, select default company
              if (location.pathname === "/") setSelectedCompanyName(response.body.companyPermissions[0].name);
              else {
                // App url, select valid company
                const currentActiveApplicationRouteName = GetActiveApplicationFromUrl(location);
                if (IsAppRoute(currentActiveApplicationRouteName)) {
                  const appName = ROUTE_TO_APP_NAME[currentActiveApplicationRouteName];
                  const validInitialCompany = GetValidCompanyForApp(response.body.companyAppMapping, appName);
                  if (validInitialCompany === null) navigate("/no-permission"); // no permitted company for application found
                  else setSelectedCompanyName(validInitialCompany);
                }
              }
            }
          } else {
            try {
              setAppPermissions([]);
              if (isAxiosError(HandleResponseErrors(response.body.meta, navigate))) {
                message.error(response.body.error);
              }
            } catch (error) {
              message.error("An error occurred while signing in.");
            }
          }
          setAuthContext({ ...authContext, isAuthenticated: true, user: user, isLoading: false });
        })();
      } else {
        console.log("User is anonymous or null");
        console.log(user);
        setAuthContext({ ...authContext, isLoading: false, isAuthenticated: false });
      }
    });
  }, []);

  function SignIn() {
    setAuthContext({ ...authContext, isLoading: true });
    // SignInWithRedirect(() => setAuthContext({ ...authContext, isLoading: false }));
    SignInWithPopUp(
      () => {},
      () => {
        message.error("An error occurred while trying to sign in.");
        setAuthContext({ ...authContext, isLoading: false });
      }
    );
  }

  function setSelectedCompanyName(companyName: string) {
    const currentActiveApplicationRouteName = GetActiveApplicationFromUrl(location);
    if (IsAppRoute(currentActiveApplicationRouteName) && companyAppMapping[companyName] !== undefined) {
      // check application and company compatible
      const currentActiveApplicationName = ROUTE_TO_APP_NAME[currentActiveApplicationRouteName];
      const isCompanyApplicationSelectionLegal = companyAppMapping[companyName].includes(currentActiveApplicationName);
      if (!isCompanyApplicationSelectionLegal) {
        console.log("navigating to root level");
        navigate("/");
      }
    }
    changeSelectedCompanyState(companyName);
  }

  if (authContext.isLoading) {
    return (
      <Loading
        tip="Log in via the Google authentication pop-up"
        spinnerProps={{ fullscreen: true, size: "large", style: { fontSize: "4em" } }}
        textStyles={{ color: "white", top: "50vh", position: "relative" }}
      />
    );
  } else if (authContext.isAuthenticated) {
    SyncFireBaseUserData(authContext.user);
    return (
      <AuthContext.Provider value={{ ...authContext, setAuthContext }}>
        <CompanyContext.Provider
          value={{
            selectedCompanyName,
            setSelectedCompanyName,
            companyBranchMapping,
            selectionDisabled,
            setSelectionDisabled,
            companies: companyPermissions,
          }}
        >
          <Routes>
            <Route
              element={
                <Dashboard appPermissions={appPermissions} companyPermissions={companyPermissions} companyAppMapping={companyAppMapping} />
              }
            >
              <Route path="" element={<NoAppPage />} />
              <Route path="algobooks/*" element={<AlgoBooksApp />} />
              <Route path="foodicsinventory/*" element={<FoodicsToolApp />} />
              <Route path="foodicspricing/*" element={<PriceUpdaterApp />} />
              <Route path="sales/*" element={<CantinaSalesApp />} />
              <Route path="recipebook/*" element={<RecipeBookApp />} />
              <Route path="dataentry/*" element={<DataEntryApp />} />
              <Route path="no-permission/" element={<PermissionDeniedPage />} />
              <Route path="no-user/" element={<UserNotRegisteredPage />} />
              <Route path="no-response/" element={<NoResponsePage />} />
              <Route path="no-connection/" element={<NoConnectionPage />} />
              <Route path="*" element={<ErrorPage />} />
            </Route>
          </Routes>
        </CompanyContext.Provider>
      </AuthContext.Provider>
    );
  } else {
    return <LoginPage signIn={SignIn} />;
  }
}

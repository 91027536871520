/*
@since: 2023-1-19
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { SalesFrequency, SalesRecord, SalesResponse, SalesTableRow } from "Utils/types";
import { TableProps } from "antd";
import { ToReadableCurrency, ToReadableDate } from "./Utils";

export class TableData {
  BranchName: string;
  BranchID: string;
  Frequency: SalesFrequency;
  Currency: string;
  grouping: number;
  SalesRows: SalesRecord[];
  ComponentColumns: TableProps<SalesTableRow>["columns"];
  ComponentRows: SalesTableRow[];
  /**
   * @param {Object} salesDataInfo
   * Handles formatting the data for antd Table Component
   */
  constructor(salesDataInfo: SalesResponse) {
    this.BranchName = salesDataInfo.branchName;
    this.BranchID = salesDataInfo.branchID;
    this.Frequency = salesDataInfo.frequency;
    this.Currency = salesDataInfo.currency;
    this.grouping = salesDataInfo.grouping;
    this.SalesRows = salesDataInfo.sales;
    this.ComponentColumns = [];
    this.ComponentRows = [];
  }

  static GetCurrencyRenderingFunction(currency: string) {
    return (number: number) => <span>{ToReadableCurrency(number, currency)}</span>;
  }

  GetCategoryColumnKey(categoryID: number) {
    return `ID${categoryID}-total`;
  }
}

export class SalesTable extends TableData {
  constructor(salesDataInfo: SalesResponse) {
    super(salesDataInfo);
    this.ComponentColumns = this.GenerateTableColumns();
    this.ComponentRows = this.GenerateTableRows();
  }
  GenerateTableColumns(): TableProps<SalesTableRow>["columns"] {
    const columns: TableProps<SalesTableRow>["columns"] = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (date: string) => <span>{ToReadableDate(date, this.Frequency)}</span>,
      },
      {
        title: "Sales",
        dataIndex: "total",
        key: "total",
        render: (number: number) => <span>{ToReadableCurrency(number, this.Currency)}</span>,
      },
    ];

    return columns;
  }

  GenerateTableRows(): SalesTableRow[] {
    let tableRows: SalesTableRow[] = [];
    for (let row of this.SalesRows) {
      let currentTableRow: SalesTableRow = {
        key: row.id,
      };
      for (let column of this.ComponentColumns!) {
        currentTableRow[column.key] = row[column.key];
      }
      tableRows.push(currentTableRow);
    }
    return tableRows;
  }
}

import { API_ROUTES } from "Utils/constants";
import { AxiosError, AxiosResponse } from "axios";
import { axiosInstance } from "../config/axios";

export const FetchUserInfo = (email: string): Promise<void> => {
  let form: FormData = new FormData();
  form.append("email", email);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_USER, form)
      .then((res: AxiosResponse) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err: AxiosError) => {
        reject(err);
      });
  });
};

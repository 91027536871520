import { API_ROUTES } from "Utils/constants";
import { PriceUpdaterRecords, PriceUpdaterSettings } from "Utils/types";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../components/FoodicsTool/Lib/Axios";

interface GetPricesResponse {
  data: PriceUpdaterRecords;
}
export const GetPrices = (branchName: string) => {
  return new Promise<AxiosResponse<GetPricesResponse>>((resolve, reject) => {
    let requestConfig = {
      params: {
        branchName,
      },
    };
    axiosInstance
      .get<AxiosResponse<GetPricesResponse>>(API_ROUTES.FOODICSPRICING_GET_ITEMS, requestConfig)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface GetPriceUpdaterConfigResponse {
  priceUpdaterBranches: string[];
  lastLbpRateUsed: number;
  latestLiraRate: number;
  addToRateConstant: number;
  downloaderStatus: string;
  updaterStatus: string;
  lastDownloadDateUtc: string;
}

export const GetPriceUpdaterConfig = () => {
  return new Promise<GetPriceUpdaterConfigResponse>((resolve, reject) => {
    axiosInstance
      .get<GetPriceUpdaterConfigResponse>(API_ROUTES.FOODICSPRICING_PRICE_UPDATER_CONFIG)
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface RunDownloaderResponse {
  message: string;
}

export const RunDownloader = () => {
  return new Promise<AxiosResponse<RunDownloaderResponse>>((resolve, reject) => {
    axiosInstance
      .post<AxiosResponse<RunDownloaderResponse>>(API_ROUTES.FOODICSPRICING_RUN_DOWNLOADER, {})
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface RunUpdater {
  message: string;
}

export const RunUpdater = (customRate: number) => {
  return new Promise<AxiosResponse<RunUpdater>>((resolve, reject) => {
    axiosInstance
      .put<AxiosResponse<RunUpdater>>(API_ROUTES.FOODICSPRICING_RUN_UPDATER, { customRate })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface FetchStatusesResponse {
  downloaderStatus: string;
  updaterStatus: string;
}

export const FetchStatuses = () => {
  return new Promise<AxiosResponse<FetchStatusesResponse>>((resolve, reject) => {
    axiosInstance
      .get<AxiosResponse<FetchStatusesResponse>>(API_ROUTES.FOODICSPRICING_FETCH_STATUSES)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *
 * @param {*} branchName
 * @param {*} newPrices
 * @returns {Promise<import("axios").AxiosResponse<any, any>>} response
 */
export const UpdateUsdPrices = (branchName: string, newPrices) => {
  return new Promise<AxiosResponse<GetPricesResponse>>((resolve, reject) => {
    let requestConfig = {
      params: {
        branchName,
      },
    };
    axiosInstance
      .put<AxiosResponse<GetPricesResponse>>(API_ROUTES.FOODICSPRICING_UPDATE_USD_PRICES, newPrices, requestConfig)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface UpdateSettingsResponse {
  message: string;
}

export const UpdateSettings = (newSettings: PriceUpdaterSettings) => {
  return new Promise<AxiosResponse<UpdateSettingsResponse>>((resolve, reject) => {
    axiosInstance
      .put<AxiosResponse<UpdateSettingsResponse>>(API_ROUTES.FOODICSPRICING_UPDATE_SETTINGS, newSettings)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

import { BASE_URL } from "Utils/urls";
import axios, { AxiosInstance } from "axios";

const defaultAxiosConfig = {
  baseURL: BASE_URL,
};

const reportFetcher: AxiosInstance = axios.create(defaultAxiosConfig);
const axiosInstance: AxiosInstance = axios.create(defaultAxiosConfig);
const currencyURL: AxiosInstance = axios.create({
  baseURL: "https://api.exchangeratesapi.io/latest?symbols=EUR,USD&base=USD",
});

export { axiosInstance, currencyURL, defaultAxiosConfig, reportFetcher };

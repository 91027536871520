/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { ChartDataLineType, OptionsInstance, OptionsResponse, SelectOption, TableDataType } from "Utils/types";
import { Col, Row, message } from "antd";
import isEmpty from "just-is-empty";
import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { FetchOptions, FetchSales } from "../../../dataHandling/cantina-sales";
import { Options } from "../Utils/Options";
import { SalesTable } from "../Utils/TableData";
import { GenerateChartXAxis, GenerateChartYAxis } from "../Utils/Utils";
import { BLUE_RGB_STRING } from "../Utils/constants";
import { SalesChart } from "../components/SalesChart";
import { SalesOptions } from "../components/SalesOptions";
import { SalesTableComponent } from "../components/SalesTableComponent";

export function SalesPage() {
  const { selectedCompanyName, companyBranchMapping, setSelectionDisabled } = useCompanyContext();
  const navigate = useNavigate();

  const [options, setOptions] = useState<OptionsResponse>({});
  const [optionsInstance, setOptionsInstance] = useState<Options | null>(null);
  const [categoryOptions, setCategoryOptions] = useState<SelectOption[]>([]);
  const [optionsLoading, setOptionLoading] = useState(true);
  const [selectedOptions, setSelected] = useState<OptionsInstance>({});
  const [tableData, setTableData] = useState<TableDataType>({});
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [chartData, setChartData] = useState<ChartDataLineType>({});

  //Initialize selectors, select first choices
  useEffect(() => {
    setOptionLoading(true);
    FetchOptions()
      .then((options) => {
        if (isEmpty(options.Branch)) navigate("denied");
        setOptions(options);
        // Dashboard company selection
        const selectedBranch = options.Branch.find((branch) => branch.name === companyBranchMapping[selectedCompanyName!]);
        const overrideInitialBranchId = selectedBranch!.optionValue;
        const optionsObj = new Options(options, overrideInitialBranchId);
        const initSelectedOptions = optionsObj.selectedOptions;
        setOptionsInstance(optionsObj);
        setCategoryOptions(optionsObj.GetBranchCategories(initSelectedOptions.Branch));
        setSelected({ ...initSelectedOptions });
        setOptionLoading(false);
      })
      .catch((err) => {
        message.error("Error occurred while fetching options, check logs for more details.");
        console.error(err);
      });
  }, []);

  //Fetch appropriate sales on selection
  useEffect(() => {
    if (!isEmpty(selectedOptions)) {
      SendSalesRequest(selectedOptions, options, setTableLoading, setTableData, setChartData);
    }
  }, [selectedOptions.Branch, selectedOptions.Currency, selectedOptions.Frequency, selectedOptions.Category]);

  useEffect(() => {
    setSelectionDisabled(tableLoading);
  }, [tableLoading]);

  const TableAndChartRowStyle = {
    marginLeft: "20px",
    marginRight: "20px",
    overflow: "hidden",
  };

  return (
    <>
      <Row gutter={[{}, { xl: 70, lg: 60, md: 50, sm: 30, xs: 24 }]}>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <SalesOptions
                categoriesOptions={categoryOptions}
                optionsInstance={optionsInstance}
                optionsLoading={optionsLoading}
                salesOptions={options}
                selectedOptions={selectedOptions}
                setCategoryOptions={setCategoryOptions}
                setSelected={setSelected}
                tableLoading={tableLoading}
              />
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={{ xl: 50, lg: 6, md: 9, sm: 20, xs: 24 }} justify="center" style={TableAndChartRowStyle}>
            {!isMobile && (
              <Col span={24}>
                <SalesChart chartData={chartData} />
              </Col>
            )}
            <Col span={24}>
              <SalesTableComponent data={tableData} isLoading={tableLoading} selectedOptions={selectedOptions} />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

function SendSalesRequest(
  selectedOptions: OptionsInstance,
  options: OptionsResponse,
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setTableData: React.Dispatch<React.SetStateAction<TableDataType>>,
  setChartData: React.Dispatch<React.SetStateAction<ChartDataLineType>>
) {
  if (!isEmpty(selectedOptions)) {
    const { Branch, Frequency, Currency, GroupBy, Category } = selectedOptions;
    setTableLoading(true);
    FetchSales(Branch, Frequency, Currency, GroupBy, Category)
      .then((salesDataInfo) => {
        //Filling table data
        const tableData = new SalesTable(salesDataInfo);
        setTableData({
          dataSource: tableData.ComponentRows,
          columns: tableData.ComponentColumns,
        });
        setTableLoading(false);

        //Filling chart data
        const salesRowsForChart = [...tableData.ComponentRows].reverse();
        const saleTotals = GenerateChartYAxis(salesRowsForChart);
        setChartData({
          labels: GenerateChartXAxis(salesRowsForChart, Frequency),
          datasets: [
            {
              data: saleTotals,
              backgroundColor: BLUE_RGB_STRING,
              borderColor: BLUE_RGB_STRING,
            },
          ],
        });
      })
      .catch((err) => {
        message.error("An error occurred while fetching sales, check logs for more details.");
        console.error(err);
      });
  }
}

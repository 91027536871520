import { Input, Modal, message } from "antd";
import axios from "axios";
import { SendSupportMessage } from "dataHandling/dashboard-core";
import React, { useState } from "react";
import { MessageResponse } from "../AlgoBooks/utils/types";

interface SupportModalProps {
  isModalVisible: boolean;
  setModalVisible: (visible: boolean) => void;
}

export default function SupportModal(props: SupportModalProps) {
  const [supportMessage, setSupportMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const onTextChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event: React.ChangeEvent<HTMLTextAreaElement>) =>
    setSupportMessage(event.target.value);
  const sendMessage = () => {
    setLoading(true);
    SendSupportMessage(supportMessage)
      .then((response) => {
        setLoading(false);
        message.success(response.message);
        props.setModalVisible(false);
      })
      .catch((error) => {
        if (error?.response && axios.isAxiosError<MessageResponse>(error)) {
          message.error(error.response.data.message);
        } else {
          message.error("Error occurred while submitting message.");
        }
        setLoading(false);
      });
  };
  return (
    <>
      <Modal
        centered
        destroyOnClose
        open={props.isModalVisible}
        title={<span style={{ cursor: "default" }}>Contact Support</span>}
        okText="Submit Message"
        okButtonProps={{ loading: isLoading, disabled: supportMessage === "" }}
        styles={{ body: { padding: "5px", margin: "20px" } }}
        onCancel={() => props.setModalVisible(false)}
        onOk={sendMessage}
      >
        <Input.TextArea
          placeholder="Feedback..."
          value={supportMessage}
          onChange={onTextChange}
          style={{ padding: "10px", maxHeight: "300px", minHeight: "100px" }}
          size="large"
        ></Input.TextArea>
      </Modal>
    </>
  );
}

import { Result } from "antd";

export default function ErrorPage() {
  return (
    <Result
      status={"error"}
      title={"Access Denied"}
      subTitle={
        <span>
          <span>You currently do not have permission to view this page!</span>
          <br />
          <span>
            If you believe this is not intended, please contact AlgoTraders at
            <a href="mailto: support@algotraders.ai"> support@algotraders.ai</a>.
          </span>
          <br />
          <span>For more information and urgent inquires, you can reach us at +961 (0) 1 203 088 ext 4</span>
        </span>
      }
    />
  );
}

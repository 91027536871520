/*
@since: 2023-3-14
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { message, Row } from "antd";
import { Loading } from "components/AlgoBooks/components/Loading";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { IsNotNullOrUndefined } from "Utils/helpers";
import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { PriceUpdaterCombo, PriceUpdaterModOption, PriceUpdaterProduct } from "Utils/types";
import { GetPrices } from "../../../../dataHandling/PriceUpdaterAPI";
import PriceUpdaterSettings from "../../Components/PriceUpdater/NavBar/PriceUpdaterSettings";
import { GetRecommendedRate } from "../../Components/PriceUpdater/NavBar/PriceUpdaterSettingsUtil";
import { FormatItemsDataForTable } from "../../Components/Utils/PriceTableUtil";
import { GetUrlResource, TabLink } from "../../Components/Utils/RoutesUtil";
import { COMBO_URL_PATH, MODIFIER_OPTION_URL_PATH, PRODUCT_URL_PATH, URL_PRICE_TABLE_INDEX } from "../../Utils/Constants";

export default function PriceUpdaterLayout() {
  const { mainBranch, branchesLoading, priceUpdaterConfig, editMode, setEditMode } = usePriceConfigContext();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const [products, setProducts] = useState<PriceUpdaterProduct[]>();
  const [modifierOptions, setModifierOptions] = useState<PriceUpdaterModOption[]>();
  const [comboItemOptionSizes, setComboItemOptionSizes] = useState<PriceUpdaterCombo[]>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [customNewRate, setCustomNewRate] = useState<number>();
  const [priceUpdaterTabs, setPriceUpdaterTabs] = useState([
    {
      key: PRODUCT_URL_PATH,
      label: <TabLink to={PRODUCT_URL_PATH} text="Products" disabled={editMode} />,
    },
    {
      key: MODIFIER_OPTION_URL_PATH,
      label: <TabLink to={MODIFIER_OPTION_URL_PATH} text="Modifier Options" disabled={editMode} />,
    },
    {
      key: COMBO_URL_PATH,
      label: <TabLink to={COMBO_URL_PATH} text="Combos" disabled={editMode} />,
    },
  ]);

  const currentResource = GetUrlResource(currentLocation, URL_PRICE_TABLE_INDEX);

  useEffect(() => {
    if (IsNotNullOrUndefined(priceUpdaterConfig) && priceUpdaterConfig?.priceUpdaterBranches?.includes(mainBranch)) {
      setDataLoading(true);
      GetPrices(mainBranch)
        .then((response) => {
          if (response.status === 200) {
            // Initialize table data
            const items = response.data.data;
            const newLbpRate = GetRecommendedRate(priceUpdaterConfig);
            FormatItemsDataForTable(items, newLbpRate);
            setProducts(items.products);
            setModifierOptions(items.modifierOptions);
            setComboItemOptionSizes(items.comboItemOptionSizes);

            // Initialize new rate to recommended rate value
            setCustomNewRate(newLbpRate);
          } else {
            message.error(response.data.message);
          }
          setDataLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching prices, check logs for more details.");
          console.error(err);
        });
    } else if (IsNotNullOrUndefined(priceUpdaterConfig) && !priceUpdaterConfig?.priceUpdaterBranches?.includes(mainBranch)) {
      setDataLoading(false);
      navigate("404");
    }
  }, [mainBranch, priceUpdaterConfig?.priceUpdaterBranches]);

  // Loading data
  if (priceUpdaterConfig === null || branchesLoading || dataLoading) {
    return (
      <div>
        <Row justify="center" align="middle" style={{ height: "50vh", marginTop: "2vh" }}>
          <Loading
            tip="Loading data..."
            style={{
              verticalAlign: "middle",
            }}
          />
        </Row>
      </div>
    );
  }

  const dataContext = {
    products,
    setProducts,
    modifierOptions,
    setModifierOptions,
    comboItemOptionSizes,
    setComboItemOptionSizes,
    dataLoading,
    setDataLoading,
    editMode,
    setEditMode,
    customNewRate,
    setCustomNewRate,
  };

  const tabProps = {
    currentResource: currentResource,
    tabs: priceUpdaterTabs,
    disableAll: editMode,
    setTabs: setPriceUpdaterTabs,
  };
  return (
    //  <div style={{ marginTop: "50px" }}>
    <>
      {currentResource !== "404" && <PriceUpdaterSettings settingsProps={dataContext} customTabProps={tabProps} />}
      <Outlet context={dataContext} />
    </>
    // </div>
  );
}

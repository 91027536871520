/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { DataEntryLoadingStates, DataTypeRecord } from "Utils/types";
import { TabsProps } from "antd";
import CustomTabs from "components/FoodicsTool/Components/Utils/CustomTabs";
import { TabLink } from "components/FoodicsTool/Components/Utils/RoutesUtil";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { NoDataTypesPage } from "../NoDataTypesPage/NoDataTypesPage";

interface DataEntryLayoutProps {
  dataTypes: DataTypeRecord[];
  currentDataType: string | undefined;
  loading: DataEntryLoadingStates;
  companyName: string | null;
}

export function DataEntryLayout(props: DataEntryLayoutProps) {
  const [dataEntryTabs, setDataEntryTabs] = useState<TabsProps["items"]>([]);

  useEffect(() => {
    setDataEntryTabs(
      props.dataTypes.map((dataType) => {
        return {
          key: dataType.name.toLowerCase(),
          label: <TabLink to={dataType.name.toLowerCase()} text={dataType.name} disabled={props.loading.dataTypesLoading} />,
        };
      })
    );
  }, [props.dataTypes]);
  return (
    <>
      {props.dataTypes.length === 0 ? (
        <NoDataTypesPage companyName={props.companyName} />
      ) : (
        <>
          <CustomTabs
            currentResource={props.currentDataType?.toLowerCase()}
            tabs={dataEntryTabs}
            disableAll={props.loading.dataTypesLoading}
            setTabs={setDataEntryTabs}
            antdTabProps={{
              tabBarStyle: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              },
            }}
          />
          <Outlet />
        </>
      )}
    </>
  );
}

import { Modal, Row } from "antd";
import { DetailViewProps } from "../../utils/types";
import ReportTable from "./ReportTable";

export default function DetailView(props: DetailViewProps) {
  const loadedParams = props.loadedParams;
  const companyOptions = props.companyOptions;
  const reportOptions = props.reportOptions;
  const socket = props.socket;
  const setDetailsPopUp = props.setPopUp;
  const detailsPopUp = props.popUp;
  const currencyOptions = props.currencyOptions;
  const params = props.params;
  const parentComponentProps = props.props;
  const loading = parentComponentProps.loading;
  const company = parentComponentProps.company;
  const report = parentComponentProps.report;
  const setLoading = parentComponentProps.setLoading;
  const queryCount = parentComponentProps.queryCount;
  const setQueryCount = parentComponentProps.setQueryCount;
  const isCurrencyReport = props.props.isCurrencyReport;

  return (
    <Modal
      title={params.title.replace(/[0-9]/g, "")}
      width={"fit-content"}
      open={detailsPopUp}
      footer={null}
      onCancel={() => setDetailsPopUp(false)}
      closable={!parentComponentProps.loading}
      maskClosable={!parentComponentProps.loading}
      className="algobooks"
    >
      <Row align="middle">

        <ReportTable
          loadedParams={loadedParams}
          companyOptions={companyOptions}
          reportOptions={reportOptions}
          href={params.href}
          company={company}
          currency={params.currency}
          view={"Detail"}
          report={report}
          forceLoading={false}
          socket={socket}
          setLoading={setLoading}
          loading={loading}
          queryCount={queryCount}
          setQueryCount={setQueryCount}
          isCurrencyReport={isCurrencyReport}
          currencyOptions={currencyOptions}
        />
      </Row>
    </Modal>
  );
}

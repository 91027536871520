/*
@since: 2023-3-16
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Switch } from "antd";

export default function CustomEditSwitch({ loading, disabled, checked, onClick, onChange }) {
  return (
    <Switch
      style={{ marginRight: "25px", width: "150px", marginBottom: "10px" }}
      checkedChildren="Save Changes"
      unCheckedChildren="Edit Prices"
      loading={loading}
      disabled={disabled}
      checked={checked}
      onClick={onClick}
      onChange={onChange}
    />
  );
}

/**
 * @since: 2023-12-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import PageMessage from "components/AlgoBooks/components/PageMessage";
import "components/Shared/Styles/styles.css";

export function UserNotRegisteredPage() {
  return (
    <div className="container-center">
      <PageMessage
        status={"error"}
        title={"User not registered"}
        subTitle={
          <span>
            Please contact us: <a href="mailto: support@algotraders.ai"> support@algotraders.ai</a>.
          </span>
        }
      />
    </div>
  );
}

import { Button, message, Popconfirm, Space } from "antd";
import { useState } from "react";
import { FoodicsToolInventoryCount } from "Utils/types";
import { ConfirmInventoryCount, DeleteInventoryCount, GetInventoryCountDetails } from "../../../../../dataHandling/InventoryCountAPI";
import { DownloadExcelFileFromBytes } from "../../../Utils/Excel";

interface TableActionButtonsProps {
  record: FoodicsToolInventoryCount;
  setModalFlag: React.Dispatch<React.SetStateAction<boolean | null>>;
  mainBranch: string;
  updateCounts: React.Dispatch<React.SetStateAction<string>>;
}

interface TableActionButtonsLoadingStates {
  download: boolean;
  delete: boolean;
  confirm: boolean;
}

export default function TableActionButtons(props: TableActionButtonsProps) {
  const record = props.record;
  const [loading, setLoading] = useState<TableActionButtonsLoadingStates>({
    download: false,
    delete: false,
    confirm: false,
  });
  const handleDelete = () => {
    setLoading({ ...loading, delete: true });
    DeleteInventoryCount(props.mainBranch, record.id)
      .then((response) => {
        if (response.status === 200) {
          message.success("Inventory count has been delete successfully");
          props.updateCounts(record.id);
        } else {
          console.log(response);
          message.error("Failed to delete inventory count");
        }
        setLoading({ ...loading, delete: false });
      })
      .catch((err) => {
        message.error("An error occurred while deleting inventory count, check logs for more details.");
        console.error(err);
      });
  };

  const changeStatus = () => {
    setLoading({ ...loading, confirm: true });
    ConfirmInventoryCount(props.mainBranch, record.id)
      .then((response) => {
        if (response.status === 200) {
          message.success("Inventory count has been confirmed successfully");
          props.updateCounts(record.id);
        } else {
          console.log(response);
          message.error("Failed to confirm inventory count");
        }
        setLoading({ ...loading, confirm: false });
      })
      .catch((err) => {
        message.error("An error occurred while confirming inventory count, check logs for more details.");
        console.error(err);
      });
  };
  const handleDownload = () => {
    setLoading({ ...loading, download: true });
    GetInventoryCountDetails(props.mainBranch, record.id)
      .then((response) => {
        if (response.status === 200) {
          DownloadExcelFileFromBytes(response.data, record.reference);
        } else {
          console.log(response);
          message.error("Failed to download inventory count");
        }
        setLoading({ ...loading, download: false });
      })
      .catch((err) => {
        message.error("An error occurred while fetching inventory count details, check logs for more details.");
        console.error(err);
      });
  };
  return (
    <Space size="middle" align="end">
      <Button onClick={handleDownload} loading={loading.download}>
        Download
      </Button>
      <Popconfirm
        placement="left"
        title="Are you sure you would like to delete this item?"
        onConfirm={(e) => {
          handleDelete();
        }}
        okText="Yes"
        cancelText="No"
        disabled={record.status === "Closed"}
      >
        <Button type="primary" danger disabled={record.status === "Closed"} loading={loading.delete}>
          Delete
        </Button>
      </Popconfirm>

      <Popconfirm
        placement="left"
        title="Are you sure you would like to lock-in this item?"
        onConfirm={(e) => {
          changeStatus();
        }}
        okText="Yes"
        cancelText="No"
        disabled={record.status === "Closed"}
      >
        <Button type="primary" disabled={record.status === "Closed"}>
          Confirm
        </Button>
      </Popconfirm>
    </Space>
  );
}

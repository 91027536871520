/**
 * @since: 2023-11-23
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import "components/Shared/Styles/styles.css";

export function NoAppPage() {
  return (
    <div className="container-center">
      <p style={{ fontSize: "2em", fontWeight: "200px", color: "gray", cursor: "default", textAlign:"center" }}>Select an application from the sidebar.</p>
    </div>
  );
}

import { API_ROUTES } from "Utils/constants";
import { OptionsResponse, SalesFrequency, SalesResponse } from "Utils/types";
import { ALL_CATEGORIES_INDICATOR } from "../components/CantinaSales/Utils/constants";
import { axiosCustom } from "../components/CantinaSales/config/axios";

const FetchOptions = () => {
  let endpoint = API_ROUTES.CANTINASALES_OPTIONS;

  return new Promise<OptionsResponse>((resolve, reject) => {
    axiosCustom
      .get<OptionsResponse>(endpoint)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchSales = (branchID: string, frequency: SalesFrequency, currency: string, grouping: number, categoryID: number | string) => {
  const resource = API_ROUTES.CANTINASALES_SALES;
  let params = {
    branchID,
    frequency,
    grouping,
    currency,
    ...(categoryID !== ALL_CATEGORIES_INDICATOR && { categoryID }),
  };
  return new Promise<SalesResponse>((resolve, reject) => {
    axiosCustom
      .get<SalesResponse>(resource, { params })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { FetchOptions, FetchSales };

export const APP_NAME = "Cantina Sales";

export const colors = {
  WHITE: "#FFFFFF",
  RED: "#ff0000",
  LIGHTBLUE: "#1e94fc",
  BLUE: "#0000FF",
  GREEN: "#00FF00",
  YELLOW: "#FFFF00",
  BLACK: "#000000",
  SILVER: "#C0C0C0",
  GRAY: "#808080",
  MAROON: "#800000",
  OLIVE: "#808000",
  LIME: "#00FF00",
  AQUA: "#00FFFF",
  TEAL: "#008080",
  NAVY: "#000080",
  FUCHSIA: "#FF00FF",
  PURPLE: "#800080",
  INDIANRED: "#CD5C5C",
  LIGHTCORAL: "#F08080",
  SALMON: "#FA8072",
  DARKSALMON: "#E9967A",
  LIGHTSALMON: "#FFA07A",
};

export const DAILY_FORMAT = "dddd, MMMM D, YYYY";
export const WEEKLY_FORMAT = "W YYYY";
export const MONTHLY_FORMAT = "MMMM YYYY";
export const QUARTERLY_FORMAT = "Q YYYY";
export const YEARLY_FORMAT = "YYYY";

export const FREQUENCY_FORMATS = {
  daily: {
    preString: "",
    format: DAILY_FORMAT,
  },
  weekly: {
    preString: "W",
    format: WEEKLY_FORMAT,
  },
  monthly: {
    preString: "",
    format: MONTHLY_FORMAT,
  },
  quarterly: {
    preString: "Q",
    format: QUARTERLY_FORMAT,
  },
  yearly: {
    preString: "",
    format: YEARLY_FORMAT,
  },
};

export const BLUE_RGB_STRING = "#4b66af";

export const GROUPING_BY_NONE = 1;
export const GROUPING_BY_CATEGORY = 2;
export const ALL_CATEGORIES_INDICATOR = "ALL";

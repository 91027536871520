/*
@since: 2023-3-15
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Table } from "antd";

export default function CustomPriceTable({ columns, dataSource, loading, rowKey, pagination }) {
  return (
    <>
      <Table
        style={{ cursor: "default" }}
        bordered
        columns={columns}
        dataSource={dataSource}
        loading={loading}
        rowKey={rowKey}
        pagination={pagination}
        scroll={{ x: true }}
      />
    </>
  );
}
// locale
// table page in url

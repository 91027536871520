function GetPeriodOptions() {
  return {
    Monthly: GetMonthlyOptions(),
    Quarterly: GetQuarterlyOptions(),
    Yearly: GetYearlyOptions(),
  };
}

function GetMonthlyOptions() {
  var listMonthlyOptions = [];
  var initialDate = new Date();
  var currentDate = new Date(initialDate.getFullYear(), initialDate.getMonth(), 1);
  for (let index = 0; index < 24; index++) {
    if (index > 0) {
      currentDate.setMonth(currentDate.getMonth() - 1);
    }
    var startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    var endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    listMonthlyOptions.push({
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      text: startDate.toLocaleString("default", { month: "short" }) + " " + String(startDate.getFullYear()),
      key: index,
    });
  }
  return listMonthlyOptions;
}

function GetYearlyOptions() {
  var listYearlyOptions = [];
  var currentDate = new Date(new Date().getFullYear(), 0, 1);
  for (let index = 0; index < 2; index++) {
    if (index > 0) {
      currentDate.setFullYear(currentDate.getFullYear() - 1);
    }
    var startDate = new Date(currentDate.getFullYear(), 0, 1);
    var endDate = new Date(currentDate.getFullYear(), 12, 0);
    listYearlyOptions.push({
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      text: String(startDate.getFullYear()),
      key: index,
    });
  }
  return listYearlyOptions;
}

function GetQuarterlyOptions() {
  var listQuarterlyOptions = [];

  var currentDate = new Date();
  var quarterStart = new Date(currentDate.getFullYear(), GetQuarterNumber(currentDate) * 3 - 3, 1);

  for (let index = 0; index < 8; index++) {
    if (index > 0) {
      quarterStart.setMonth(quarterStart.getMonth() - 3);
    }

    var startDate = new Date(quarterStart.getFullYear(), quarterStart.getMonth(), 1);
    var endDate = new Date(quarterStart.getFullYear(), quarterStart.getMonth() + 3, 0);
    listQuarterlyOptions.push({
      start: startDate.toISOString().slice(0, 10),
      end: endDate.toISOString().slice(0, 10),
      text: "Q" + String(GetQuarterNumber(startDate)) + " " + String(quarterStart.getFullYear()),
      key: index,
    });
  }
  return listQuarterlyOptions;
}

function GetQuarterNumber(date) {
  return Math.floor((date.getMonth() + 3) / 3);
}

/**
 *
 * @param {Object.<string, any>} companyOptions
 * @param {string} reportType
 * @returns {Object.<string, Array<Object.<string, string>>>} proper period options based on company options, grouping and report type
 */
function GetAllPeriodOptions(companyOptions, reportType, grouping) {
  const periodOptions = companyOptions.periodOptions;
  // Period options configured for all report types
  if (grouping in periodOptions) {
    return periodOptions;
  } else {
    // Period options per report type
    return periodOptions[reportType];
  }
}

export { GetPeriodOptions, GetAllPeriodOptions };

/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { OptionsInstance, SelectOnChange, SelectOption } from "Utils/types";
import { Select } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { colors } from "../Utils/constants";

interface CustomSelectProps {
  optionsLoading: boolean;
  setSelected: React.Dispatch<React.SetStateAction<OptionsInstance>>;
  selectedOptions: OptionsInstance;
  tableLoading: boolean;
  options: SelectOption[];
  optionName: string;
  customHandler?: SelectOnChange;
}

export function CustomSelect(props: CustomSelectProps) {
  const choices = props.options; //array of possible options, each an <Option /> component
  const optionsLoading = props.optionsLoading;
  const optionName = props.optionName; // Branch, Currency, Frequency ...
  const selectedOptions = props.selectedOptions;
  const setSelected = props.setSelected;
  const customHandler = props.customHandler;
  const tableLoading = props.tableLoading || false;
  const { Option } = Select;

  const defaultHandler: SelectOnChange = (selectedValue: string | number, selectedOptionInfo: DefaultOptionType | DefaultOptionType[]) => {
    const optionType = selectedOptionInfo.title;
    let newSelectedOptions = structuredClone(selectedOptions);
    newSelectedOptions[optionType] = selectedValue;
    setSelected({ ...newSelectedOptions });
  };
  const handleOptionChange = customHandler || defaultHandler;

  const optionsSelectorStyle = {
    width: "100%",
  };

  const selectContainerStyle = {
    marginTop: "20px",
    marginLeft: "20px",
    marginRight: "20px",
  };

  const labelStyle = {
    color: colors.LIGHTBLUE,
  };

  return (
    <div style={selectContainerStyle}>
      <span style={labelStyle}>{optionName}</span>
      <Select
        value={selectedOptions[optionName]}
        defaultActiveFirstOption={false}
        onChange={handleOptionChange}
        disabled={optionsLoading || tableLoading}
        loading={optionsLoading}
        placeholder={optionName}
        size="large"
        style={optionsSelectorStyle}
      >
        {choices &&
          choices.map((choice) => {
            return (
              <Option value={choice.optionValue} key={choice.optionValue} title={optionName}>
                {choice.name}
              </Option>
            );
          })}
      </Select>
    </div>
  );
}

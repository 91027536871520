import { Decimal } from "decimal.js";

export const INVENTORY_URL_ROUTE = "inventory-count";
export const PRICE_UPDATER_URL_ROUTE = "price-updater";
export const PRODUCT_URL_PATH = "products";
export const MODIFIER_OPTION_URL_PATH = "modifier-options";
export const COMBO_URL_PATH = "combos";
export const SETTINGS_URL_PATH = "settings";

export const MINIMUM_PRICE = 0;
export const MAXIMUM_PRICE = 1000;
export const CURR_USD_PRICE_COL_TITLE = "Current Price ($)";
export const CURR_LBP_PRICE_COL_TITLE = "Current Price (LBP)";
export const NEW_PRICE_COL_TITLE = `New Price ($) (${MINIMUM_PRICE} - ${MAXIMUM_PRICE})`;
export const CURR_USD_PRICE_FIELD = "usd_price";
export const NEW_USD_PRICE_FIELD = "new_usd_price";
export const NULL_PRICE_TEXT = "N / A";

export const STATUS = {
  RUNNING: "RUNNING",
  STOPPED: "STOPPED",
  FAILED: "FAILED",
  DONE: "DONE",
};

export const CURRENCY = {
  USD: "USD",
  LBP: "LBP",
};

export const PRICES_CEILING = new Decimal(1000);
export const URL_BASE_INDEX = 0; //   /price-updater/product
export const URL_RESOURCE_INDEX = URL_BASE_INDEX + 1; //   /(price-updater)/product
export const URL_PRICE_TABLE_INDEX = URL_BASE_INDEX + 2; //   /price-updater/(product)

import { API_ROUTES } from "Utils/constants";
import { InventoryCounts, ListSubBranchesType } from "Utils/types";
import { AxiosResponse } from "axios";
import { axiosInstance } from "../components/FoodicsTool/Lib/Axios";

const ListBranches = () => {
  return new Promise<AxiosResponse<string[]>>((resolve, reject) => {
    let values = {
      params: {},
    };
    axiosInstance
      .get<AxiosResponse<string[]>>(API_ROUTES.FOODICSINVENTORY_BRANCHES, values)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



const ListSubBranches = (branch: string) => {
  return new Promise<AxiosResponse<ListSubBranchesType>>((resolve, reject) => {
    let values = {
      params: {
        branch: branch,
      },
    };
    axiosInstance
      .get<AxiosResponse<ListSubBranchesType>>(API_ROUTES.FOODICSINVENTORY_SUBBRANCHES, values)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetInventoryCounts = (branch: string, page: number) => {
  return new Promise<AxiosResponse<InventoryCounts>>((resolve, reject) => {
    let values = {
      params: {
        branch: branch,
        page: page,
      },
    };
    axiosInstance
      .get<AxiosResponse<InventoryCounts>>(API_ROUTES.FOODICSINVENTORY_GET_INVENTORY_COUNTS, values)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetInventoryCountTemplate = (branch: string) => {
  return new Promise<AxiosResponse<ArrayBuffer>>((resolve, reject) => {
    let values = {
      params: {
        branch: branch,
      },
    };
    axiosInstance
      .get<AxiosResponse<ArrayBuffer>>(API_ROUTES.FOODICSINVENTORY_INVENTORY_COUNT_TEMPLATE, values)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const DeleteInventoryCount = (branch: string, inventoryCountId: string) => {
  const formData = new FormData();
  formData.append("id", inventoryCountId);
  formData.append("branch", branch);
  return new Promise<AxiosResponse>((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.FOODICSINVENTORY_DELETE_INVENTORY_COUNT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ConfirmInventoryCount = (branch: string, inventoryCountId: string) => {
  const formData = new FormData();
  formData.append("id", inventoryCountId);
  formData.append("branch", branch);
  return new Promise<AxiosResponse>((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.FOODICSINVENTORY_CONFIRM_INVENTORY_COUNT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetInventoryCountDetails = (branch: string, inventoryCountId: string) => {
  let form = new FormData();
  form.append("id", inventoryCountId);
  form.append("branch", branch);
  console.log(branch);
  return new Promise<AxiosResponse<ArrayBuffer>>((resolve, reject) => {
    axiosInstance
      .post<AxiosResponse<ArrayBuffer>>(API_ROUTES.FOODICSINVENTORY_GET_INVENTORY_COUNT, form)
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const CreateInventoryCount = (branch: string, branchId: string, note: string, file: Blob) => {
  const formData = new FormData();
  formData.append("branch", branch);
  formData.append("id", branchId);
  formData.append("note", note);
  formData.append("file", file);
  return new Promise<AxiosResponse>((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.FOODICSINVENTORY_CREATE_INVENTORY_COUNT, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: AxiosResponse) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export {
  ConfirmInventoryCount,
  CreateInventoryCount,
  DeleteInventoryCount,
  GetInventoryCountDetails,
  GetInventoryCountTemplate,
  GetInventoryCounts,
  ListBranches,
  ListSubBranches,
};

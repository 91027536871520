import { Col, Layout, Row, message } from "antd";
// import "antd/dist/antd.min.css";
import { STATUS_ROUTES } from "Utils/constants";
import { useAuthContext } from "Utils/hooks/useAuthContext";
import "components/Shared/Styles/styles.css";
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { IMAGES } from "../../Utils/constants";
import "./AlgoBooks.scss";
import { FetchUserInfo } from "./api/user";
import { GenerateReportsButton } from "./components/GenerateReportsButton";
import { Loading } from "./components/Loading";
import TopBar from "./components/TopBar/TopBar";
import { UserGuideButton } from "./components/UserGuideButton";
import { appRoutes } from "./urls";
import QuickbooksReports from "./views/QuickbooksReports";
import ResultPage from "./views/ResultPage";

function AlgoBooksApp() {
  const { Content } = Layout;
  const { isAuthenticated, user, isLoading } = useAuthContext();
  const location = useLocation();
  const [redirecting, setRedirecting] = useState(true);

  useEffect(() => {
    if (isAuthenticated && !isLoading) {
      FetchUserInfo(user.email!)
        .then((userInfo) => {
          if (typeof userInfo !== "object") {
            throw new Error(`Can not process user info (${userInfo})`);
          }
          setRedirecting(false);
        })
        .catch((err) => {
          const maintenanceURL = "/algobooks/" + appRoutes.maintenance;
          const statusPages = [maintenanceURL, ...STATUS_ROUTES];

          if (!statusPages.includes(location.pathname)) {
            // if error was not handled
            // navigate(maintenanceURL);
            // window.location.replace(maintenanceURL);
            message.error("An error occurred while fetching user data.");
          }
          setRedirecting(false);
        });
    }
    // eslint-disable-next-line
  }, [isAuthenticated]);

  return (
    <Layout style={{ minHeight: "100%" }} className="algobooks">
      <TopBar
        logoUrl={IMAGES.ALGOBOOKS_LOGO}
        siteName="AlgoBooks"
        rightSidePanelChildren={
          <Row justify="end" style={{ width: "100%", height: "100%", alignItems: "center" }}>
            <Col xxl={5} xl={7} lg={9} md={12} sm={15} xs={13} style={{ display: "flex", justifyContent: "center", marginRight:"10px" }}>
              <GenerateReportsButton />
            </Col>
            <Col xxl={4} xl={5} lg={7} md={7} sm={7} xs={9}>
              <UserGuideButton />
            </Col>
          </Row>
        }
        rideSideColumnProps={{
          span: 12,
          className: "",
        }}
      />
      <Content>
        <div
          style={{
            paddingTop: "20px",
            paddingBottom: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {isLoading || redirecting ? (
            <div className="container-center">
              <Loading tip="Redirecting" />
            </div>
          ) : (
            <Routes>
              <Route path=":realmId/:departments/:classes/:report/:frequency/:period/:currency" element={<QuickbooksReports />} />
              <Route path="" element={<QuickbooksReports />} />
              <Route path={appRoutes.maintenance} element={<ResultPage state={"offline"} />} />
            </Routes>
          )}
        </div>
      </Content>
    </Layout>
  );
}
export default AlgoBooksApp;

import { FileExcelTwoTone, FilePdfTwoTone, LoadingOutlined, MailTwoTone, MinusSquareTwoTone, PlusSquareTwoTone } from "@ant-design/icons";
import { Button, Row, Tooltip, message } from "antd";
// import "antd/dist/antd.min.css";
import { isAxiosError } from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { SiGooglesheets } from "react-icons/si";
import { GetExcel, GetPDF } from "../../../../dataHandling/algobooks.js";
import { CleanColumnsData } from "../../utils/ComponentUtils/ReportTable/formatters.js";
import { CompanyOption, GroupingType, ReportColumn, ReportOption, ReportParams, ReportRow } from "../../utils/types.js";

interface ReportToolbarProps {
  loadedParams: ReportParams;
  companyOptions: CompanyOption[];
  reportOptions: ReportOption[];
  company: string;
  disabled: boolean;
  pdfLoading: boolean;
  report: string;
  view: string;
  columns: ReportColumn[];
  rows: ReportRow[];
  frequency: GroupingType;
  setPdfLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setPDFError: React.Dispatch<React.SetStateAction<boolean>>;
  setExpandedKeys: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function ReportToolbar(props: ReportToolbarProps) {
  const [expand, setExpand] = useState<boolean>(false);
  const [keys, setKeys] = useState<string[]>([]);
  const [excelLoading, setExcelLoading] = useState<boolean>(false);
  const [selectedCompany, setSelectedCompany] = useState<CompanyOption>({});
  const [selectedReport, setSelectedReport] = useState<ReportOption>({});
  const { setExpandedKeys, rows, columns } = props;

  const handleExpand = () => {
    setExpandedKeys(keys);
    setExpand(!expand);
  };

  const handleCollapse = () => {
    setExpandedKeys([]);
    setExpand(!expand);
  };

  useEffect(() => {
    setExpandedKeys([]);
    setExpand(false);
    setKeys(fetchKeys(rows));
  }, [setExpandedKeys, rows]);

  // Get company from URL
  useEffect(() => {
    // check if url provides company
    const companyRealmID = Object.keys(props.loadedParams).length !== 0 ? props.loadedParams.realmId : props.company;
    const selectedCompanyOption = props.companyOptions.find((obj) => {
      return obj.realmId === companyRealmID;
    });
    if (selectedCompanyOption === undefined) throw new Error("Company realm id not found in cached company data.");
    setSelectedCompany(selectedCompanyOption);
  }, [props.loadedParams.realmId, props.company]);

  // Get report from URL
  useEffect(() => {
    // option selection from URL or default
    const params = Object.keys(props.loadedParams).length !== 0 ? props.loadedParams : props;
    const reportValue = params.report;
    let selectedReportOption = props.reportOptions.find((obj) => {
      return obj.value === reportValue;
    });
    // Revert to default in case in case newly selected company does not support previously selected report type
    if (selectedReportOption === undefined) selectedReportOption = props.reportOptions[0];
    setSelectedReport(selectedReportOption);
  }, [props.loadedParams.report, props.report]);
  if (rows)
    return (
      <Row>
        {/* Collapse | Expand buttons */}
        {expand ? (
          <Tooltip placement="top" title="Collapse All">
            <Button disabled={props.disabled} onClick={handleCollapse}>
              <MinusSquareTwoTone type="primary" onClick={handleCollapse}></MinusSquareTwoTone>
              Collapse all
            </Button>
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Expand All">
            <Button disabled={props.disabled} onClick={handleExpand}>
              <PlusSquareTwoTone type="primary"></PlusSquareTwoTone>
              Expand All
            </Button>
          </Tooltip>
        )}
        {/* Download Excel Button */}
        <Tooltip placement="top" title="Save to Excel">
          <Button disabled={props.disabled} onClick={() => generateExcel(rows, columns, setExcelLoading)}>
            {excelLoading ? <LoadingOutlined /> : <FileExcelTwoTone />}
            Save To Excel
          </Button>
        </Tooltip>
        {props.view === "General" ? (
          <>
            {/* Download PDF Button */}
            <Tooltip placement="top" title="Save to PDF">
              <Button
                disabled={props.disabled || props.pdfLoading}
                onClick={() => generatePDF(rows, columns, selectedCompany, selectedReport, props.setPdfLoading, props.setPDFError)}
              >
                {props.pdfLoading ? <LoadingOutlined /> : <FilePdfTwoTone />}
                Save To PDF
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Send Report Email">
              <Button
                disabled={props.disabled}
                style={props.disabled ? { color: "#B8B8B8" } : {}}
                onClick={() => sendEmail(selectedReport, selectedCompany, props.loadedParams)}
                target="popup"
              >
                <MailTwoTone />
                Send Email
              </Button>
            </Tooltip>
            {selectedCompany.payrollSheets &&
              selectedCompany.payrollSheets.map((payrollSheet) => (
                <Tooltip placement="top" title="Open Payroll Sheet" key={payrollSheet.name}>
                  <Button
                    disabled={props.disabled}
                    style={props.disabled ? { color: "#B8B8B8" } : {}}
                    target="popup"
                    onClick={() => window.open(payrollSheet.sheetUrl, "_blank")}
                  >
                    <SiGooglesheets style={{ color: "#1890ff", position: "relative", left: "-4px", top: "2px" }} />
                    {payrollSheet.name}
                  </Button>
                </Tooltip>
              ))}
          </>
        ) : (
          <></>
        )}
      </Row>
    );
  else {
    return <div></div>;
  }
}

function generateExcel(data: ReportRow[], cols: ReportColumn[], setExcelLoading: React.Dispatch<React.SetStateAction<boolean>>): void {
  if (cols.length === 0 || data.length === 0) {
    message.error("No report data found, please view a report before exporting.");
  } else {
    setExcelLoading(true);
    const formattedColumns = CleanColumnsData(cols);
    GetExcel(JSON.stringify(data), JSON.stringify(formattedColumns))
      .then((res) => {
        var bytes = new Uint8Array(res);
        var blob = new Blob([bytes]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Report.xlsx";
        link.click();
        setExcelLoading(false);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          message.error(err?.response?.data?.message);
        } else {
          message.error("Exporting details report failed, data might be too large.");
        }
        setExcelLoading(false);
      });
  }
}

function generatePDF(
  data: ReportRow[],
  cols: ReportColumn[],
  selectedCompany: CompanyOption,
  selectedReport: ReportOption,
  setPdfLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setPDFError: React.Dispatch<React.SetStateAction<boolean>>
) {
  if (!selectedReport || !selectedCompany || cols.length === 0 || data.length === 0) {
    message.error("No report data found, please view a report before exporting.");
  } else {
    setPdfLoading(true);
    var currentDate = moment().format("DD MMMM YYYY LTS");
    GetPDF(JSON.stringify(data), JSON.stringify(cols), selectedCompany.name, selectedReport.text, currentDate)
      .then((res) => {
        var bytes = new Uint8Array(res);
        var blob = new Blob([bytes]);
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Report.pdf";
        link.click();
        setPdfLoading(false);
        setPDFError(false);
      })
      .catch((err) => {
        setPdfLoading(false);
        setPDFError(true);
      });
  }
}

function sendEmail(selectedReport: ReportOption, selectedCompany: CompanyOption, loadedOptions: ReportParams): void | boolean {
  if (!selectedReport || !selectedCompany || (loadedOptions && Object.keys(loadedOptions).length === 0)) {
    message.error("No report data found, please view a report before sending an email.");
  } else {
    window.open(
      "mailto:?subject=" +
        encodeURIComponent("AlgoBooks Financial Report") +
        "&body=" +
        encodeURIComponent(
          "Dear management,\nYou can find the latest " +
            selectedReport?.text +
            " report for " +
            selectedCompany.name +
            " here: \n\n" +
            window.location.href +
            "\n\nBest regards,"
        ),
      "popup",
      "width=600,height=600"
    );
    return false;
  }
}

function fetchKeys(rows: ReportRow[]): string[] {
  var res: string[] = [];
  rows.forEach((row) => {
    res.push(row.key);
    if ("children" in row && row.children) {
      res = res.concat(fetchKeys(row.children));
    }
  });
  return res;
}

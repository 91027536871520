import { Divider } from "antd";

export default function CompanyFooter(props) {
  const companyNote = props.companyNote;
  return companyNote ? (
    <span style={{ cursor: "default" }}>
      <h4>{companyNote}</h4>
      <Divider />
    </span>
  ) : (
    <></>
  );
}

/**
 * @since: 2024-1-3
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { API_ROUTES } from "Utils/constants";
import { AdditionalDescriptions, AdditionalRecord, AlgoBooksEntity, DataTypeRecord } from "Utils/types";
import { axiosInstance } from "components/AlgoBooks/config/axios";

interface AdditionalRecordsResponse {
  success: true;
  additionalRecords: AdditionalRecord[];
}

export const FetchAdditionalRecords = (companyQuickbooksId: string, date: string): Promise<AdditionalRecordsResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<AdditionalRecordsResponse>(API_ROUTES.DATAENTRY_ADDITIONAL_RECORDS, {
        params: {
          companyQuickbooksId,
          date,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
interface LatestAdditionalRecordsResponse {
  success: true;
  latestAdditionalRecords: AdditionalRecord[];
}

export const FetchLatestAdditionalRecords = (companyQuickbooksId: string): Promise<LatestAdditionalRecordsResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<LatestAdditionalRecordsResponse>(API_ROUTES.DATAENTRY_LATEST_ADDITIONAL_RECORDS, {
        params: {
          companyQuickbooksId,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface AdditionalEntitiesResponse {
  success: true;
  classes: AlgoBooksEntity[];
  departments: AlgoBooksEntity[];
  reportNames: string[];
  descriptionOptions: AdditionalDescriptions;
  userDisplayName: string;
}

export const FetchAdditionalEntities = (companyQuickbooksId: string): Promise<AdditionalEntitiesResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<AdditionalEntitiesResponse>(API_ROUTES.DATAENTRY_ADDITIONAL_ENTITIES, {
        params: {
          companyQuickbooksId,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
interface AvailableDataTypesResponse {
  success: true;
  availableDataTypes: DataTypeRecord[];
}

export const FetchAvailableDataTypes = (companyQuickbooksId: string): Promise<AvailableDataTypesResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<AvailableDataTypesResponse>(API_ROUTES.DATAENTRY_DATA_TYPES, {
        params: {
          companyQuickbooksId,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface AvailableDatesResponse {
  success: true;
  availableDates: string[];
}

export const FetchAvailableDates = (companyQuickbooksId: string, dataType: string): Promise<AvailableDatesResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<AvailableDatesResponse>(API_ROUTES.DATAENTRY_AVAILABLE_DATES, {
        params: {
          companyQuickbooksId,
          dataType,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface DataTypeDescriptionResponse {
  success: true;
  dataDescription: string;
}

export const FetchDataTypeDescription = (dataType: string): Promise<DataTypeDescriptionResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get<DataTypeDescriptionResponse>(API_ROUTES.DATAENTRY_DATA_DESCRIPTION, {
        params: {
          dataType,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface UpdateAdditionalDataResponse {
  success: true;
  message: string;
  additionalRecords: AdditionalRecord[];
}

export const UpdateAdditionalData = (
  companyQuickbooksId: string,
  date: string,
  additionalRecords: AdditionalRecord[]
): Promise<UpdateAdditionalDataResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post<UpdateAdditionalDataResponse>(API_ROUTES.DATAENTRY_UPDATE_ADDITIONAL_RECORDS, {
        companyQuickbooksId,
        date,
        additionalRecords,
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

type ExportAdditionalDataResponse = ArrayBuffer;

export const ExportAdditionalData = (additionalRecords: AdditionalRecord[]): Promise<ExportAdditionalDataResponse> => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post<ExportAdditionalDataResponse>(
        API_ROUTES.DATAENTRY_EXPORT_ADDITIONAL_RECORDS,
        {
          additionalRecords,
        },
        { responseType: "arraybuffer" }
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

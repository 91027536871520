/**
 * @since: 2024-1-16
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { DashboardCompany } from "Utils/types";
import { RedirectToDefaultPage } from "components/FoodicsTool/Components/Utils/RoutesUtil";
import { ErrorPage } from "components/Shared/ErrorPage/ErrorPage";
import { useMemo } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { DataEntry } from "./DataEntry";

export function DataEntryApp() {
  const { selectedCompanyName, companies } = useCompanyContext();
  const currentCompany = useMemo(() => companies.find((company) => company.name === selectedCompanyName), [selectedCompanyName]);
  const navigate = useNavigate();
  function NavigateCompanyId(company: DashboardCompany) {
    navigate(company?.quickbooksId);
  }
  return (
    <Routes>
      <Route path="" element={<RedirectToDefaultPage defaultRoute={currentCompany?.quickbooksId} />} />
      <Route path=":companyId/*" element={<DataEntry navigateCompanyId={NavigateCompanyId} />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
}

/**
 * @since: 2024-1-8
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { useDataEntryContext } from "Utils/hooks/useDataEntryContext";
import { ConfigProvider, Switch, Tooltip } from "antd";
import "./EditSwitch.scss";

export function EditSwitch() {
  const { editMode, setEditMode, disabledEditSwitch } = useDataEntryContext();
  const EditSwitch = (
    <Switch
      checked={editMode}
      unCheckedChildren="Edit"
      onClick={() => setEditMode((editMode) => !editMode)}
      disabled={disabledEditSwitch || editMode}
      style={{ minWidth: "150px", borderRadius: "100px" }}
    />
  );

  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 17,
        },
        components: {
          Switch: {
            handleSize: 20,
            trackHeight: 35,
            trackMinWidth: 10,
            trackPadding: 7,
            innerMaxMargin: 60,
            innerMinMargin: 60,
          },
        },
      }}
    >
      {disabledEditSwitch && !editMode ? (
        <Tooltip placement="bottomRight" title="Select a date to enable editing" overlayStyle={{ fontSize: "0.9em" }}>
          {EditSwitch}
        </Tooltip>
      ) : (
        EditSwitch
      )}
    </ConfigProvider>
  );
}

/*
@since: 2023-8-11
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

export enum STATUS {
  RUNNING,
  STOPPED,
  FAILED,
  DONE,
  UNSET,
  LOADING,
}

export function toStatus(status: string): STATUS {
  switch (status) {
    case "RUNNING":
      return STATUS.RUNNING;
    case "STOPPED":
      return STATUS.STOPPED;
    case "FAILED":
      return STATUS.FAILED;
    case "DONE":
      return STATUS.DONE;
    case "UNSET":
      return STATUS.UNSET;
    case "LOADING":
      return STATUS.LOADING;
    default:
      return STATUS.UNSET;
  }
}

import { FoodicsToolInventoryCount, ListSubBranchesType } from "Utils/types";
import { Button, Card, Table } from "antd";
import { useState } from "react";
import CreationModal from "./CreationModal";
import "./InventoryCountTable.scss";
import TableActionButtons from "./TableActionButtons";

interface InventoryCountTableProps {
  counts: FoodicsToolInventoryCount[] | null;
  loading: boolean;
  updateCounts: React.Dispatch<React.SetStateAction<string>>;
  mainBranch: string | null;
  branches: ListSubBranchesType;
}
export default function InventoryCountTable(props: InventoryCountTableProps) {
  const [modalFlag, setModalFlag] = useState<boolean | null>(false);
  const { Column } = Table;
  const dataSource = props.counts;

  return (
    <Card className="count-table-card">
      <Button type="primary" onClick={() => setModalFlag(true)}>
        Create New Inventory Count
      </Button>
      <Table
        loading={!dataSource || props.loading}
        dataSource={dataSource}
        scroll={{ x: true }}
        className="width"
        style={{ cursor: "default" }}
      >
        <Column title="Reference" dataIndex="reference" key="reference" />
        <Column title="Branch" dataIndex="branch" key="branch" />
        <Column title="Status" dataIndex="status" key="status" />
        <Column title="Notes" dataIndex="notes" key="notes" />
        <Column title="Created" dataIndex="created_at" key="created_at" />
        <Column title="Last Updated" dataIndex="updated_at" key="updated_at" />
        <Column
          title="Action"
          key="action"
          align="center"
          render={(text, record: FoodicsToolInventoryCount) => (
            <TableActionButtons
              record={record}
              setModalFlag={setModalFlag}
              updateCounts={props.updateCounts}
              mainBranch={props.mainBranch!}
            />
          )}
        />
      </Table>
      <div>
        <CreationModal
          flag={modalFlag}
          setFlag={setModalFlag}
          branches={props.branches}
          updateCounts={props.updateCounts}
          mainBranch={props.mainBranch!}
        />
      </div>
    </Card>
  );
}

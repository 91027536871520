import { Col, message, Row, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { RecipeDataType } from "Utils/types";
import { FetchMainBranches, GetRecipe } from "../../../../dataHandling/RecipeBook";
import { CustomBar } from "../CustomBar";
import { RecipeBookToolBar as Utility } from "../RecipeBookToolBar";
import { MenuSelect } from "./MenuSelect";
import { RecipeInfo } from "./RecipeInfo";

interface RecipeViewerProps {
  mainBranch: string | null;
  setCompany: (branchName: string) => void;
  mainBranches: string[];
  setMainBranches: React.Dispatch<React.SetStateAction<string[]>>;
}

export default function RecipeViewer(props: RecipeViewerProps) {
  const { mainBranch, setMainBranches, setCompany } = props;

  // Context
  const { setSelectionDisabled, selectedCompanyName, companyBranchMapping } = useCompanyContext();

  // Local State
  const [recipeId, setRecipeId] = useState<string | null>(null);
  const [recipeData, setRecipeData] = useState<RecipeDataType>({});
  const [recipeViewLoading, setRecipeViewLoading] = useState<boolean>(true);

  useEffect(() => {
    setRecipeViewLoading(true);
    FetchMainBranches()
      .then((response) => {
        setMainBranches(response);
        const branchName = selectedCompanyName !== null ? companyBranchMapping[selectedCompanyName] : response[0];
        setCompany(branchName);
        setRecipeViewLoading(false);
      })
      .catch((err) => {
        message.error("An error occurred while fetching branches, check logs for more details.");
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (recipeId) {
      setRecipeViewLoading(true);
      GetRecipe(mainBranch!, recipeId)
        .then((response) => {
          if (response.data !== "error") {
            setRecipeData(response.data);
          } else {
            setRecipeId(null);
            setRecipeData({});
            message.error("There are data issues with selected recipe! Please choose another one");
          }
          setRecipeViewLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching recipe, check logs for more details.");
          console.error(err);
        });
    }
    // eslint-disable-next-line
  }, [recipeId]);

  useEffect(() => {
    setSelectionDisabled(recipeViewLoading);
  }, [recipeViewLoading]);

  const tabItems: TabsProps["items"] = [
    { key: "recipelist", label: "Recipes", children: <MenuSelect setRecipeId={setRecipeId} recipeId={recipeId} mainBranch={mainBranch} /> },
    { key: "toolbar", label: "Ingredients", children: <Utility mainBranch={mainBranch} setRecipeId={setRecipeId} recipeId={recipeId} /> },
  ];

  return (
    <div>
      <Row>
        <Col xs={24} sm={24} md={8} lg={6} xl={4}>
          <Row>
            <CustomBar>
              <Tabs centered defaultActiveKey="recipelist" items={tabItems} />
            </CustomBar>
          </Row>
        </Col>
        <Col xs={24} sm={24} md={16} lg={18} xl={20} style={{ paddingLeft: "15px" }}>
          <RecipeInfo recipeData={recipeData} recipeId={recipeId} loading={recipeViewLoading} mainBranch={mainBranch} />
        </Col>
      </Row>
    </div>
  );
}

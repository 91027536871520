/**
 * @since: 2023-12-20
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import PageMessage from "components/AlgoBooks/components/PageMessage";
import "components/Shared/Styles/styles.css";
import { Link } from "react-router-dom";
export function NoConnectionPage() {
  return (
    <div className="container-center">
      <PageMessage
        status={"error"}
        title={"No internet connection."}
        subTitle={
          <span>
            Your connection appears to be offline.
            <Link reloadDocument to="/" style={{ display: "block" }}>
              Go back to homepage.
            </Link>
          </span>
        }
      />
    </div>
  );
}

/*
@since: 2023-4-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Col, Row } from "antd";
import DownloaderButton from "../DownloaderSetting";
import UpdaterSetting from "../UpdaterSetting";

export default function NavBarOperations({ settingsProps, downloaderState, updaterState, popConfirmState, customNewRate }) {
  return (
    <Row justify="center" style={{ maxWidth: "100%", margin: "0 auto" }}>
      <Col>
        <DownloaderButton
          settingsProps={settingsProps}
          downloaderState={downloaderState}
          updaterState={updaterState}
          popConfirmState={popConfirmState}
        />
      </Col>
      <Col>
        <UpdaterSetting
          settingsProps={settingsProps}
          updaterState={updaterState}
          downloaderState={downloaderState}
          popConfirmState={popConfirmState}
          customNewRate={customNewRate}
        />
      </Col>
    </Row>
  );
}

/**
 * @since: 2024-1-5
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { IsNotNullOrUndefined } from "./helpers";

/**
 *
 * @param monthDate '2022-01'
 * @returns 'January 2022'
 */
export function ToReadableDate(monthDate: string): string {
  const [year, month] = monthDate.split("-");
  const dateInstance = new Date(parseInt(year), parseInt(month) - 1);
  const readableMonth = dateInstance.toLocaleString("default", { month: "long" });
  return `${readableMonth} ${year}`;
}

/**
 *
 * @param number : 7.49
 * @param currency: USD
 * @return formatted number: $ 7.49
 */
export function ToReadableCurrency(number: number, currency?: string | null | undefined): string {
  let options: Intl.NumberFormatOptions = {};
  if (IsNotNullOrUndefined(currency)) {
    options = { style: "currency", currency: currency! };
  }
  return new Intl.NumberFormat("en-US", options).format(number);
}

/**
 * @since: 2023-11-24
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import { DownOutlined } from "@ant-design/icons";
import { GetActiveApplicationFromUrl } from "Utils/RouteUtils";
import { ALL_AXIOS_INSTANCES, IMAGES, ROUTE_TO_APP_NAME } from "Utils/constants";
import { IsNotNullOrUndefined } from "Utils/helpers";
import { useAuthContext } from "Utils/hooks/useAuthContext";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { CompanyAppMapping, DashboardCompany, DashboardCompanyOption } from "Utils/types";
import { Avatar, ConfigProvider, Menu, Select } from "antd";
import { MenuItemType } from "antd/es/menu/hooks/useItems";
import { SignOutFirebaseUser } from "auth";
import SupportModal from "components/SupportModal/SupportModal";
import { SelectEventHandler } from "rc-menu/lib/interface";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from "react-router-dom";
import "./SideBarMenu.scss";

interface CustomMenuItem extends MenuItemType {
  isApplication?: boolean;
  label?: string;
}

interface SideBarMenuProps {
  appPermissions: string[];
  companyPermissions: DashboardCompany[];
  sideBarCollapsed: boolean;
  toggleSideBarCollapse: () => void;
  companyAppMapping: CompanyAppMapping;
}

const sideBarTopSectionHeightPx = 350;
const menuItemHeightPx = 47.31;
const maxNumberOfMenuItems = 8;

export function SideBarMenu(props: SideBarMenuProps) {
  // Utilities
  const navigate = useNavigate();
  const location = useLocation();
  const smallHeight = useMediaQuery({
    query: `(max-height:${sideBarTopSectionHeightPx + menuItemHeightPx * maxNumberOfMenuItems}px)`,
  });

  // Context
  const authContext = useAuthContext();
  const { selectedCompanyName, setSelectedCompanyName, selectionDisabled } = useCompanyContext();

  // Local State
  const [isSupportModalVisible, setSupportModalVisible] = useState<boolean>(false);
  const [selectedApplicationKeys, setSelectedApplicationKeys] = useState<string[]>([]);
  const [companyOptions, setCompanyOptions] = useState<DashboardCompanyOption[]>([]);

  const onShortcutToggleSideBar = (event: KeyboardEvent) => {
    if (event.ctrlKey && event.key.toLowerCase() === "b") props.toggleSideBarCollapse();
  };

  useEffect(() => {
    document.addEventListener("keydown", onShortcutToggleSideBar);
    return () => {
      document.removeEventListener("keydown", onShortcutToggleSideBar);
    };
  }, []);

  useEffect(() => {
    const currentActiveApplication = GetActiveApplicationFromUrl(location);
    // check currentActiveApplication is valid
    setSelectedApplicationKeys([currentActiveApplication]);
  }, [location]);

  useEffect(() => {
    const companyOptions = props.companyPermissions.map((company: DashboardCompany) => {
      return {
        ...company,
        label: company.name,
        value: company.name,
      };
    });
    setCompanyOptions(companyOptions);
  }, [props.companyPermissions]);

  const [centerMenuItems, buttonMenuItems]: CustomMenuItem[][] = useMemo<CustomMenuItem[][]>(() => {
    const currentActiveApplication = GetActiveApplicationFromUrl(location);
    let appMenuItems: CustomMenuItem[] = [
      {
        key: "algobooks",
        title: "AlgoBooks",
        label: "AlgoBooks",
        icon: <Avatar src={IMAGES.ALGOBOOKS_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
      {
        key: "foodicsinventory",
        title: "FoodicsInventory",
        label: "FoodicsInventory",
        icon: <Avatar src={IMAGES.FOODICSTOOL_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
      {
        key: "foodicspricing",
        title: "FoodicsPricing",
        label: "FoodicsPricing",
        icon: <Avatar src={IMAGES.FOODICSTOOL_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
      {
        key: "sales",
        title: "CantinaSales",
        label: "CantinaSales",
        icon: <Avatar src={IMAGES.CANTINASALES_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
      {
        key: "recipebook",
        title: "RecipeBook",
        label: "RecipeBook",
        icon: <Avatar src={IMAGES.RECIPEBOOK_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
      {
        key: "dataentry",
        title: "DataEntry",
        label: "DataEntry",
        icon: <Avatar src={IMAGES.DATAENTRY_LOGO} className="app-logo"></Avatar>,
        isApplication: true,
      },
    ];

    appMenuItems = FilterMenuItemsByPermission(appMenuItems);
    appMenuItems = FilterMenuItemsByCompany(appMenuItems);
    appMenuItems = StyleAppMenuItems(appMenuItems, currentActiveApplication);
    if (currentActiveApplication) setSelectedApplicationKeys([currentActiveApplication]);

    // Buttons
    let buttonMenuItems: CustomMenuItem[] = [];
    // If user registered, show support button
    if (currentActiveApplication !== "no-user") {
      buttonMenuItems.push({
        key: "contact-support",
        label: "Contact Support",
        title: "Contact Support",
        onClick: () => setSupportModalVisible(true),
        icon: (
          <img
            src={IMAGES.SUPPORT_MESSAGE_ICON}
            className="contact-support-icon"
            style={props.sideBarCollapsed ? { marginTop: "0px" } : { marginRight: "9px", marginLeft: "9px" }}
          />
        ),
        className: "menu-item",
      });
    }
    // Remaining buttons
    buttonMenuItems = buttonMenuItems.concat([
      {
        key: "sign-out",
        label: "Sign out",
        title: "Sign out",
        icon: (
          <span
            className="logout-logo-container app-logo"
            style={props.sideBarCollapsed ? { marginTop: "0px" } : { marginRight: "12px", marginLeft: "12px" }}
          >
            <svg
              viewBox="64 64 896 896"
              focusable="false"
              data-icon="logout"
              fill="currentColor"
              aria-hidden="true"
              className="logout-logo-icon"
            >
              <path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path>
            </svg>
          </span>
        ),
        onClick: SignOutUser,
        className: "menu-item-logout",
      },
    ]);
    buttonMenuItems = StyleButtonMenuItems(buttonMenuItems);
    if (smallHeight) {
      return [[...appMenuItems, ...buttonMenuItems], []];
    } else {
      return [appMenuItems, buttonMenuItems];
    }
  }, [props.sideBarCollapsed, location, props.appPermissions, selectedCompanyName, smallHeight]);

  function SignOutUser() {
    authContext.setAuthContext({ ...authContext, isLoading: true });
    navigate("/");
    SignOutFirebaseUser(() => {
      ALL_AXIOS_INSTANCES.map((axiosInstance) => {
        axiosInstance.interceptors.request.clear();
        axiosInstance.interceptors.response.clear();
      });
      authContext.setAuthContext({ ...authContext, isAuthenticated: false, isLoading: false });
    });
  }

  const OnMenuItemSelect: SelectEventHandler = ({ key, keyPath, selectedKeys, domEvent }) => {
    // Selection effect only on apps
    if (key in ROUTE_TO_APP_NAME) setSelectedApplicationKeys([key]);
  };

  function FilterMenuItemsByPermission(menuItems: CustomMenuItem[]) {
    return menuItems.filter((menuItem) => (typeof menuItem.label === "string" ? props.appPermissions.includes(menuItem.label) : true));
  }

  function FilterMenuItemsByCompany(menuItems: CustomMenuItem[]) {
    if (selectedCompanyName === null || selectedCompanyName === undefined) return []; // Must reload page if state is in error
    const companyApplications: string[] = props.companyAppMapping[selectedCompanyName];
    return menuItems.filter((menuItem) => (typeof menuItem.label === "string" ? companyApplications.includes(menuItem.label) : true));
  }

  function StyleAppMenuItems(menuItems: CustomMenuItem[], currentActiveApplication: string | null) {
    menuItems = menuItems.map((menuItem: CustomMenuItem) => {
      menuItem!.className = "menu-item";
      if (menuItem.isApplication) {
        menuItem.onClick = () => navigate("/" + menuItem.key);
        // Current menu item is active, prevent users from navigate to base route of active app
        if (currentActiveApplication === menuItem.key) {
          menuItem.onClick = () => {};
        }
      }
      if (props.sideBarCollapsed) delete menuItem!.label;
      if (props.sideBarCollapsed && menuItem.isApplication) {
        menuItem!.className += " menu-item-collapsed";
      }
      delete menuItem!.isApplication;
      return menuItem;
    });
    return menuItems;
  }

  function StyleButtonMenuItems(menuItems: CustomMenuItem[]) {
    menuItems = menuItems.map((menuItem: CustomMenuItem) => {
      if (props.sideBarCollapsed) {
        delete menuItem!.label;
        menuItem!.className += " menu-item-collapsed";
      }
      return menuItem;
    });
    return menuItems;
  }

  const globalMenuDynamicStyle: CSSProperties = props.sideBarCollapsed
    ? { width: "50px", transitionDuration: "0.2s" }
    : { width: "200px", transitionDuration: "350ms" };

  const centerMenuDynamicStyle: CSSProperties = props.sideBarCollapsed
    ? { top: "70px", ...globalMenuDynamicStyle }
    : { top: "200px", ...globalMenuDynamicStyle };
  if (smallHeight) {
    centerMenuDynamicStyle.overflowY = "scroll";
    centerMenuDynamicStyle.height = "calc(100vh - 250px)"; // sidebarHeight - (total height above & below the menu items)
    centerMenuDynamicStyle.boxSizing = "content-box";
    centerMenuDynamicStyle.msOverflowStyle = "none";
    centerMenuDynamicStyle.scrollbarWidth = "none";
    if (props.sideBarCollapsed) {
      centerMenuDynamicStyle.maxWidth = "49px";
      centerMenuDynamicStyle.height = "calc(100vh - 130px)"; // sidebarHeight - (total height above & below the menu items)
    } else {
      centerMenuDynamicStyle.maxWidth = "199px";
    }
  }

  const bottomMenuDynamicStyle: CSSProperties = { bottom: "57px", ...globalMenuDynamicStyle };
  return (
    <>
      <SupportModal isModalVisible={isSupportModalVisible} setModalVisible={setSupportModalVisible} />
      <div className="flex-parent">
        <div className="profile-info-container">
          <div className="profile-picture-container">
            <Avatar size={props.sideBarCollapsed ? 44 : 100} src={authContext.user.photoURL} className="profile-picture"></Avatar>
          </div>
          {!props.sideBarCollapsed && <p className="profile-name">{authContext.user.displayName}</p>}
        </div>
        <ConfigProvider
          theme={{
            components: {
              Select: {
                selectorBg: "#3e5594",
                optionSelectedBg: "#004b92",
                optionSelectedColor: "white",
                optionActiveBg: "#4a67b5",
                colorIcon: "white",
                colorText: "white",
                colorTextPlaceholder: "white",
                colorBgElevated: "#3e5594",
                colorBorder: "#3e5594",
              },
            },
          }}
        >
          {!props.sideBarCollapsed && companyOptions.length > 0 && IsNotNullOrUndefined(selectedCompanyName) && (
            <Select
              className="company-selection"
              options={companyOptions}
              onChange={setSelectedCompanyName}
              value={selectedCompanyName}
              disabled={selectionDisabled}
              suffixIcon={<DownOutlined style={{ color: "white" }} />}
            />
          )}
        </ConfigProvider>

        <Menu
          items={centerMenuItems}
          style={{ backgroundColor: "#4b66af", position: "fixed", ...centerMenuDynamicStyle, marginTop: "40px" }}
          onSelect={OnMenuItemSelect}
          selectedKeys={selectedApplicationKeys}
          className="center-menu"
        />
        <Menu
          items={buttonMenuItems}
          style={{ backgroundColor: "#4b66af", position: "fixed", ...bottomMenuDynamicStyle }}
          selectedKeys={[]}
        />
      </div>
    </>
  );
}

/**
 * @since: 2023-11-30
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { AuthContext } from "Utils/constants";
import { useContext } from "react";

export function useAuthContext() {
  const auth = useContext(AuthContext);
  return auth;
}

/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { DataEntryContext } from "Utils/constants";
import { useContext } from "react";

export function useDataEntryContext() {
  const dataEntry = useContext(DataEntryContext);
  return dataEntry;
}

/*
@since: 2023-4-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Card } from "antd";

export default function NavBarCard({ cardProps, children }) {
  return (
    <>
      <Card
        size="small"
        headStyle={{ textAlign: "center" }}
        bodyStyle={{ textAlign: "center", height: "100%" }}
        style={{ wordWrap: "break-word", height: "100%" }}
        {...cardProps}
      >
        {children}
      </Card>
    </>
  );
}

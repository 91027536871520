function DownloadExcelFileFromBytes(data, fileName) {
  var bytes = new Uint8Array(data);
  var blob = new Blob([bytes]);
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName + ".xlsx";
  link.click();
}

export { DownloadExcelFileFromBytes };

/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { OptionsInstance, OptionsResponse, SelectOption } from "Utils/types";
import { Col, Row } from "antd";
import { useEffect } from "react";
import { Options, UpdateSelectedGroupingAndCategory } from "../Utils/Options";
import { getDefaultCurrencyForBranch } from "../Utils/Utils";
import { CustomSelect } from "./CustomSelect";

interface SalesOptionsProps {
  categoriesOptions: SelectOption[];
  optionsInstance: Options | null;
  optionsLoading: boolean;
  salesOptions: OptionsResponse;
  selectedOptions: OptionsInstance;
  setCategoryOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>;
  setSelected: React.Dispatch<React.SetStateAction<OptionsInstance>>;
  tableLoading: boolean;
}

export function SalesOptions(props: SalesOptionsProps) {
  const { selectedCompanyName, companyBranchMapping } = useCompanyContext();

  const setCategoryOptions = props.setCategoryOptions;
  const optionsInstance = props.optionsInstance;
  const salesOptions = props.salesOptions;
  const categoriesOptions = props.categoriesOptions;
  const selectedOptions = props.selectedOptions;
  const setSelected = props.setSelected;
  const optionsLoading = props.optionsLoading;
  const tableLoading = props.tableLoading;

  useEffect(() => {
    if (salesOptions.Branch !== undefined) {
      const selectedBranch = salesOptions.Branch.find((branch) => branch.name === companyBranchMapping[selectedCompanyName!]);
      handleBranchChange(selectedBranch!.optionValue);
    }
  }, [selectedCompanyName]);

  const handleBranchChange = (selectedBranchID: string) => {
    const defaultCurrency = getDefaultCurrencyForBranch(selectedBranchID, salesOptions.Branch);
    const defaultCategory = optionsInstance!.GetDefaultBranchCategory(selectedBranchID);
    const newSelectedOptions: OptionsInstance = {
      ...selectedOptions,
      Branch: selectedBranchID,
      Currency: defaultCurrency!,
      Category: defaultCategory,
    };
    setCategoryOptions(optionsInstance!.GetBranchCategories(selectedBranchID));
    setSelected(newSelectedOptions);
    UpdateSelectedGroupingAndCategory(defaultCategory, newSelectedOptions, setSelected);
  };

  const handleCategoryChange = (selectedCategoryId: number | string) => {
    UpdateSelectedGroupingAndCategory(selectedCategoryId, selectedOptions, setSelected);
  };

  const commonSelectorProps = {
    optionsLoading: optionsLoading,
    setSelected: setSelected,
    selectedOptions: selectedOptions,
    tableLoading: tableLoading,
  };

  const colSpanProps = {
    xl: 5,
    lg: 6,
    md: 9,
    sm: 12,
    xs: 24,
  };

  return (
    <Row gutter={50} justify="center" align="middle" style={{ maxWidth: "100%", marginLeft: "0", marginRight: "0" }}>
      <Col {...colSpanProps}>
        <CustomSelect {...commonSelectorProps} options={salesOptions.Currency} optionName="Currency" />
      </Col>

      <Col {...colSpanProps}>
        <CustomSelect {...commonSelectorProps} options={salesOptions.Frequency} optionName="Frequency" />
      </Col>
      <Col {...colSpanProps}>
        <CustomSelect {...commonSelectorProps} options={categoriesOptions} optionName="Category" customHandler={handleCategoryChange} />
      </Col>
    </Row>
  );
}

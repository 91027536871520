/**
 * @since: 2024-1-19
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { FileExcelTwoTone } from "@ant-design/icons";
import { ToReadableDate } from "Utils/HumanReadable";
import { DownloadExcelFile } from "Utils/helpers";
import { AdditionalRecord, DashboardCompany } from "Utils/types";
import { Button, message } from "antd";
import { ExportAdditionalData } from "dataHandling/data-entry";
import { useState } from "react";

interface ExportButtonProps {
  additionalRecords: AdditionalRecord[];
  disabled: boolean;
  company: DashboardCompany;
  date: string;
}

export function ExportButton(props: ExportButtonProps) {
  const [loading, setLoading] = useState(false);
  function ExportData() {
    setLoading(true);
    ExportAdditionalData(props.additionalRecords)
      .then((additionalData) => {
        DownloadExcelFile(additionalData, `${props.company.name} - ${ToReadableDate(props.date)}`);
      })
      .catch((error) => {
        message.error("An error occurred while exporting the additional data.");
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  return (
    <Button
      onClick={ExportData}
      disabled={props.disabled || loading}
      loading={loading}
      icon={<FileExcelTwoTone />}
      style={{ height: "40px" }}
    >
      Save To Excel
    </Button>
  );
}

import { useParams } from "react-router-dom";
import { ReportParams } from "../utils/types";
import { ReportsPage } from "./ReportsPage";

export default function QuickbooksReports() {
  // Auth and permission
  const params = useParams();
  const defaultParams: ReportParams = { ...params };
  delete defaultParams["*"];

  return (
    <div>
      <ReportsPage defaultParams={defaultParams}></ReportsPage>
    </div>
  );
}

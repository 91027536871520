import { API_ROUTES } from "Utils/constants";
import axios from "axios";
import { axiosInstance } from "../components/AlgoBooks/config/axios";

const AuthenticateAccess = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(API_ROUTES.ALGOBOOKS_AUTH)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchReportOptions = () => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(API_ROUTES.ALGOBOOKS_REPORT_OPTIONS)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchCompanyEntities = (realmId) => {
  var parameters = {
    realm_id: realmId,
  };
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(API_ROUTES.ALGOBOOKS_COMPANY_ENTITIES, { params: parameters })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 *
 * @param {import("axios").AxiosInstance} axiosInstance
 * @param {Object} CancellerObj
 * @param {string} realm_id
 * @param {string} report
 * @param {string} date
 * @param {string} groupBy
 * @param {Array<Object>} listClassesObjects
 * @param {Array<Object>} listDepartmentsObjects
 * @param {Array<string>} currencies
 * @returns Report data
 * Queries general report from backend
 */
const QueryReport = (
  axiosInstance,
  CancellerObj,
  realm_id,
  report,
  date,
  groupBy,
  listDepartmentsObjects,
  listClassesObjects,
  currencies
) => {
  let form = new FormData();
  form.append("realm_id", realm_id);
  form.append("report", report);
  form.append("date", date);
  form.append("group_by", groupBy);
  if (listDepartmentsObjects) form.append("department", JSON.stringify(listDepartmentsObjects));
  if (listClassesObjects) form.append("class", JSON.stringify(listClassesObjects));
  form.append("currencies", currencies);

  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_REPORT, form, {
        cancelToken: new axios.CancelToken((canceler) => {
          CancellerObj.CancelRequest = canceler;
        }),
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetDepartments = (realm_id) => {
  let form = new FormData();
  form.append("realm_id", realm_id);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_DEPARTMENTS, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetClass = (realm_id) => {
  let form = new FormData();
  form.append("realm_id", realm_id);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_CLASSES, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
const GetExcel = (data, cols) => {
  let form = new FormData();
  form.append("data", data);
  form.append("columns", cols);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_EXCEL_REPORT, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const GetPDF = (data, cols, companyName, reportType, currentDate) => {
  let form = new FormData();
  form.append("data", data);
  form.append("columns", cols);
  form.append("companyName", companyName);
  form.append("reportType", reportType);
  form.append("currentDate", currentDate);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_PDF_REPORT, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const StartReportGeneration = (realmId) => {
  let form = new FormData();
  form.append("realmId", realmId);
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(API_ROUTES.ALGOBOOKS_START_REPORT_GENERATION, form)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const FetchReportGenerationStatus = (realmId) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .get(API_ROUTES.ALGOBOOKS_REPORT_GENERATION_STATUS, {
        params: {
          realmId,
        },
      })
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { AuthenticateAccess, FetchCompanyEntities, FetchReportOptions, GetClass, GetDepartments, GetExcel, GetPDF, QueryReport };

/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { OptionsInstance, SalesTableRow, TableDataType } from "Utils/types";
import { Table, TablePaginationConfig, TableProps } from "antd";
import { useEffect, useState } from "react";

interface SalesTableComponentProps {
  isLoading: boolean;
  data: TableDataType;
  selectedOptions: OptionsInstance;
}

export function SalesTableComponent(props: SalesTableComponentProps) {
  const isLoading = props.isLoading;
  const tableData = props.data;
  const selectedOptions = props.selectedOptions;

  const [currentPage, setCurrentPage] = useState(1);

  const paginationSettings: TablePaginationConfig = {
    total: tableData?.dataSource?.length || 0,
    hideOnSinglePage: true,
    size: "small",
    showSizeChanger: true,
    defaultPageSize: 20,
    current: currentPage,
    onChange: (page: number, pageSize: number) => {
      setCurrentPage(page);
    },
  };
  const scrollSettings: TableProps<SalesTableRow>["scroll"] = {
    scrollToFirstRowOnChange: false,
    x: true,
  };

  // Set first page position on selection change
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedOptions]);

  // TODO : for mobile calculate responsive number of rows on first render and stick to it

  return (
    <>
      <Table
        bordered
        columns={tableData.columns}
        dataSource={tableData.dataSource}
        loading={isLoading}
        pagination={paginationSettings}
        scroll={scrollSettings}
        style={{ cursor: "default" }}
      ></Table>
    </>
  );
}

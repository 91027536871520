/**
 * @since: 2023-11-23
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import { WarningTwoTone } from "@ant-design/icons";
import "components/Shared/Styles/styles.css";
import { Link } from "react-router-dom";
export function ErrorPage() {
  return (
    <>
      <div className="container-center">
        <WarningTwoTone twoToneColor="#FF0000" style={{ fontSize: "7em", display: "block", margin: "30px" }} />
        <span style={{ fontSize: "1.5em", cursor: "default" }}>
          Sorry, an unexpected error has occurred.{" "}
          <Link to="/" style={{ display: "block" }} reloadDocument>
            Go back home.
          </Link>
        </span>
      </div>
    </>
  );
}

/**
 * @since: 2023-12-19
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { Location } from "react-router-dom";
import { ROUTE_TO_APP_NAME } from "./constants";

export function GetActiveApplicationFromUrl(location: Location): string {
  return location.pathname.split("/")[1];
}

export function IsAppRoute(appRoute: string): boolean {
  return appRoute !== undefined && appRoute in ROUTE_TO_APP_NAME;
}


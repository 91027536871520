// CSS
// Util Components
import { Alert, Divider, message, Row, Table } from "antd";
// import "antd/dist/antd.min.css";
import "./../../AlgoBooks.scss";
// Hooks
import { useEffect, useState } from "react";
// API
import { QueryReport } from "../../../../dataHandling/algobooks";
// Util Functions
import {
  AreRowsLimited,
  ContainsExternalUrls,
  ConvertToEntityObjects,
  ExtractNodeText,
  GenerateColumns,
  GenerateReportRows,
  GenerateRowStyles,
  GetRowLevel,
  HideZeroRows,
  RemoveEmptyChildrenProperty,
  URLisBase,
} from "../../utils/ComponentUtils/ReportTable/utils";
// Main Components
import { reportFetcher } from "../../config/axios";
// import reportJson from "../../dump/report.json";
import axios, { AxiosInstance } from "axios";
import { RETRY_PROMPT_DELAY_MILLISECONDS } from "../../utils/ComponentUtils/ReportTable/constants";
import {
  CancellerType,
  DetailParameters,
  ReportColumn,
  ReportData,
  ReportRow,
  ReportStateParams,
  ReportTableProps,
  RowStyles,
} from "../../utils/types";
import CompanyFooter from "./CompanyFooter";
import DetailView from "./DetailView";
import ExternalUrlsAlert from "./ExternalUrlsAlert";
import ReportToolbar from "./ReportToolbar";
import ReportValidations from "./ReportValidations";
import RetryPrompt from "./RetryPrompt";

/**
 * Contains request cancelling function to abort previous report queries
 */
let Canceller: CancellerType = {
  CancelRequest: null,
};

/**
 * Contains all incomplete timeouts
 */
let runningTimers: NodeJS.Timeout[] = [];

export default function ReportTable(props: ReportTableProps) {
  // Props
  const classes = props.classes;
  const company = props.company;
  const companyNote = props.companyNote;
  const companyOptions = props.companyOptions;
  const currency = props.currency;
  const currencyOptions = props.currencyOptions;
  const departments = props.departments;
  const disableSelector = props.disableSelector;
  const forceLoading = props.forceLoading;
  const grouping = props.grouping;
  const href = props.href;
  const isCurrencyReport = props.isCurrencyReport;
  const loadedParams = props.loadedParams;
  const loading = props.loading;
  const period = props.period;
  const prevQueryCount = props.prevQueryCount;
  const queryCount = props.queryCount;
  const reportType = props.report;
  const reportOptions = props.reportOptions;
  const setLoading = props.setLoading;
  const setPrevQueryCount = props.setPrevQueryCount;
  const setQueryCount = props.setQueryCount;
  const setValidParameters = props.setValidParameters;
  const socket = props.socket;
  const view = props.view;
  const hideRows = props.hideRows;

  // Local state
  const [reportData, setReportData] = useState<ReportData>(null);
  const [cachedReportParameters, setCachedReportParameters] = useState<ReportStateParams>(null);
  const [expanded, setExpanded] = useState<string[]>([]);
  const [columns, setColumns] = useState<ReportColumn[]>([]);
  const [tableRows, setTableRows] = useState<ReportRow[]>([]);
  const [originalTableRows, setOriginalTableRows] = useState<ReportRow[]>([]);
  const [rowStyles, setRowStyles] = useState<RowStyles>({});
  const [pdfError, setPDFError] = useState<boolean>(false);
  const [pdfLoading, setPdfLoading] = useState<boolean>(false);
  const [detailParams, setDetailParams] = useState<DetailParameters>({
    title: "",
    currency: {},
    href: "",
  });
  const [retryPromptIsVisible, setRetryPromptIsVisible] = useState<boolean>(false);

  const [popUp, setPopUp] = useState<boolean>(false);

  // Socket management
  const SocketHandler = (socket: WebSocket) => {
    socket.onmessage = function (e) {
      var message = JSON.parse(e.data);
      if (message.messageType === view && message.status === "OK") {
        let reportDataResponse = message.report;
        RemoveEmptyChildrenProperty(reportDataResponse.Rows);
        setReportData(reportDataResponse);
        setRowStyles(GenerateRowStyles(reportDataResponse.Rows, reportType));
        setTableData(reportDataResponse);
        setLoading(false);
      }
    };
  };

  useEffect(() => {
    // Trigger timer for when report loading takes too long
    const loadingTimerInterceptorId = reportFetcher.interceptors.request.use(
      (requestConfig) => {
        StartNewReportLoadingTimer();
        // https://github.com/svrcekmichal/redux-axios-middleware/issues/83#issuecomment-407466397
        return requestConfig;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    // Clean up on component dismount
    return () => {
      ClearAllReportLoadingTimers(true);
      // CancelPendingReportRequest();
      reportFetcher.interceptors.request.eject(loadingTimerInterceptorId);
    };
  }, []);

  // Trigger when socket prop passed/changed, constructs socket handler
  useEffect(() => {
    if (socket !== null) {
      SocketHandler(socket);
    }
    // eslint-disable-next-line
  }, [socket]);

  // Spam Prevention
  useEffect(() => {
    if (view === "General") {
      disableSelector(loading === true || forceLoading === true);
    }
    // eslint-disable-next-line
  }, [loading, forceLoading]);

  const createEndPeriodByColKeyObject = (columns: ReportColumn[]): { [key: string]: string } => {
    var validationDateByColKey: { [key: string]: string } = {};
    columns.forEach((col: ReportColumn) => {
      if ("endPeriod" in col) {
        validationDateByColKey[col.key] = col.endPeriod;
      }
    });
    return validationDateByColKey;
  };

  // Changes class at column value level
  const handleHeaderHighlightedValues = (record, index) => {
    var endPeriodByColumnKey = {};
    var lastValidationDate: string | null = null;
    if (reportData !== undefined && "Validations" in reportData && "created" in reportData["Validations"] && columns) {
      endPeriodByColumnKey = createEndPeriodByColKeyObject(columns);
      lastValidationDate = reportData["Validations"]["created"];
    }

    var colKeys = Object.keys(record).filter((key) => key.includes("COL"));
    if (expanded.includes(record.key) && "children" in record && record.children.length > 0) {
      colKeys.forEach((key) => {
        if (!record[key].class.includes("total-value")) record[key].class.push("total-value");
      });
    } else if (!expanded.includes(record.key) && "children" in record && record.children.length > 0) {
      colKeys.forEach((key) => {
        var index = record[key].class.indexOf("total-value");
        if (index > -1) {
          record[key].class.splice(index, 1);
        }
      });
    }

    if (view === "General") {
      colKeys.forEach((key) => {
        if (key !== "COL1") {
          var recordClasses = record[key].class.filter((className) => {
            return className !== "unvalidated";
          });
          if (!lastValidationDate) {
            record[key].class = recordClasses.concat(["unvalidated"]);
          }
          if (key in endPeriodByColumnKey && endPeriodByColumnKey[key] > lastValidationDate) {
            record[key].class = recordClasses.concat(["unvalidated"]);
          }
        }
      });
    }
  };

  // Changes class at row level
  const handleHighlightedHeaderRows = (record: ReportRow, index: number) => {
    var classes = "";
    var levelNumber = GetRowLevel(record.key, tableRows);
    classes = `level${levelNumber}`;
    if (record.key in rowStyles) {
      rowStyles[record.key].forEach((style) => {
        classes += " " + style;
      });
    }
    return classes;
  };

  const handleRowExpand = (shouldExpand: boolean, row: ReportRow) => {
    var newExpandedRows = [];
    if (shouldExpand) {
      // Expand
      newExpandedRows = [...expanded, row.key];
    } else {
      // Collapse
      newExpandedRows = [...expanded];
      let index = newExpandedRows.indexOf(row.key);
      newExpandedRows.splice(index, 1);
    }
    setExpanded(newExpandedRows);
  };

  /**
   * Handle report column setting, formats data and modified columns state
   * @param queriedReportData
   */
  const setTableData = (queriedReportData: ReportData) => {
    try {
      if (queriedReportData) {
        setLoading(true);
        let [newColumns, newUniqueColumns] = GenerateColumns(queriedReportData.Columns, props, setDetailParams, setPopUp);

        let newReportRows = queriedReportData.Rows;
        if (isCurrencyReport) {
          console.debug("Currency report detected, applying currency multiplier to report...");
          newReportRows = GenerateReportRows(queriedReportData.Rows, queriedReportData.Columns, newColumns, currencyOptions);
        }
        setOriginalTableRows([...newReportRows]);
        // if hde rows do the hiding here
        if (hideRows) {
          newReportRows = HideZeroRows(newReportRows);
        }
        setTableRows(newReportRows);

        var listColumns: ReportColumn[] = [];
        // Exclude all total columns if the table is in general view
        if (view === "General") {
          newUniqueColumns.forEach((column: ReportColumn) => {
            // !column.title.toUpperCase().includes("PERCENT")
            const columnTitle = ExtractNodeText(column.title);
            if (!columnTitle.toUpperCase().includes("TOTAL")) {
              listColumns.push(column);
            }
          });
          newUniqueColumns = listColumns;
        }
        setColumns(newUniqueColumns);
        setLoading(false);
      }
    } catch (error) {
      message.error("Displaying table data failed, please contact AlgoTraders.");
    }
  };

  const showZeroRows = () => {
    setTableRows(originalTableRows);
  };

  const hideZeroRows = () => {
    const transformedRows = HideZeroRows(originalTableRows);
    setTableRows(transformedRows);
  };

  useEffect(() => {
    if (hideRows) hideZeroRows();
    else showZeroRows();
  }, [hideRows]);

  /**
   * Stores current report parameters into state
   */
  const cacheQueriedReportParams = () => {
    var reportParams: ReportStateParams = {};
    reportParams.report = reportType;
    reportParams.company = company;
    reportParams.grouping = grouping;
    reportParams.period = period;
    reportParams.departments = departments;
    reportParams.classes = classes;
    reportParams.currency = currency;
    reportParams.companyNote = companyNote;
    setCachedReportParameters(reportParams);
  };

  /**
   *
   * Checks if currently set report parameters are equivalent to the cached ones
   */
  const areReportParamsCached = (): boolean => {
    return (
      company === cachedReportParameters.company &&
      reportType === cachedReportParameters.report &&
      grouping === cachedReportParameters.grouping &&
      period === cachedReportParameters.period &&
      departments.toString() === cachedReportParameters.departments.toString() &&
      classes.toString() === cachedReportParameters.classes.toString() &&
      currency.toString() === cachedReportParameters.currency.toString()
    );
  };

  // Fetch a new report once the component is activated, or parameters change
  useEffect(() => {
    setLoading(true);

    // Socket exists
    if (socket !== null && forceLoading === false) {
      // stop default report load on page load
      if (!URLisBase() || (URLisBase() && queryCount != prevQueryCount)) {
        RequestReport(reportFetcher);
      } else {
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, [forceLoading, queryCount, view]);

  /**
   *
   * @param {import("axios").AxiosInstance} reportFetcher
   * Requests report from backend based on view type (General/Detail)
   */
  function RequestReport(reportFetcher: AxiosInstance) {
    if (view === "General") {
      // Detected currency change
      // Does this block run only on currency change?
      if (cachedReportParameters !== null && areReportParamsCached() && false) {
        // The false is a temp change, TODO: remove the whole block
        setTableData(reportData);
      }
      // Detected report parameters change
      else {
        console.log("requesting report block");
        const listClassesObjects = ConvertToEntityObjects(classes, "classes", company, companyOptions);
        const listDepartmentsObjects = ConvertToEntityObjects(departments, "departments", company, companyOptions);
        const currencies = currency.selectedCurrencies.toString();
        QueryReport(reportFetcher, Canceller, company, reportType, period, grouping, listDepartmentsObjects, listClassesObjects, currencies)
          .then((responseReportData: ReportData) => {
            // DEV BLOCK
            // RemoveEmptyChildrenProperty(reportJson.Rows);
            // setReportData(reportJson);
            // setRowStyles(GenerateRowStyles(reportJson.Rows, reportType));
            // setTableData(reportJson);
            // ----------
            ClearAllReportLoadingTimers(true);
            RemoveEmptyChildrenProperty(responseReportData.Rows);
            setReportData(responseReportData);
            setRowStyles(GenerateRowStyles(responseReportData.Rows, reportType));
            setTableData(responseReportData);
            // ----------
            setLoading(false);
            cacheQueriedReportParams();
          })
          .catch((err) => {
            if (axios.isCancel(err)) {
              console.log("Previous report query cancelled.");
            } else {
              console.error(err);
              message.error("Report query has failed.");
            }
          });
      }
      setValidParameters(true);
    } else {
      // Details table, fetching report data through socket
      socket.send(
        JSON.stringify({
          realm_id: company,
          report: reportType,
          href: href.toString(),
          type: "Detail",
          currency: currency.selectedCurrencies[0], // if rendering the detail reports, the selected currencies value will belong to one currency only
        })
      );
    }

    ClearAllReportLoadingTimers(true);
  }

  function StartNewReportLoadingTimer() {
    ClearAllReportLoadingTimers(true);
    const timeoutId = setTimeout(() => {
      setRetryPromptIsVisible(true);
    }, RETRY_PROMPT_DELAY_MILLISECONDS);
    runningTimers.push(timeoutId);
  }
  /**
   *
   * @param {bool} hidePrompt
   */
  function ClearAllReportLoadingTimers(hidePrompt: boolean) {
    while (runningTimers.length > 0) {
      const runningTimerId = runningTimers.pop();
      clearTimeout(runningTimerId);
    }
    if (hidePrompt) {
      setRetryPromptIsVisible(false);
    }
  }

  function CancelPendingReportRequest() {
    // Cancelling pending requests
    if (Canceller.CancelRequest !== undefined && Canceller.CancelRequest !== null) {
      Canceller.CancelRequest();
    }
  }

  return (
    <div>
      {view === "Detail" && AreRowsLimited(reportData) && (
        <Alert type="error" showIcon={true} closable={true} message="Unable to display all data. Please reduce the date range." />
      )}
      {!pdfError && pdfLoading && <Alert type="info" message="Generating the PDF report. This might take a while.." />}
      {pdfError && !loading && (
        <Alert
          type="error"
          showIcon={true}
          closable={true}
          message="Sorry, an error occurred. Try selecting less columns, or report the issue to support@algotraders.ai."
        />
      )}
      {loading && retryPromptIsVisible && (
        <RetryPrompt
          ClearReportLoadingTimers={ClearAllReportLoadingTimers}
          setPrevQueryCount={setPrevQueryCount}
          setQueryCount={setQueryCount}
          queryCount={queryCount}
          CancelPendingRequest={CancelPendingReportRequest}
        />
      )}
      <Row justify="start">
        <ReportToolbar
          columns={columns}
          company={company}
          companyOptions={companyOptions}
          disabled={forceLoading || loading}
          frequency={grouping}
          loadedParams={loadedParams}
          pdfLoading={pdfLoading}
          report={reportType}
          reportOptions={reportOptions}
          rows={tableRows}
          setExpandedKeys={setExpanded}
          setPDFError={setPDFError}
          setPdfLoading={setPdfLoading}
          view={view}
        />
      </Row>
      {!loading ? (
        <Table
          align="right"
          size="small"
          columns={columns}
          dataSource={tableRows}
          expandable={{
            expandedRowKeys: expanded,
            defaultExpandAllRows: false,
            onExpand: handleRowExpand,
          }}
          rowClassName={handleHighlightedHeaderRows}
          onRow={handleHeaderHighlightedValues}
          pagination={{
            total: tableRows.length,
            pageSize: tableRows.length,
            hideOnSinglePage: true,
          }}
          loading={loading || forceLoading || socket === null}
          scroll={{ x: true, y: "70vh" }}
          style={view === "General" ? {} : { maxWidth: "90vw" }}
          className="report-table"
        ></Table>
      ) : (
        <Table loading={true}></Table>
      )}

      {view === "General" && popUp ? (
        <DetailView
          loadedParams={loadedParams}
          companyOptions={companyOptions}
          reportOptions={reportOptions}
          props={props}
          params={detailParams}
          popUp={popUp}
          setPopUp={setPopUp}
          socket={socket}
          currencyOptions={currencyOptions}
        />
      ) : (
        <></>
      )}

      {ContainsExternalUrls(reportData) && <ExternalUrlsAlert reportData={reportData} />}

      {reportData && "Validations" in reportData && "Version" in reportData && !loading && !forceLoading ? (
        <>
          <ReportValidations
            reportValidations={reportData["Validations"]}
            reportWarnings={reportData["Warnings"]}
            reportErrors={reportData["Errors"]}
            version={reportData["Version"]}
            reportType={cachedReportParameters?.report}
          ></ReportValidations>
          <Divider />
          <CompanyFooter companyNote={cachedReportParameters?.companyNote} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

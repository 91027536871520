/**
 * @since: 2024-1-4
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { DataEntryContext, IMAGES } from "Utils/constants";
import { IsNotNullOrUndefined, ListToObjectByProperty } from "Utils/helpers";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { CompaniesByRealmId, DashboardCompany, DataEntryLoadingStates, DataEntryParams, DataTypeRecord } from "Utils/types";
import { Layout, Row, message } from "antd";
import { Loading } from "components/AlgoBooks/components/Loading";
import TopBar from "components/AlgoBooks/components/TopBar/TopBar";
import { RedirectToDefaultPage } from "components/FoodicsTool/Components/Utils/RoutesUtil";
import { ErrorPage } from "components/Shared/ErrorPage/ErrorPage";
import { FetchAvailableDataTypes } from "dataHandling/data-entry";
import { useEffect, useMemo, useState } from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import { AdditionalData } from "./AdditionalData/AdditionalData";
import { DataEntryLayout } from "./DataEntryLayout/DataEntryLayout";

/**
 * TODO: Navigation logic must be refactored once more datatypes are added (validating company/data type pairs...)
 */

interface DataEntryProps {
  navigateCompanyId: (company: DashboardCompany) => void;
}

export function DataEntry(props: DataEntryProps) {
  const { Content } = Layout;
  const { setSelectedCompanyName, companies, selectedCompanyName } = useCompanyContext();
  const [currDataEntryCompany, setDataEntryCompany] = useState<DashboardCompany | null>(null);
  const [dataTypes, setDataTypes] = useState<DataTypeRecord[]>([]);
  const [currentDataType, setCurrentDataType] = useState<string>();
  const [disabledEditSwitch, setDisabledEditSwitch] = useState<boolean>(false);
  const [loading, setLoading] = useState<DataEntryLoadingStates>({
    dataTypesLoading: true,
  });
  const [editMode, setEditMode] = useState<boolean>(false);
  const params = useParams<DataEntryParams>();
  const navigate = useNavigate();

  const companiesByRealmId: CompaniesByRealmId = useMemo(() => ListToObjectByProperty(companies, "quickbooksId"), [companies]);
  const availableDataTypesNames = useMemo(() => dataTypes.map((dataType) => dataType.name.toLowerCase()), [dataTypes]);

  // On company selection
  useEffect(() => {
    const selectedCompanyObj = companies.find((company) => company.name === selectedCompanyName);
    SetCompany(selectedCompanyObj!);
  }, [selectedCompanyName]);

  // On load
  useEffect(() => {
    if (IsNotNullOrUndefined(params.companyId)) {
      const selectedCompanyObj = companies.find((company) => company.quickbooksId === params.companyId);
      if (IsNotNullOrUndefined(selectedCompanyObj)) SetCompany(selectedCompanyObj!);
      else navigate("/");
    }
  }, []);

  // Fetch metadata for selected data entry company, navigate on company change
  useEffect(() => {
    setLoading({ ...loading, dataTypesLoading: true });
    if (IsNotNullOrUndefined(currDataEntryCompany)) {
      SetCompany(currDataEntryCompany!);
      props.navigateCompanyId(currDataEntryCompany!);
      FetchAvailableDataTypes(currDataEntryCompany!.quickbooksId)
        .then((response) => {
          setDataTypes(response.availableDataTypes);
        })
        .catch((error) => {
          message.error(`Error occurred while fetching data types for ${currDataEntryCompany!.name}`);
          console.error(error);
        })
        .finally(() => {
          setLoading({ ...loading, dataTypesLoading: false });
        });
    }
  }, [currDataEntryCompany]);

  function SetCompany(company: DashboardCompany): void {
    setSelectedCompanyName(company.name);
    setDataEntryCompany(company);
  }
  return (
    <>
      <DataEntryContext.Provider
        value={{
          company: currDataEntryCompany!,
          companiesByRealmId,
          editMode,
          setEditMode,
          disabledEditSwitch,
          setDisabledEditSwitch,
          setCurrentDataType,
        }}
      >
        <Layout style={{ height: "100%" }}>
          <TopBar logoUrl={IMAGES.DATAENTRY_LOGO} siteName="Data Entry" />
          {loading.dataTypesLoading ? (
            <div>
              <Row justify="center" align="middle" style={{ height: "50vh", marginTop: "2vh" }}>
                <Loading tip="Loading data..." />
              </Row>
            </div>
          ) : (
            <Layout>
              <Content>
                <div
                  style={{
                    paddingTop: "20px",
                    paddingBottom: "20px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    height: "100%",
                  }}
                >
                  <Routes>
                    <Route
                      element={
                        <DataEntryLayout
                          dataTypes={dataTypes}
                          currentDataType={currentDataType}
                          loading={loading}
                          companyName={currDataEntryCompany!.name}
                        />
                      }
                    >
                      {dataTypes.length === 0 ? (
                        <Route path="" element={<></>} />
                      ) : (
                        <Route path="" element={<RedirectToDefaultPage defaultRoute={availableDataTypesNames[0]} />} />
                      )}
                      {availableDataTypesNames.includes("additional") && <Route path="additional" element={<AdditionalData />} />}
                    </Route>
                    <Route path="*" element={<ErrorPage />} />
                  </Routes>
                </div>
              </Content>
            </Layout>
          )}
        </Layout>
      </DataEntryContext.Provider>
    </>
  );
}

import { API_ROUTES } from "Utils/constants";
import { RecipeDataType, RecipeItems, RecipeSearchRecord } from "Utils/types";
import { AxiosResponse } from "axios";
import { axiosCustom } from "../components/RecipeBook/Lib/Axios";

const GetRecipe = (branch: string, recipeId: string) => {
  let form = new FormData();
  form.append("recipeId", recipeId);
  form.append("branch", branch);
  return new Promise<AxiosResponse<RecipeDataType>>((resolve, reject) => {
    axiosCustom
      .post<AxiosResponse<RecipeDataType>>(API_ROUTES.RECIPEBOOK_RECIPE, form)
      .then((res) => {
        let result = res;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const ListCategories = (branch: string) => {
  return new Promise<AxiosResponse<string[]>>((resolve, reject) => {
    let values = {
      params: {
        branch: branch,
      },
    };
    axiosCustom
      .get<AxiosResponse<string[]>>(API_ROUTES.RECIPEBOOK_LIST_CATEGORIES, values)
      .then((res) => {
        let result = res;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchMainBranches = () => {
  return new Promise<string[]>((resolve, reject) => {
    axiosCustom
      .get<string[]>(API_ROUTES.RECIPEBOOK_MAIN_BRANCH)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const FetchIngredients = (branch: string) => {
  return new Promise<RecipeItems>((resolve, reject) => {
    let values = {
      params: {
        branch: branch,
      },
    };
    axiosCustom
      .get<RecipeItems>(API_ROUTES.RECIPEBOOK_LIST_INGREDIENTS, values)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};



const FetchRecipeFromItem = (itemSku: string, branch: string) => {
  return new Promise<QueriedRecipes>((resolve, reject) => {
    let values = {
      params: {
        itemsku: itemSku,
        branch: branch,
      },
    };
    axiosCustom
      .get(API_ROUTES.RECIPEBOOK_RECIPE_BY_ITEM, values)
      .then<QueriedRecipes>((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface FetchDownloaderStatusResponse {
  branch: string;
  status: string;
}

const FetchDownloaderStatus = (branchName: string) => {
  return new Promise<FetchDownloaderStatusResponse>((resolve, reject) => {
    let values = {
      params: {
        branchName: branchName,
      },
    };
    axiosCustom
      .get<FetchDownloaderStatusResponse>(API_ROUTES.RECIPEBOOK_STATUS, values)
      .then((res) => {
        let result = res.data;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

interface StartDownloaderResponse {
  message: string;
}
const StartDownloader = (branchName: string, userEmail: string) => {
  let form = new FormData();
  form.append("branchName", branchName);
  form.append("userEmail", userEmail);
  return new Promise<StartDownloaderResponse>((resolve, reject) => {
    axiosCustom
      .post<StartDownloaderResponse>(API_ROUTES.RECIPEBOOK_START_DOWNLOADER, form)
      .then((res) => {
        let result = res;
        resolve(result);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { FetchDownloaderStatus, FetchIngredients, FetchMainBranches, FetchRecipeFromItem, GetRecipe, ListCategories, StartDownloader };

import { Layout } from "antd";
// import "antd/dist/antd.min.css";
import { IMAGES } from "Utils/constants";
import { useAuthContext } from "Utils/hooks/useAuthContext";
import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { Loading } from "components/AlgoBooks/components/Loading";
import TopBar from "components/AlgoBooks/components/TopBar/TopBar";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { DownloaderButton } from "./Components/DownloaderButton";
import RecipeViewer from "./Components/RecipeViewer/RecipeViewer";
import "./RecipeBookApp.scss";
import "./RecipeBookRoot.scss";

function RecipeBookApp() {
  const { Content } = Layout;

  // Context
  const { isAuthenticated, isLoading } = useAuthContext();
  const { setSelectedCompanyName, companyBranchMapping, selectedCompanyName } = useCompanyContext();

  // Local
  const [mainBranch, setMainBranch] = useState<string | null>(null);
  const [mainBranches, setMainBranches] = useState<string[]>([]);

  const branchCompanyMapping = _.invert(companyBranchMapping);

  useEffect(() => {
    if (!!selectedCompanyName) {
      setCompany(companyBranchMapping[selectedCompanyName]);
    }
  }, [selectedCompanyName]);

  function setCompany(branchName: string) {
    setMainBranch(branchName);
    setSelectedCompanyName(branchCompanyMapping[branchName]);
  }
  if (isLoading) {
    return <Loading tip="Logging In"></Loading>;
  } else if (isAuthenticated) {
    return (
      <Layout className="recipe-book">
        <TopBar
          logoUrl={IMAGES.RECIPEBOOK_LOGO}
          siteName="Recipe Book"
          rightSidePanelChildren={<DownloaderButton loadingMainBranches={mainBranches.length === 0} mainBranch={mainBranch} />}
        />

        <Content style={{ padding: "20px" }}>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <RecipeViewer
                  mainBranch={mainBranch}
                  setCompany={setCompany}
                  mainBranches={mainBranches}
                  setMainBranches={setMainBranches}
                />
              }
            ></Route>
          </Routes>
        </Content>
      </Layout>
    );
  } else {
    return <></>;
  }
}

export default RecipeBookApp;

/*
@since: 2022-7-28
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/
import { IMAGES } from "Utils/constants";
import { Layout } from "antd";
import { Chart } from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
import TopBar from "components/AlgoBooks/components/TopBar/TopBar";
import { CSSProperties } from "react";
import { Route, Routes } from "react-router-dom";
import "./CantinaSalesApp.scss";
import { APP_NAME } from "./Utils/constants";
import "./index.css";
import { RestrictedPage } from "./views/RestrictedPage";
import { SalesPage } from "./views/SalesPage";

Chart.register(annotationPlugin);

function CantinaSalesApp() {
  const { Content } = Layout;

  const layoutStyle: CSSProperties = { minHeight: "100vh", minWidth: "100%" };
  const contentStyle: CSSProperties = {
    width: "100%",
    height: "100%",
  };

  return (
    <Layout style={layoutStyle} className="cantina-sales App">
      <Content style={contentStyle}>
        <TopBar siteName={APP_NAME} logoUrl={IMAGES.CANTINASALES_LOGO} />
        <Routes>
          <Route path="" element={<SalesPage />} />
          <Route path="denied" element={<RestrictedPage />} />
        </Routes>
      </Content>
    </Layout>
  );
}

export default CantinaSalesApp;

/**
 * @since: 2023-12-6
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { Button } from "antd";
import { Link } from "react-router-dom";
import "./LoginPage.scss";

interface LoginPageProps {
  signIn: () => void;
}

export function LoginPage(props: LoginPageProps) {
  return (
    <div className="login-page">
      <div className="login-button-container">
        <span className="login-modal-title">AlgoAdvisory</span>
        <Button onClick={props.signIn} className="login-button">
          Login
        </Button>
        <Link to="mailto:support@algoadvisory.ai?subject=AlgoAdvisory%20Dashboard%20Login%20Support" className="link">
          Contact support
        </Link>
      </div>
    </div>
  );
}

/**
 * @since: 2023-11-24
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */
import { fireBaseAuth } from "auth";
import { axiosInstance as AlgoBooksAxios, reportFetcher as AlgoBooksReportAxios } from "components/AlgoBooks/config/axios";
import { axiosCustom as CantinaSalesAxios } from "components/CantinaSales/config/axios";
import { axiosInstance as FoodicsToolAxios } from "components/FoodicsTool/Lib/Axios";
import { axiosCustom as RecipeBookAxios } from "components/RecipeBook/Lib/Axios";
import { axiosInstance as DashboardAxios } from "dataHandling/axios";
import { signOut } from "firebase/auth";
import { createContext } from "react";
import { AuthContextType, CompanyContextType, DataEntryContextType } from "./types";

export const ROUTE_TO_APP_NAME: { [appRoute: string]: string } = {
  algobooks: "AlgoBooks",
  foodicsinventory: "FoodicsInventory",
  foodicspricing: "FoodicsPricing",
  sales: "CantinaSales",
  recipebook: "RecipeBook",
  dataentry: "DataEntry",
};

export const IMAGES = {
  ALGOBOOKS_LOGO: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/AlgoBooks2.png",
  FOODICSTOOL_LOGO: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/FoodicsTool.png",
  RECIPEBOOK_LOGO: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/RecipeBook.png",
  CANTINASALES_LOGO: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/CantinaSales-modified.png",
  DATAENTRY_LOGO: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/DataEntryLogo.png",
  SUPPORT_MESSAGE_ICON: "https://storage.googleapis.com/www.algoadvisory.ai/Icons/3rd%20Party/message-icon.png",
};

export const APP_AXIOS_INSTANCES = [AlgoBooksAxios, CantinaSalesAxios, FoodicsToolAxios, RecipeBookAxios, AlgoBooksReportAxios];
export const ALL_AXIOS_INSTANCES = [...APP_AXIOS_INSTANCES, DashboardAxios];

export const BAD_SERVER_RESPONSE = {
  NO_APP_ACCESS: { detail: "User does not have access to application." },
  USER_UNREGISTERED: { detail: "User not whitelisted in database." },
};

export const API_ROUTES = {
  DASHBOARD_APP_PERMISSIONS: "/dashboardcore/app-permissions/",
  DASHBOARD_MESSAGE_SUPPORT: "/dashboardcore/message-support/",
  DASHBOARD_LOG_ERROR: "/dashboardcore/log-error/",
  ALGOBOOKS_AUTH: "/algobooks/auth/",
  ALGOBOOKS_REPORT_OPTIONS: "/algobooks/reportOptions/",
  ALGOBOOKS_COMPANY_ENTITIES: "/algobooks/companyEntities/",
  ALGOBOOKS_REPORT: "/algobooks/report/",
  ALGOBOOKS_DEPARTMENTS: "/algobooks/departments/",
  ALGOBOOKS_CLASSES: "/algobooks/classes/",
  ALGOBOOKS_EXCEL_REPORT: "/algobooks/excelReport/",
  ALGOBOOKS_PDF_REPORT: "/algobooks/pdfReport/",
  ALGOBOOKS_USER: "/algobooks/user/",
  ALGOBOOKS_START_REPORT_GENERATION: "/algobooks/start-report-generation/",
  ALGOBOOKS_REPORT_GENERATION_STATUS: "/algobooks/report-generation-status/",
  CANTINASALES_OPTIONS: "/cantinasales/options/?",
  CANTINASALES_SALES: "/cantinasales/sales/",
  FOODICSINVENTORY_BRANCHES: "/foodicsinventory/Branches/",
  FOODICSINVENTORY_SUBBRANCHES: "/foodicsinventory/SubBranches/",
  FOODICSINVENTORY_GET_INVENTORY_COUNTS: "/foodicsinventory/GetInventoryCounts/",
  FOODICSINVENTORY_INVENTORY_COUNT_TEMPLATE: "/foodicsinventory/InventoryCountTemplate/",
  FOODICSINVENTORY_DELETE_INVENTORY_COUNT: "/foodicsinventory/DeleteInventoryCount/",
  FOODICSINVENTORY_CONFIRM_INVENTORY_COUNT: "/foodicsinventory/ConfirmInventoryCount/",
  FOODICSINVENTORY_GET_INVENTORY_COUNT: "/foodicsinventory/GetInventoryCount/",
  FOODICSINVENTORY_CREATE_INVENTORY_COUNT: "/foodicsinventory/CreateInventoryCount/",
  FOODICSPRICING_GET_ITEMS: "/foodicspricing/GetItems/",
  FOODICSPRICING_PRICE_UPDATER_CONFIG: "/foodicspricing/PriceUpdaterConfig/",
  FOODICSPRICING_RUN_DOWNLOADER: "/foodicspricing/RunDownloader/",
  FOODICSPRICING_RUN_UPDATER: "/foodicspricing/RunUpdater/",
  FOODICSPRICING_FETCH_STATUSES: "/foodicspricing/FetchStatuses/",
  FOODICSPRICING_UPDATE_USD_PRICES: "/foodicspricing/UpdateUsdPrices/",
  FOODICSPRICING_UPDATE_SETTINGS: "/foodicspricing/UpdateSettings/",
  RECIPEBOOK_RECIPE: "/recipebook/recipe/",
  RECIPEBOOK_LIST_CATEGORIES: "/recipebook/listCategories/",
  RECIPEBOOK_MAIN_BRANCH: "/recipebook/MainBranch/",
  RECIPEBOOK_LIST_INGREDIENTS: "/recipebook/listIngredients/",
  RECIPEBOOK_RECIPE_BY_ITEM: "/recipebook/recipeByItem/",
  RECIPEBOOK_STATUS: "/recipebook/status/",
  RECIPEBOOK_START_DOWNLOADER: "/recipebook/startDownloader/",
  RECIPEBOOK_SEARCH: "/recipebook/search/",
  DATAENTRY_ADDITIONAL_RECORDS: "/dataentry/additional-records/",
  DATAENTRY_LATEST_ADDITIONAL_RECORDS: "/dataentry/latest-additional-records/",
  DATAENTRY_ADDITIONAL_ENTITIES: "/dataentry/additional-entities/",
  DATAENTRY_DATA_TYPES: "/dataentry/data-types/",
  DATAENTRY_AVAILABLE_DATES: "/dataentry/available-dates/",
  DATAENTRY_DATA_DESCRIPTION: "/dataentry/data-description/",
  DATAENTRY_UPDATE_ADDITIONAL_RECORDS: "/dataentry/update-additional-records/",
  DATAENTRY_EXPORT_ADDITIONAL_RECORDS: "/dataentry/export-additional-records/",
};

export const NO_PERM_ROUTE = "/no-permission";
export const NO_USER_ROUTE = "/no-user";
export const SERVER_DOWN_ROUTE = "/no-response";
export const NO_CONNECTION_ROUTE = "/no-connection";
export const STATUS_ROUTES = [NO_PERM_ROUTE, NO_USER_ROUTE, SERVER_DOWN_ROUTE, NO_CONNECTION_ROUTE];

// Contexts

// Auth

export const defaultAuthContextObject: AuthContextType = {
  isAuthenticated: false,
  user: null,
  isLoading: true,
  logout: () => signOut(fireBaseAuth),
};
export const AuthContext = createContext<AuthContextType>(defaultAuthContextObject);

// General logic

export const defaultCompanyContext: CompanyContextType = {
  selectedCompanyName: null,
  setSelectedCompanyName: () => null,
  companyBranchMapping: {},
  selectionDisabled: false,
  setSelectionDisabled: () => null,
  companies: [],
};
export const CompanyContext = createContext<CompanyContextType>(defaultCompanyContext);

// In-App Contexts

const INITIAL_CONFIG_CONTEXT = {
  mainBranch: null,
  setCompany: null,
  mainBranches: [],
  setMainBranches: null,
  branchesLoading: null,
  setBranchesLoading: null,
  branchCompanyMapping: null,
  priceUpdaterConfig: null,
  setPriceUpdaterConfig: null,
  editMode: false,
  setEditMode: null,
};

export const PriceUpdaterConfigContext = createContext(INITIAL_CONFIG_CONTEXT);

const defaultDataEntryContext: DataEntryContextType = {
  company: null,
  companiesByRealmId: null,
  editMode: false,
  setEditMode: () => {},
  disabledEditSwitch: false,
  setDisabledEditSwitch: () => {},
  setCurrentDataType: () => {},
};

export const DataEntryContext = createContext<DataEntryContextType>(defaultDataEntryContext);

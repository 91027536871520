/*
@since: 2023-3-15
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { usePriceConfigContext } from "Utils/hooks/usePriceConfigContext";
import { Input, Row, Select } from "antd";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { UpdateUsdPrices } from "../../../../dataHandling/PriceUpdaterAPI";
import { NEW_PRICE_COL_TITLE } from "../../Utils/Constants";
import { AddExtraPriceFields, EditableCellRenderer, GenerateCurrPriceColumns, GetNewLbpPriceColumn } from "../Utils/PriceTableUtil";
import CustomEditSwitch from "./CustomEditSwitch";
import CustomPriceTable from "./CustomPriceTable";
const { Option } = Select;

export default function ProductPriceTable() {
  // Context
  const { priceUpdaterConfig, mainBranch } = usePriceConfigContext();
  const { products, dataLoading, setProducts, setDataLoading, editMode, setEditMode, customNewRate } = useOutletContext();

  // State
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [paginationSettings, setPaginationSettings] = useState({
    current: 1,
    onChange: (page, pageSize) => {
      setPaginationSettings({ ...paginationSettings, current: page });
    },
  });

  // Reverts unsaved changes
  useEffect(() => {
    // on table unmount
    return () => {
      // reset data changes
      const productsUnchanged = [...products];
      AddExtraPriceFields(productsUnchanged, customNewRate);
      setProducts(productsUnchanged);
    };
  }, []);

  if (dataLoading) {
    return <></>;
  }

  function onEditModeChange(checked, event) {
    // toggle off = send update request
    if (!checked) {
      let changedProducts = products.filter((product) => product.usd_price != product.new_usd_price);

      // changes were made
      if (changedProducts.length > 0) {
        changedProducts = changedProducts.map((product) => {
          product.usd_price = product.new_usd_price;
          return product;
        });
        const requestBody = { products: changedProducts };

        setDataLoading(true);
        UpdateUsdPrices(mainBranch, requestBody)
          .then((response) => {
            const items = response.data.data;
            const newProducts = AddExtraPriceFields(items.products, customNewRate);
            setProducts(newProducts);
            setDataLoading(false);
            console.log(response);
          })
          .catch((err) => {
            message.error("An error occurred while updating product prices, check logs for more details.");
            console.error(err);
          });
      }
    }
  }

  function SearchableColumn(columnTitle, setFilteredData, searchFieldName) {
    const [inputValue, setInputValue] = useState("");
    const fuse = new Fuse(products, { keys: [searchFieldName], findAllMatches: true });
    function searchChangeHandler(event) {
      const currInputValue = event.target.value;
      setInputValue(currInputValue);
      let filteredData;
      // show all records on empty string
      if (currInputValue == "") {
        filteredData = products;
      } else {
        const filteredSearchResults = fuse.search(currInputValue);
        filteredData = filteredSearchResults.map((element) => element.item);
      }
      setFilteredData(filteredData);
      // go to first page when searching
      setPaginationSettings({ ...paginationSettings, current: 1 });
    }
    return (
      <>
        {columnTitle}
        <Input placeholder={`Search ${columnTitle}`} value={inputValue} onChange={searchChangeHandler} />
      </>
    );
  }

  function CategoryDropDownColumn(setFilteredData) {
    const [selectedValue, setSelectedValue] = useState("");
    const fuse = new Fuse(products, { keys: ["category_name"], findAllMatches: false });

    // Extract a unique list of categories
    let productCategories = products.map((product) => product.category_name);
    productCategories = [...new Set(productCategories)];
    // Initialize drop down data
    let dropdownOptions = productCategories.map((category) => {
      return { value: category, label: category };
    });
    dropdownOptions.unshift({ value: "", label: "All Categories" });

    function itemSelectionHandler(newSelectedValue) {
      setSelectedValue(newSelectedValue);
      let filteredData;
      // show all records on empty string
      if (newSelectedValue == "") {
        filteredData = products;
      } else {
        const filteredResults = fuse.search(newSelectedValue);
        filteredData = filteredResults.map((element) => element.item);
      }
      setFilteredData(filteredData);
      // go to first page when filtering
      setPaginationSettings({ ...paginationSettings, current: 1 });
    }
    return (
      <>
        Category
        <Select options={dropdownOptions} defaultValue="" onChange={itemSelectionHandler} style={{ width: "100%" }} value={selectedValue}>
          {dropdownOptions.map((element) => (
            <Option key={element} value={element}>
              {element}
            </Option>
          ))}
        </Select>
      </>
    );
  }

  const productColumns = [
    {
      title: SearchableColumn("Name", setFilteredProducts, "name"),
      dataIndex: "name",
      render: (productName) => <span>{productName}</span>,
    },
    {
      title: SearchableColumn("SKU", setFilteredProducts, "foodics_sku"),
      dataIndex: "foodics_sku",
      render: (productSku) => <span>{productSku}</span>,
    },
    {
      title: CategoryDropDownColumn(setFilteredProducts),
      dataIndex: "category_name",
      render: (productCategory) => <span>{productCategory}</span>,
    },
    ...GenerateCurrPriceColumns(priceUpdaterConfig.lastLbpRateUsed),
    {
      title: NEW_PRICE_COL_TITLE,
      dataIndex: "new_usd_price",
      sorter: (a, b) => a.new_usd_price - b.new_usd_price,
      render: EditableCellRenderer(setProducts, products, editMode, customNewRate),
    },
    GetNewLbpPriceColumn(),
  ];

  return (
    <>
      <Row justify="end">
        <CustomEditSwitch
          loading={dataLoading}
          disabled={dataLoading}
          checked={editMode}
          onClick={setEditMode}
          onChange={onEditModeChange}
        />
      </Row>
      <CustomPriceTable
        columns={productColumns}
        dataSource={filteredProducts}
        rowKey="foodics_id"
        loading={dataLoading}
        pagination={paginationSettings}
      />
    </>
  );
}

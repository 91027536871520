import { FoodicsToolInventoryCount, ListSubBranchesType } from "Utils/types";
import { Col, Row, message } from "antd";
import { Loading } from "components/AlgoBooks/components/Loading";
import "components/Shared/Styles/styles.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { GetInventoryCounts, ListSubBranches } from "../../../../dataHandling/InventoryCountAPI";
import InventoryCountTable from "../../Components/InventoryCount/InventoryCountTable/InventoryCountTable";
import { ConfigContext } from "../FoodicsToolApp";
import "./InventoryCount.scss";

export default function InventoryCount() {
  const { mainBranch, setBranchesLoading, branchesLoading } = React.useContext(ConfigContext);
  // const branchesLoading = true;
  const [inventoryCounts, setInventoryCounts] = useState<FoodicsToolInventoryCount[] | null>(null);
  const [branches, setBranches] = useState<ListSubBranchesType>([]);
  const [countsLoading, setCountsLoading] = useState<boolean>(true);
  const [update, setUpdate] = useState("");

  const [inventoryCountsPage, setInventoryCountsPage] = useState(1);

  useEffect(() => {
    if (mainBranch) {
      setCountsLoading(true);
      ListSubBranches(mainBranch)
        .then((response) => {
          if (response.status === 200) {
            setBranches(response.data);
          } else {
            message.error("Failed to get sub branches for: " + mainBranch);
          }
        })
        .catch((err) => {
          message.error("An error occurred while fetching sub branches, check logs for more details.");
          console.error(err);
        });
      GetInventoryCounts(mainBranch, inventoryCountsPage)
        .then((response) => {
          if (response.status === 200) {
            let data = response.data;
            const statusVals = {
              1: "Draft",
              2: "Closed",
            };
            data.forEach((element: FoodicsToolInventoryCount) => {
              element["key"] = element["id"];
              element["status"] = statusVals[element["status"]];
              element["created_at"] = moment(moment.utc(element["created_at"], "YYYY/MM/DD HH:mm:ss").toDate()).calendar();
              element["updated_at"] = moment(moment.utc(element["updated_at"], "YYYY/MM/DD HH:mm:ss").toDate()).calendar();
              element["branch"] = element["branch"]["name"];
              if (element["reference"] === null) {
                element["reference"] = "-";
              }
              if (element["notes"] === null) {
                element["notes"] = "-";
              }
            });
            setInventoryCounts(data);
          } else {
            message.error("Failed to get inventory count history for: " + mainBranch);
          }
          setCountsLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching inventory counts, check logs for more details.");
          console.error(err);
        });
    }
  }, [update, mainBranch]);

  return countsLoading ? (
    <Row justify="center" align="middle" className="inventory-count-row container-center" style={{ height: "50%", minHeight: "72%" }}>
      <Loading tip="Loading..." />
    </Row>
  ) : (
    <Row justify="center" align="middle" className="inventory-count-row">
      <Col xxl={16} xl={18} lg={21} md={22} sm={23} xs={24}>
        <InventoryCountTable
          branches={branches}
          counts={inventoryCounts}
          updateCounts={setUpdate}
          loading={countsLoading}
          mainBranch={mainBranch}
        ></InventoryCountTable>
      </Col>
    </Row>
  );
}

import { InboxOutlined } from "@ant-design/icons";
import { Upload } from "antd";
// import "antd/dist/antd.css";

interface FileUploaderProps {
  disabled: boolean;
  cachedFiles: ArrayBuffer[];
  setCachedFiles: React.Dispatch<React.SetStateAction<ArrayBuffer[]>>;
  maxCount: number;
}

export default function FileUploader(props: FileUploaderProps) {
  const { Dragger } = Upload;
  const onRemove = (file) => {
    props.setCachedFiles([]);
  };
  const beforeUpload = (file) => {
    props.setCachedFiles([file]);
    return false;
  };
  return (
    <Dragger
      disabled={props.disabled}
      onRemove={onRemove}
      beforeUpload={beforeUpload}
      defaultFileList={props.file}
      maxCount={props.maxCount}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag the file to this area to upload</p>
      <p className="ant-upload-hint">Only supports single files</p>
    </Dragger>
  );
}

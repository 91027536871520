/*
@since: 2022-8-5
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { WarningFilled } from "@ant-design/icons";
import { Col, Row } from "antd";
import { CSSProperties } from "react";
import { colors } from "../Utils/constants";

export function RestrictedPage() {
  const restrictionPageStyle: CSSProperties = {
    height: "80vh",
    width: "100%",
    display: "table",
    position: "absolute",
    textAlign: "center",
  };
  const messageContainerStyle: CSSProperties = {
    fontSize: "170%",
    display: "table-cell",
    verticalAlign: "middle",
  };

  const iconStyle: CSSProperties = {
    fontSize: "300%",
    color: colors.RED,
  };
  return (
    <div style={restrictionPageStyle}>
      <div style={messageContainerStyle}>
        <Row align="middle" justify="center">
          <Col>
            <WarningFilled height={2000} width={2000} style={iconStyle} />
          </Col>
        </Row>
        <Row align="middle" justify="center">
          <Col>
            <span>
              <span>You currently do not have permission to view this page.</span>
              <br />
              <span>
                If you believe this is not intended, please contact AlgoTraders at
                <a href="mailto: support@algotraders.ai"> support@algotraders.ai</a>.
              </span>
              <br />
              <span>For more information and urgent inquires, you can reach us at +961 (0) 1 203 088 ext 4</span>
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}

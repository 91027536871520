/**
 * @since: 2024-1-5
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { CloseOutlined, DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { ToReadableCurrency, ToReadableDate } from "Utils/HumanReadable";
import { IsNotNullOrUndefined } from "Utils/helpers";
import { useDataEntryContext } from "Utils/hooks/useDataEntryContext";
import { AdditionalEntities, AdditionalRecord, AlgoBooksEntity, CompaniesByRealmId, DashboardCompany } from "Utils/types";
import { Button, Col, Input, InputNumber, InputNumberProps, Row, Select, Table } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { ColumnsType } from "antd/es/table";
import { ReactNode, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import "./AdditionalRecordsTable.scss";

type EntityType = "Department" | "Class";
type Currency = "LBP" | "USD" | "SAR";
interface EntitiesById {
  [property: string]: AlgoBooksEntity;
}

interface AdditionalRecordsTableProps {
  additionalRecords: AdditionalRecord[];
  setAdditionalRecords: React.Dispatch<React.SetStateAction<AdditionalRecord[]>>;
  additionalEntities: AdditionalEntities;
  loading: boolean;
  companiesByRealmId: CompaniesByRealmId;
  classesById: EntitiesById;
  departmentsById: EntitiesById;
  currentTableDate: string | null;
  company: DashboardCompany;
  reloadTableData: () => void;
  newDateMode: boolean;
  resetToInitialState: () => void;
  onSaveChanges: () => void;
  tableToolbarChildren: ReactNode;
}

const PRECISION_PER_CURRENCY: { [currency: string]: number } = {
  SAR: 2,
  USD: 2,
  LBP: 0,
};

const MIN_AMOUNT = -999999999999999;
const MAX_AMOUNT = 999999999999999;

export function AdditionalRecordsTable({ loading = false, ...props }: AdditionalRecordsTableProps) {
  const { editMode, setEditMode } = useDataEntryContext();
  const [inputStatuses, setInputStatuses] = useState<{ [key: string]: InputNumberProps["status"][] }>({
    LBP: [],
    USD: [],
    SAR: [],
  });

  // Options
  const classOptions = useOptions(props.additionalEntities?.classes || []);
  const departmentOptions = useOptions(props.additionalEntities?.departments || []);
  const reportNameOptions: DefaultOptionType[] = useMemo(() => {
    if (IsNotNullOrUndefined(props.additionalEntities)) {
      return props.additionalEntities!.reportNames.map((reportName) => ({
        label: reportName,
        value: reportName,
      }));
    } else {
      return [];
    }
  }, [props.additionalEntities?.reportNames]);
  const descriptionOptions: DefaultOptionType[] = useMemo(() => {
    if (IsNotNullOrUndefined(props.additionalEntities)) {
      return Object.entries(props.additionalEntities!.descriptionOptions).map(([expenseType, descriptions]: [string, string[]]) => {
        return {
          label: expenseType,
          options: descriptions.map((description) => ({ label: description, value: description })),
        };
      });
    } else {
      return [];
    }
  }, [props.additionalEntities?.descriptionOptions]);

  // Table data
  const { dataSource, columns } = useMemo(() => {
    const columns: ColumnsType<AdditionalRecord> = [
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        render: (date: string) => ToReadableDate(date),
      },
      {
        title: "Company",
        dataIndex: "companyQuickbooksId",
        key: "company",
        render: (quickbooksId: string) => props.companiesByRealmId[quickbooksId].name,
      },
      {
        title: "Location",
        dataIndex: "departmentQuickbooksId",
        key: "department",
        render: (departmentId: string, record: AdditionalRecord, index: number) =>
          RenderEntitiesCell(departmentId, "Department", record, index),
      },
      {
        title: "Class",
        dataIndex: "classQuickbooksId",
        key: "class",
        render: (classId: string, record: AdditionalRecord, index: number) => RenderEntitiesCell(classId, "Class", record, index),
      },
      {
        title: "Report Name",
        dataIndex: "reportName",
        key: "report-name",
        render: RenderReportNameCell,
      },
      {
        title: "Description",
        dataIndex: "description",
        key: "description",
        render: RenderDescriptionCell,
      },
      {
        title: "Value LBP",
        dataIndex: "valueLbp",
        key: "lbp",
        render: (amount: string, record: AdditionalRecord, index: number) => RenderCurrencyCell(amount, record, index, "LBP"),
      },
      {
        title: "Value USD",
        dataIndex: "valueUsd",
        key: "usd",
        render: (amount: string, record: AdditionalRecord, index: number) => RenderCurrencyCell(amount, record, index, "USD"),
      },
      {
        title: "Value SAR",
        dataIndex: "valueSar",
        key: "sar",
        render: (amount: string, record: AdditionalRecord, index: number) => RenderCurrencyCell(amount, record, index, "SAR"),
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        render: (text: string, record: AdditionalRecord, index: number) => RenderCommentCell(text, record, index),
      },
      {
        title: "Added By",
        dataIndex: "addedBy",
        key: "added-by",
      },
    ];
    if (editMode) {
      columns.push({
        title: "Delete Row",
        key: "delete-row",
        render: (text: string, record: AdditionalRecord, index: number) => (
          <DeleteOutlined
            size={70}
            className="delete-button"
            style={{ fontSize: "1.5em" }}
            onClick={(event) => {
              event.preventDefault();
              onDeleteRow(record);
            }}
          />
        ),
      });
    }
    props.additionalRecords.forEach((additionalRecord) => (additionalRecord.key = additionalRecord.record_id));
    return {
      columns,
      dataSource: props.additionalRecords,
    };
  }, [props.additionalRecords, editMode]);

  // Cell Components

  function RenderEntitiesCell(entityId: string, entityType: EntityType, record: AdditionalRecord, index: number): ReactNode | string {
    if (!editMode) {
      return entityType === "Department" ? props.departmentsById[entityId].name : props.classesById[entityId].name;
    } else {
      const options = entityType === "Department" ? departmentOptions : classOptions;
      return (
        <Select
          dropdownStyle={{ width: "250px" }}
          placeholder={`Select ${entityType}`}
          options={options}
          value={entityId}
          onSelect={(entityId, option) => onEntitySelect(entityId, option, record, entityType)}
        />
      );
    }
  }

  function RenderReportNameCell(reportName: string, record: AdditionalRecord, index: number): ReactNode | string {
    if (!editMode) {
      return reportName;
    } else {
      return (
        <Select
          placeholder="Select report name"
          dropdownStyle={{ width: "max-content" }}
          options={reportNameOptions}
          value={reportName}
          onSelect={(reportName, option) => onReportNameSelect(reportName, option, record)}
        />
      );
    }
  }

  function RenderDescriptionCell(description: string, record: AdditionalRecord, index: number): ReactNode | string {
    if (!editMode) {
      return description;
    } else {
      return (
        <Select
          placeholder="Select description"
          dropdownStyle={{ width: "250px" }}
          options={descriptionOptions}
          value={description}
          onSelect={(description, option) => onDescriptionSelect(description, option, record)}
        />
      );
    }
  }

  function RenderCurrencyCell(amount: string, record: AdditionalRecord, index: number, currency: Currency): ReactNode | string {
    if (!editMode) {
      return ToReadableCurrency(Number(amount));
    } else {
      return (
        <>
          {inputStatuses[currency][index] === "error" && <span style={{ color: "red" }}>Value out of range</span>}
          <InputNumber
            type="number"
            value={Number(amount)}
            style={{
              width: `${Math.max(
                (amount.length +
                  (PRECISION_PER_CURRENCY[currency] === 0 ? 1 : 0) +
                  (PRECISION_PER_CURRENCY[currency] > 0 && Number(amount) % 1 == 0 ? 2 : 0)) *
                  (9.5 + Math.max(0, PRECISION_PER_CURRENCY[currency] - 1)),
                100
              )}px`,
            }}
            controls={false}
            defaultValue={0}
            min={MIN_AMOUNT}
            max={MAX_AMOUNT}
            status={inputStatuses[currency][index] || ""}
            precision={PRECISION_PER_CURRENCY[currency]}
            onChange={(value: number | null) => onAmountChange(value, record, currency)}
            onInput={(text: string) => {
              const newInputStatuses = { ...inputStatuses };
              if (Number(text) > MAX_AMOUNT || Number(text) < MIN_AMOUNT) {
                newInputStatuses[currency][index] = "error";
              } else {
                newInputStatuses[currency][index] = "";
              }
              setInputStatuses(newInputStatuses);
            }}
            onBlur={() => {
              const newInputStatuses = { ...inputStatuses };
              newInputStatuses[currency][index] = "";
              setInputStatuses(newInputStatuses);
            }}
          />
        </>
      );
    }
  }

  function RenderCommentCell(text: string, record: AdditionalRecord, index: number) {
    if (!editMode) {
      return text;
    } else {
      return (
        <Input.TextArea
          maxLength={255}
          value={text}
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onCommentChange(e.target.value, record)}
          style={{ maxHeight: "300px", minWidth: "200px" }}
        ></Input.TextArea>
      );
    }
  }

  // Row Operations

  function UpdateAdditionalRecords(recordId: string, updateCallback: (additionalRecord: AdditionalRecord) => void): void {
    const additionalRecords = [...props.additionalRecords];
    const additionalRecord = additionalRecords.find((additionalRecord) => additionalRecord.record_id === recordId);
    if (IsNotNullOrUndefined(additionalRecord)) {
      updateCallback(additionalRecord!);
    } else {
      throw new Error(`${recordId} was not found in loaded additional records`);
    }
    props.setAdditionalRecords(additionalRecords);
  }

  function DeleteAdditionalRecord(recordId: string): void {
    const additionalRecords = [...props.additionalRecords];
    const additionalRecordIndex = additionalRecords.findIndex((additionalRecord) => additionalRecord.record_id === recordId);
    if (IsNotNullOrUndefined(additionalRecordIndex)) {
      additionalRecords.splice(additionalRecordIndex, 1);
    } else {
      throw new Error(`${recordId} was not found in loaded additional records`);
    }
    props.setAdditionalRecords(additionalRecords);
  }

  function AddAdditionalRecord() {
    let initialDescriptionOption = null;
    for (let descriptions of Object.values(props.additionalEntities.descriptionOptions)) {
      if (descriptions.length > 0) {
        initialDescriptionOption = descriptions[0];
        break;
      }
    }
    const newAdditionalRecord: AdditionalRecord = {
      record_id: uuidv4(),
      date: props.currentTableDate!,
      companyQuickbooksId: props.company.quickbooksId,
      departmentQuickbooksId: props.additionalEntities.departments[0].quickbooksId,
      classQuickbooksId: props.additionalEntities.classes[0].quickbooksId,
      reportName: props.additionalEntities.reportNames[0],
      description: initialDescriptionOption!,
      valueLbp: "0",
      valueUsd: "0",
      valueSar: "0",
      comment: "",
      addedBy: props.additionalEntities!.userDisplayName,
    };
    props.setAdditionalRecords([...props.additionalRecords, newAdditionalRecord]);
  }

  // Change handlers

  function onEntitySelect(entityId: string, option: DefaultOptionType, record: AdditionalRecord, entityType: EntityType) {
    UpdateAdditionalRecords(record.record_id, (additionalRecord: AdditionalRecord) => {
      if (entityType === "Department") {
        additionalRecord!.departmentQuickbooksId = entityId;
        additionalRecord!.classQuickbooksId = "";
      } else {
        additionalRecord!.classQuickbooksId = entityId;
        additionalRecord!.departmentQuickbooksId = "";
      }
    });
  }

  function onReportNameSelect(reportName: string, option: DefaultOptionType, record: AdditionalRecord) {
    UpdateAdditionalRecords(record.record_id, (additionalRecord: AdditionalRecord) => {
      additionalRecord.reportName = reportName;
    });
  }

  function onDescriptionSelect(description: string, option: DefaultOptionType, record: AdditionalRecord) {
    UpdateAdditionalRecords(record.record_id, (additionalRecord: AdditionalRecord) => {
      additionalRecord.description = description;
    });
  }

  function onAmountChange(amount: number | null, record: AdditionalRecord, currency: string) {
    if (IsNotNullOrUndefined(amount)) {
      let currencyField: null | "valueLbp" | "valueUsd" | "valueSar" = null;
      switch (currency) {
        case "LBP":
          currencyField = "valueLbp";
          break;
        case "USD":
          currencyField = "valueUsd";
          break;
        case "SAR":
          currencyField = "valueSar";
          break;
        default:
          break;
      }
      UpdateAdditionalRecords(record.record_id, (additionalRecord: AdditionalRecord) => {
        if (currencyField !== null) additionalRecord[currencyField] = amount!.toString();
      });
    }
  }

  function onCommentChange(text: string, record: AdditionalRecord) {
    UpdateAdditionalRecords(record.record_id, (additionalRecord: AdditionalRecord) => {
      additionalRecord.comment = text;
    });
  }

  function onDeleteRow(record: AdditionalRecord) {
    DeleteAdditionalRecord(record.record_id);
  }

  function onDiscardChanges() {
    if (props.newDateMode) {
      props.resetToInitialState();
    } else {
      props.reloadTableData();
      setEditMode(false);
    }
  }

  return (
    <>
      <Row style={{ flexDirection: "column" }}>
        <Col>
          {props.tableToolbarChildren}
          <Table
            columns={columns}
            dataSource={dataSource}
            loading={loading}
            pagination={false}
            scroll={{ x: true }}
            style={!editMode ? { cursor: "default" } : {}}
          ></Table>
        </Col>
        {editMode && (
          <Col style={{ display: "flex", justifyContent: "end", marginTop: "20px", gap: "20px" }}>
            <Button
              onClick={onDiscardChanges}
              icon={<CloseOutlined style={{ color: "white" }} />}
              className={!loading ? "discard-button" : ""}
              disabled={loading}
            >
              Discard Changes
            </Button>
            {(!props.newDateMode || props.additionalRecords.length !== 0) && ( // hide when new date mode and table is blank
              <Button
                onClick={props.onSaveChanges}
                icon={<SaveOutlined style={{ color: "white" }} />}
                className={!loading ? "save-button" : ""}
                disabled={loading}
              >
                Save Changes
              </Button>
            )}
            <Button onClick={AddAdditionalRecord} icon={<PlusOutlined style={{ color: !loading ? "green" : "gray" }} />} disabled={loading}>
              Add New Row
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

function useOptions(entities: AlgoBooksEntity[]): DefaultOptionType[] {
  return useMemo(() => entities.map((entity): DefaultOptionType => ({ value: entity.quickbooksId, label: entity.name })), [entities]);
}

import { API_ROUTES } from "Utils/constants";
import { RecipeSearchRecord } from "Utils/types";
import { Input, Row, Select, message } from "antd";
import axios, { Canceler } from "axios";
import isEmpty from "just-is-empty";
import { CSSProperties, useEffect, useState } from "react";
import { ListCategories } from "../../../../dataHandling/RecipeBook";
import { axiosCustom } from "../../Lib/Axios";
import RecipeList from "./RecipeList";

interface MenuSelectProps {
  setRecipeId: React.Dispatch<React.SetStateAction<string | null>>;
  recipeId: string | null;
  mainBranch: string | null;
}

const { Option } = Select;

// Request Cancellation Utilities
const CancelTokenClass = axios.CancelToken;
let cancelRequest: Canceler;

function MenuSelect(props: MenuSelectProps) {
  // State variables
  const [recipeOptions, setRecipeOptions] = useState<RecipeSearchRecord[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<string[]>(["All Categories"]);
  const [selectedCategory, setSelectedCategory] = useState<string>(categoryOptions[0]);
  const [loading, setLoading] = useState<boolean>(true);
  const [recipeName, setRecipeName] = useState<string | null>(null);

  // Fetch all category values
  useEffect(() => {
    if (props.mainBranch) {
      ListCategories(props.mainBranch)
        .then((res) => {
          setCategoryOptions(res.data);
          setRecipeOptions([]);
          setLoading(false);
        })
        .catch((err) => {
          message.error("An error occurred while fetching options, check logs for more details.");
          console.error(err);
        });
    }
  }, [props.mainBranch]);

  // Fetch recipes on recipe name / category change
  useEffect(() => {
    if (!isEmpty(props.mainBranch) && !isEmpty(selectedCategory)) {
      setLoading(true);

      // Cancelling pending requests
      if (cancelRequest !== undefined) {
        cancelRequest();
      }

      axiosCustom
        .get<RecipeSearchRecord[]>(API_ROUTES.RECIPEBOOK_SEARCH, {
          params: {
            subString: recipeName,
            category: selectedCategory,
            branch: props.mainBranch,
          },
          // Associating the request to a cancel token
          cancelToken: new CancelTokenClass((canceler) => {
            cancelRequest = canceler;
          }),
        })
        .then((response) => {
          setRecipeOptions(response.data);
          setLoading(false);
        })
        .catch((error) => {
          if (!axios.isCancel(error)) {
            message.error("An error occurred while searching for recipes, check logs for more details.");
            console.log("Error during searching for recipes : " + error);
          }
        });
    }
  }, [props.mainBranch, recipeName, selectedCategory]);

  // Basic Data Handlers
  const handleChangeCategory = (value: string) => {
    setSelectedCategory(value);
  };
  const handleRecipeNameChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target;
    setRecipeName(value);
  };

  //Styles
  const selectorStyle: CSSProperties = {
    marginBottom: "10px",
  };

  return (
    <>
      <Row>
        <Input placeholder="Recipe Name..." allowClear style={selectorStyle} onChange={handleRecipeNameChange} />
      </Row>
      <Row>
        <Select
          onChange={handleChangeCategory}
          placeholder="Category..."
          value={selectedCategory}
          style={{ ...selectorStyle, width: "100%" }}
        >
          {categoryOptions.map((element) => (
            <Option key={element} value={element}>
              {element}
            </Option>
          ))}
        </Select>
      </Row>
      <Row>
        <RecipeList loading={loading} recipes={recipeOptions} recipeId={props.recipeId} setRecipeId={props.setRecipeId} />
      </Row>
    </>
  );
}

export { MenuSelect };

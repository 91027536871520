/**
 * @since: 2024-2-8
 * @author: Mohammad Traboulsi
 * @maintainer: Mohammad Traboulsi
 * @copyright: All rights reserved
 */

import { useCompanyContext } from "Utils/hooks/useCompanyContext";
import { ReportGenerationStatus } from "Utils/types";
import { Button, Popconfirm, message } from "antd";
import { FetchReportGenerationStatus, StartReportGeneration } from "dataHandling/algobooks";
import { useEffect, useState } from "react";

export function GenerateReportsButton() {
  const { selectedCompanyName, companies } = useCompanyContext();
  const [buttonStatus, setButtonStatus] = useState<ReportGenerationStatus>("LOADING");

  const startReportGeneration = () => {
    const company = companies.find((company) => selectedCompanyName == company.name);
    StartReportGeneration(company!.quickbooksId)
      .then((response) => {
        message.info(response.message);
      })
      .catch((err) => {
        message.error("An error occurred while starting report report generation.");
        console.error(err);
      });
  };

  useEffect(() => {
    const statusWatcher = setInterval(() => {
      const company = companies.find((company) => selectedCompanyName == company.name);
      FetchReportGenerationStatus(company!.quickbooksId)
        .then((response) => {
          if (response === undefined) {
            message.error("Failed to fetch report generation status.");
          } else {
            setButtonStatus(response.status);
          }
        })
        .catch((err) => {
          message.error("An error occurred, check logs for more details");
          console.error(err);
          // check why there was an error
        });
    }, 1000); // 1s

    return () => {
      console.log("clean up interval function");
      clearInterval(statusWatcher);
    };
  }, [selectedCompanyName]);

  return (
    <Popconfirm
      placement="bottomRight"
      title="Reports are automatically generated daily. Initiating this process now may take several hours. Do you wish to proceed?"
      onConfirm={startReportGeneration}
      okText="Yes"
      cancelText="No"
      disabled={buttonStatus == "LOADING"}
    >
      <Button className="top-bar-button" loading={buttonStatus == "LOADING"}>
        Re-generate
      </Button>{" "}
    </Popconfirm>
  );
}

/*
@since: 2023-3-15
@author: Mohammad Traboulsi
@maintainer: Mohammad Traboulsi
@copyright: All rights reserved
*/

import { Tabs, TabsProps } from "antd";

import React, { ReactNode, useEffect } from "react";

interface CustomTabProps {
  currentResource: string | undefined;
  tabs: TabsProps["items"];
  disableAll: boolean;
  setTabs: React.Dispatch<React.SetStateAction<TabsProps["items"]>>;
  extraRightContent?: ReactNode;
  extraLeftContent?: ReactNode;
  antdTabProps: TabsProps;
}

/**
 *
 * Assumes tab labels are TabLinks
 */
export default function CustomTabs({
  currentResource,
  tabs,
  disableAll,
  setTabs,
  extraRightContent,
  extraLeftContent,
  antdTabProps,
}: CustomTabProps) {
  useEffect(() => {
    if (setTabs) {
      const disabledTabs = tabs.map((tab) => {
        tab.disabled = disableAll;
        tab.label = React.cloneElement(tab.label, { disabled: disableAll });
        return tab;
      });
      setTabs(disabledTabs);
    }
  }, [disableAll]);
  return (
    <Tabs
      tabBarExtraContent={{
        left: extraLeftContent,
        right: extraRightContent,
      }}
      defaultActiveKey={currentResource}
      activeKey={currentResource}
      centered
      items={tabs}
      {...antdTabProps}
    />
  );
}

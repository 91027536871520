import { Button, Col, ConfigProvider, Row, Select, Switch } from "antd";
// import "antd/dist/antd.min.css";
import React from "react";
import { updateQueryCount } from "../utils/ComponentUtils/ReportSelect/QueryUtils";
import { CurrencyType, Entity, Period, ReportOption } from "../utils/types";
import { Loading } from "./Loading";
import "./Styles.scss";
const { Option } = Select;

interface ReportSelectProps {
  departments: string[] | null;
  setDepartments: React.Dispatch<React.SetStateAction<string[] | null>>;
  classes: string[] | null;
  setClasses: React.Dispatch<React.SetStateAction<string[] | null>>;
  report: string | null;
  setReport: React.Dispatch<React.SetStateAction<string | null>>;
  setGrouping: React.Dispatch<React.SetStateAction<string | null>>;
  isCurrencyReport: boolean;
  grouping: string | null;
  setPeriod: React.Dispatch<React.SetStateAction<string[]>>;
  period: string[];
  setCurrency: React.Dispatch<React.SetStateAction<CurrencyType>>;
  currency: CurrencyType;
  reportOptions: ReportOption[] | null;
  groupingOptions: string[];
  periodOptions: Period[];
  currencyOptions: string[];
  classOptions: Entity[];
  departmentOptions: Entity[];
  disable: boolean;
  reportLoading: boolean;
  queryCount: number;
  setQueryCount: React.Dispatch<React.SetStateAction<number>>;
  setPrevQueryCount: React.Dispatch<React.SetStateAction<number>>;
  setValidParameters: React.Dispatch<React.SetStateAction<boolean>>;
  validParameters: boolean;
  loading: boolean;
  hideRows: boolean;
  setHideRows: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReportSelect({
  classes,
  classOptions,
  currency,
  currencyOptions,
  grouping,
  groupingOptions,
  isCurrencyReport,
  loading,
  departmentOptions,
  departments,
  period,
  periodOptions,
  queryCount,
  report,
  reportLoading,
  reportOptions,
  setClasses,
  setCurrency,
  setGrouping,
  setDepartments,
  setPeriod,
  setPrevQueryCount,
  setQueryCount,
  setReport,
  setValidParameters,
  validParameters,
  hideRows,
  setHideRows,
}: ReportSelectProps) {
  // Constants and initial values
  const labelStyle = { color: "#1e94fc", cursor: "default" };

  const handleChangeReport = (newSelectedReportType: string) => {
    setValidParameters(false);
    setReport(newSelectedReportType);
  };

  const handleChangeDepartment = (newSelectedDepartments: string[]) => {
    var filteredDepartments = newSelectedDepartments;
    if (newSelectedDepartments.length < 1) {
      //user deselects the only selected option -> select default option
      if (JSON.stringify(departments) !== JSON.stringify([departmentOptions[0].id])) {
        setValidParameters(false);
      }
      filteredDepartments = [departmentOptions[0].id];
    } else {
      setValidParameters(false);
      const selectedAll = newSelectedDepartments.includes("");
      const multipleOptionsSelected = newSelectedDepartments.length > 1;
      if (selectedAll && multipleOptionsSelected) {
        if (newSelectedDepartments[newSelectedDepartments.length - 1] === "") {
          filteredDepartments = newSelectedDepartments.filter((value, index, arr) => value === "");
        } else {
          filteredDepartments = newSelectedDepartments.filter((value, index, arr) => value !== "");
        }
        classes = [""];
      }
    }
    setDepartments(filteredDepartments);
    setClasses(classes);
  };

  const handleChangeClass = (newSelectedClasses: string[]) => {
    var filteredClasses = newSelectedClasses;
    var tempDepartments = departments;
    if (newSelectedClasses.length < 1) {
      if (JSON.stringify(classes) !== JSON.stringify([""])) {
        setValidParameters(false);
      }
      filteredClasses = [""];
    } else {
      setValidParameters(false);
      if (newSelectedClasses.includes("") && newSelectedClasses.length > 1) {
        if (newSelectedClasses[newSelectedClasses.length - 1] === "") {
          filteredClasses = newSelectedClasses.filter((value, index, arr) => value === "");
        } else {
          filteredClasses = newSelectedClasses.filter((value, index, arr) => value !== "");
        }
        tempDepartments = [departmentOptions[0].id];
      }
    }
    setDepartments(tempDepartments);
    setClasses(filteredClasses);
  };

  const handleChangeGrouping = (value: string) => {
    setValidParameters(false);
    setGrouping(value);
  };

  const handleChangePeriod = (value: string[]) => {
    if (JSON.stringify(value) !== JSON.stringify(period)) {
      if (value.length > 0) {
        setValidParameters(false);
        value.sort();
        value.reverse();
        setPeriod(value);
      } else {
        if (JSON.stringify(period) !== JSON.stringify([periodOptions[0].key])) {
          setValidParameters(false);
        }
        setPeriod([periodOptions[0].key]);
      }
    }
  };

  const handleChangeCurrency = (value: string[]) => {
    let newVal = value;
    if (newVal.length < 1) {
      let temp = Object.keys(currency.multipliers);
      newVal = [temp[0]];
    } else {
      setValidParameters(false);
      setCurrency({ ...currency, selectedCurrencies: newVal });
    }
  };

  if (loading) {
    return <Loading tip="Fetching Parameters" />;
  } else {
    return (
      <Row justify="start">
        <Col xl={2} lg={0} md={0} sm={0} xs={0}></Col>
        <Col xl={20} lg={24} md={24} sm={24} xs={24}>
          <Row gutter={[8, 16]} align="middle" justify="start" style={{ paddingBottom: "20px" }}>
            <Col xl={6} lg={6} md={9} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Department</span>
              </Row>
              <Select
                disabled={reportLoading}
                style={{ width: "100%" }}
                onChange={handleChangeDepartment}
                placeholder="Department"
                value={departments}
                mode="multiple"
                showSearch={false}
              >
                {departmentOptions.map((element) => (
                  <Option key={element.id} value={element.id}>
                    {element.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xl={6} lg={6} md={9} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Class</span>
              </Row>
              <Select
                disabled={reportLoading}
                style={{ width: "100%" }}
                onChange={handleChangeClass}
                placeholder="Class"
                value={classes}
                mode="multiple"
                showSearch={false}
              >
                {classOptions.map((element) => (
                  <Option key={element.id} value={element.id}>
                    {element.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Frequency</span>
              </Row>
              <Select
                disabled={reportLoading}
                style={{ width: "100%" }}
                onChange={handleChangeGrouping}
                placeholder="Frequency"
                value={grouping}
              >
                {groupingOptions.map((element) => (
                  <Option key={element} value={element}>
                    {element}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xl={6} lg={6} md={18} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Period</span>
              </Row>
              <Select
                disabled={reportLoading}
                style={{ width: "100%" }}
                onChange={handleChangePeriod}
                placeholder="Period"
                value={period}
                mode="multiple"
                showSearch={false}
              >
                {periodOptions.map((element) => (
                  <Option key={element.key} value={element.key}>
                    {element.text}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xl={6} lg={6} md={6} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Report</span>
              </Row>
              <Select disabled={reportLoading} style={{ width: "100%" }} onChange={handleChangeReport} placeholder="Report" value={report}>
                {reportOptions?.map((element) => (
                  <Option key={element.value} value={element.value}>
                    {element.text}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col xl={6} lg={6} md={8} sm={12} xs={24}>
              <Row>
                <span style={labelStyle}>Currency</span>
              </Row>
              <Select
                disabled={reportLoading || !isCurrencyReport}
                style={{ width: "100%" }}
                onChange={handleChangeCurrency}
                placeholder="Currency"
                value={currency.selectedCurrencies}
                mode="multiple"
                showSearch={false}
              >
                {currencyOptions.map((element) => (
                  <Option key={element} value={element}>
                    {element}
                  </Option>
                ))}
              </Select>
            </Col>
            <Col xl={6} lg={6} md={10} sm={12} xs={24}>
              <Button
                type="primary"
                style={{ marginTop: "20px", width: "100%" }}
                disabled={reportLoading || validParameters || loading}
                onClick={() => updateQueryCount(setPrevQueryCount, setQueryCount, queryCount)}
              >
                View Report
              </Button>
            </Col>
            <Col xl={6} lg={6} md={10} sm={12} xs={24}>
              <ConfigProvider
                theme={{
                  token: {
                    fontSize: 17,
                  },
                  components: {
                    Switch: {
                      handleSize: 20,
                      trackHeight: 35,
                      trackMinWidth: 10,
                      trackPadding: 7,
                      innerMaxMargin: 40,
                      innerMinMargin: 40,
                    },
                  },
                }}
              >
                <Switch
                  checked={hideRows}
                  checkedChildren="Show zero rows"
                  unCheckedChildren="Hide zero rows"
                  disabled={reportLoading || loading}
                  onClick={() => setHideRows((hideRows) => !hideRows)}
                  style={{ borderRadius: "100px", marginTop: "20px" }}
                  className="hide-rows-switch"
                />
              </ConfigProvider>
            </Col>
          </Row>
        </Col>
        <Col xl={2} lg={0} md={0} sm={0} xs={0}></Col>
      </Row>
    );
  }
}
